import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { round } from 'mathjs'


const borderColor = '#e1e5eb'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#e1e5eb',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '40%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    price: {
        width: '18.33%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '18.33%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableRow = ({items = []}) => {
    const rows = items.map( (item, idx) => 
        <View style={styles.row} key={idx}>
            <Text style={styles.description}>{item.productName}</Text>
            <Text style={styles.qty}>{item.productQuantity}</Text>
            <Text style={styles.price}>{item.productPriceExclTax}</Text>
            <Text style={styles.price}>{item.productPrice}</Text>
            <Text style={styles.amount}>{round(item.productPrice * item.productQuantity,2)}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default InvoiceTableRow