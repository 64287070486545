import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row
  } from "shards-react"
  
  import PageTitle from "../../components/common/PageTitle"
  import React from "react"
  import ReactTable from "react-table"
  import dateFormat from "dateformat"
  import { connect } from 'react-redux'
  import { hot } from 'react-hot-loader/root'
  import { getCustomers } from '../../reducers/app/customers.reducer'
  import { history } from '../../utils/history'
  import { CSVLink } from 'react-csv'  
  import { withTranslate } from 'react-redux-multilingual'

  
  class Customers extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 20,
        tableData: []
      }
  
      this.searcher = null
    }
  
    componentDidMount() {
      this.props.dispatch(getCustomers())
    }
  
    /**
     * Handles the page size change event.
     */
    handlePageSizeChange = (e) => {
      this.setState({
        ...this.state,
        pageSize: e.target.value
      });
    }
  
    handlePageChange = (pageIndex) => {
      const { pathname } = history.location
      const numPage = pageIndex + 1
  
      history.push({
        pathname: pathname,
        search:`?numPage=${numPage}`
      })
    }
  
    render() {
      const { customers: { items = [] }, translate } = this.props
      const { pageSize } = this.state
      const { search } = history.location
      let numPage = new URLSearchParams(search.substring(1)).get("numPage")
      numPage = parseInt(numPage) ? parseInt(numPage) - 1 : 0

      const tableColumns = [
        {
          Header: translate('name'),
          accessor: "lastname",
          minWidth: 100,
          className: "text-center",
        },
        {
          Header: translate('surname'),
          accessor: "firstname",
          className: "text-center",
          minWidth: 100,
        },
        {
          Header: translate('email'),
          accessor: "email",
          className: "text-center",
          minWidth: 100,
        },
        {
          Header: translate('registration'),
          accessor: "products",
          className: "text-center",
          Cell: row => dateFormat(new Date(row.original.createdAt), "dd/mm/yyyy HH:MM:ss")
        }
      ];
  
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={translate('allCustomers')} subtitle={translate('customers')} className="text-sm-left mb-3" />
            {/* <Col sm="4" className="d-flex ml-auto my-auto">
              <RangeDatePicker className="justify-content-end" />
            </Col> */}
          </Row>
          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  <Col className="file-manager__filters__rows d-flex" md="6"></Col>
                  <Col className="d-flex justify-content-end" md="6">
                    <CSVLink className="btn btn-primary ml-auto" 
                        headers={[ 
                            { label: translate('name'), key: "lastname" },
                            { label: translate('surname'), key: "firstname" },
                            { label: translate('email'), key: "email" },
                            { label: translate('registration'), key: "createdAt" }
                        ]} 
                        data={items.map((item) => { return { ...item, createdAt: dateFormat(new Date(item.createdAt), "dd/mm/yyyy HH:MM:ss")} } )} 
                        filename={"clients.csv"}>
                        {translate('exportToExcel')}
                    </CSVLink>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div className="">
                <ReactTable
                  columns={tableColumns}
                  data={items}
                  pageSize={pageSize}
                  resizable={false}
                  showPageSizeOptions={false}
                  previousText={translate('prev')}
                  nextText={translate('next')}
                  ofText="/"
                  rowsText={translate('lines')}
                  noDataText={translate('noCustomerFound')}
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageChange}
                  page={numPage}
                  /* pages={ceil(totalOrders / pageSize)} */
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      );
    }
  }
  
  const mapStateToProps = ({ customers }) => {
    return { customers }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(Customers)))
  