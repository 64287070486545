import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "shards-react"
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'

class DropzoneArea extends React.Component {
    

    render() {
        const { translate, handleChangeType } = this.props

        return (
        <Modal size="lg" centered={true} open={true} toggle={()=>{}}>
            <ModalHeader>{translate('createStore')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Button theme="white" block onClick={() => handleChangeType(1)}>
                            <i className="material-icons font-size-xxl">menu_book</i>
                            <span className="d-block font-weight-bold">{translate('fromPDF')}</span>
                        </Button>
                    </Col>
                    <Col>
                        <Button theme="white" block onClick={() => handleChangeType(2)}>
                            <i className="material-icons font-size-xxl">view_list</i>
                            <span className="d-block font-weight-bold">{translate('fromProducts')}</span>
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
        )
    }
}

function mapStateToProps({ userInterface : { uploadProgress, uploading, successfullUploaded, convertProgress, converting, successfullConverted, uploadError, convertError }, user : { credits } }){
  return {  uploadProgress, uploading, successfullUploaded, convertProgress, converting, successfullConverted, uploadError, convertError, credits }
}

export default hot(connect(mapStateToProps)(withTranslate(DropzoneArea)))
