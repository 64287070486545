import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'
import { history } from '../../utils/history';
import { setAlert } from '../alert.reducer'
import { checkCartBelongsToGuest } from './cart.reducer'
import { waiting } from '../app/userInterface.reducer'


// Types d’actions
// ---------------
const constants = {
    'CUSTOMER_REGISTER_SUCCESS': 'CUSTOMER_REGISTER_SUCCESS',
    'CUSTOMER_REGISTER_ERROR': 'CUSTOMER_REGISTER_ERROR',
    'CUSTOMER_LOGIN_SUCCESS': 'CUSTOMER_LOGIN_SUCCESS',
    'CUSTOMER_LOGIN_FAILURE': 'CUSTOMER_LOGIN_FAILURE',
    'GET_CUSTOMER_SUCCESS': 'GET_CUSTOMER_SUCCESS',
    'CUSTOMER_TOKEN_SUCCESS': 'CUSTOMER_TOKEN_SUCCESS',
    'CUSTOMER_TOKEN_ERROR': 'CUSTOMER_TOKEN_ERROR',
    'CUSTOMER_CREATE_ADDRESS_SUCCESS': 'CUSTOMER_CREATE_ADDRESS_SUCCESS',
    'CUSTOMER_CREATE_ADDRESS_ERROR': 'CUSTOMER_CREATE_ADDRESS_ERROR',
    'CUSTOMER_LOGOUT_SUCCESS': 'CUSTOMER_LOGOUT_SUCCESS',
    'CUSTOMER_UPDATE_SUCCESS': 'CUSTOMER_UPDATE_SUCCESS',
    'CUSTOMER_UPDATE_FAILURE': 'CUSTOMER_UPDATE_FAILURE',
}

// Réducteur
// ---------
const defaultState = { logged: false, orders: {} }

export function customer(state = defaultState, action) {
  switch (action.type) {
    case 'GET_CUSTOMER_SUCCESS':
    case 'CUSTOMER_UPDATE_SUCCESS':{
        const customer = action.payload

        return {
            ...state,
            ...customer
        }
    }
    case 'CUSTOMER_LOGIN_SUCCESS':{
        return {
            ...state,
            logged: true
        }
    }
    case 'CUSTOMER_LOGIN_FAILURE':{
        return {
            ...state,
            logged: false
        }
    }
    default:
        return state
  }
}

// Action Creators
// ---------------
export function setSession(){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(!customer){
            try {
                setLocaleHeader(locale)

                const response = await axios.post(`/customers/guest`)
                const { token } = response.data

                localStorage.setItem('customer', JSON.stringify({ token }))

                dispatch({ type: constants.CUSTOMER_TOKEN_SUCCESS })
            } catch (error) {
                dispatch({ type: constants.CUSTOMER_TOKEN_ERROR })
                history.push('/internal-error')
            }
        } else {
            return dispatch(checkValidTokenCustomer())
        }
    }
}

export function checkValidTokenCustomer(){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(customer){
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)

                const response = await axios.get(`/customers/me`)
                const me = response.data

                dispatch({ type: constants.GET_CUSTOMER_SUCCESS, payload: me })

                // If token is customer not guest 
                if(me.hasOwnProperty("email") && me.hasOwnProperty("firstname") && me.hasOwnProperty("lastname") 
                && me.email !== "" && me.firstname !== "" && me.lastname !== ""){
                    dispatch({ type: constants.CUSTOMER_LOGIN_SUCCESS })
                } else {
                    dispatch({ type: constants.CUSTOMER_LOGIN_FAILURE })
                }
            } catch (error) {
                const { data } = error.response
                
                localStorage.removeItem("customer")
                
                dispatch({ type: constants.CUSTOMER_TOKEN_ERROR })
                dispatch(setAlert('DANGER', data.message))
                
                return dispatch(setSession())
            }
        } else {
            return dispatch(setSession())
        }
    }
}

export function register(lastname, firstname, email, password){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        try{
            setLocaleHeader(locale)

            // Add custormer
            const response = await axios.post('/customers/register', { lastname, firstname, email, password })
            const data = response.data
            
            // Get token
            const response2 = await axios.post('/customers/token', { email: email, password: password })
            const { token } = response2.data 

            // Set Customer token and Delete Guest token
            localStorage.setItem('customer', JSON.stringify({ token }))
            
            dispatch({ type: constants.CUSTOMER_REGISTER_SUCCESS })
            dispatch(setAlert('SUCCESS', data.message))
            
            dispatch(checkValidTokenCustomer())
        } catch(error) {
            const { data } = error.response
            dispatch(setAlert('DANGER', data.message))
            dispatch({ type: constants.CUSTOMER_REGISTER_ERROR })
        }
    }
}

export function login(email, password){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        try {
            setLocaleHeader(locale)
            
            // Get token
            const response = await axios.post('/customers/token', { email: email, password: password })
            const { token } = response.data
        
            if(token){
                // Check if cart belong to Guest
                const guest = JSON.parse(localStorage.getItem('customer'))
                await dispatch(checkCartBelongsToGuest(guest.token, token))
        
                localStorage.setItem('customer', JSON.stringify({ token }))
        
                dispatch(checkValidTokenCustomer())
            }
        } catch (error) {
            const { data } = error.response
            dispatch({ type: constants.CUSTOMER_LOGIN_FAILURE })
            dispatch(setAlert('DANGER', data.message))
        }
    }
}

export function createDeliveryAddress(lastname, firstname, address, postcode, city, phone){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(customer){
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)

                const response = await axios.post(`/customers/address/delivery`, { lastname, firstname, address, postcode, city, phone })
                const data = response.data
                dispatch({ type: constants.CUSTOMER_CREATE_ADDRESS_SUCCESS })
                dispatch(setAlert('SUCCESS', data.message))
                // Get addresses
                dispatch(checkValidTokenCustomer())
            } catch (error) {
                const { data } = error.response
                dispatch({ type: constants.CUSTOMER_CREATE_ADDRESS_ERROR })
                dispatch(setAlert('DANGER', data.message))
            }
        }
    }
}

export function createInvoiceAddress(lastname, firstname, address, postcode, city, phone){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(customer){
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)

                const response = await axios.post(`/customers/address/invoice`, { lastname, firstname, address, postcode, city, phone })
                const data = response.data
                dispatch({ type: constants.CUSTOMER_CREATE_ADDRESS_SUCCESS })
                dispatch(setAlert('SUCCESS', data.message))
                // Get addresses
                dispatch(checkValidTokenCustomer())
            } catch (error) {
                const { data } = error.response
                dispatch({ type: constants.CUSTOMER_CREATE_ADDRESS_ERROR })
                dispatch(setAlert('DANGER', data.message))
            }
        }
    }
}

export function lostPassword(email) {
    return async (dispatch, getState) => {
        const { shop: { book }, Intl : { locale } } = getState()
        
        dispatch(waiting(true))
    
        try {
            setLocaleHeader(locale)

            const response = await axios.post('/customers/lostpassword', { email, key: book.key })
            const data = response.data
            dispatch(setAlert('SUCCESS', data.message))
            dispatch(waiting(false))
        } catch (error) {
            const { data } = error.response
            dispatch(setAlert('DANGER', data.message))
            dispatch(waiting(false))
        }
    }
}

export function resetPassword(token, password){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        try {
            setAuthHeader(token)
            setLocaleHeader(locale)

            const response = await axios.put('/customers/me', { password })
            const data = response.data
            const { shop: { book } } = getState()
            history.push(`/shop/book/${book.key}/authentication`)
            dispatch(setAlert('SUCCESS', data.message))
            dispatch(waiting(false))
        } catch (error) {
            const { data } = error.response
            dispatch(setAlert('DANGER', data.message))
            dispatch(waiting(false))
        }
    }
}

export function logout() {
    return async (dispatch, getState) => {
        const { shop: { book } } = getState()
        dispatch({ type: constants.CUSTOMER_LOGOUT_SUCCESS })
        history.push(`/shop/book/${book.key}/authentication`)
        history.push(process.env.REACT_APP_BASENAME)
    }
}

export function updateCustomer({ email, firstname, lastname, password }){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        dispatch(waiting(true))
  
        const customer = JSON.parse(localStorage.getItem('customer'))
  
        if(customer){
            const updatedCustomer = {}

            try {
            setAuthHeader(customer.token)
            setLocaleHeader(locale)

            if(email) updatedCustomer.email = email
            if(firstname) updatedCustomer.firstname = firstname
            if(lastname) updatedCustomer.lastname = lastname
            if(password && password.length > 7) updatedCustomer.password = password
    
            const response = await axios.put('/customers/me',  updatedCustomer)
            const data = response.data
    
            dispatch({ type: constants.CUSTOMER_UPDATE_SUCCESS, payload: updatedCustomer })
            dispatch(setAlert('SUCCESS', data.message))
            dispatch(waiting(false))
            } catch (error) {
            const { data } = error.response
            dispatch({ type: constants.CUSTOMER_UPDATE_FAILURE })
            dispatch(setAlert('DANGER', data.message))
            dispatch(waiting(false))
            }
        }
    }
}