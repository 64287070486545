import {
    Col,
    Container,
    Row,
    DatePicker,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "shards-react"
import { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
//import FuzzySearch from "fuzzy-search"
import PageTitle from "../../components/common/PageTitle"
import SmallStats from "../../components/common/SmallStats"
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { getOrdersStatistics } from '../../reducers/app/orders.reducer'
import { history } from '../../utils/history'
import colors from "../../utils/colors"
import ProductSales from "../../components/app/order/ProductSales"
import BestSales from "../../components/app/order/BestSales"
import BestCustomers from "../../components/app/order/BestCustomers"
import LatestOrders from "../../components/app/order/LatestOrders"
import { startOfMonth } from '../../utils/date'
import { withTranslate } from 'react-redux-multilingual'

registerLocale('fr', fr)

  
  
  
class OrdersStats extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 20,
        tableData: [],
        startDate: startOfMonth(new Date()),
        endDate: new Date()
      }
  
      this.searcher = null
    }
  
    componentDidMount() {
        const { startDate, endDate } = this.state
        this.props.dispatch(getOrdersStatistics(startDate,endDate))
  
        // Initialize the fuzzy searcher.
        /* this.searcher = new FuzzySearch(tableData, ["customer", "status"], {
            caseSensitive: false
        }); */
    }
  
    /**
     * Handles the page size change event.
     */
    handlePageSizeChange = (e) => {
      this.setState({
        ...this.state,
        pageSize: e.target.value
      });
    }
  
    /**
     * Handles the global search.
     */
    handleFilterSearch = (e) => {
      this.setState({
        ...this.state,
        tableData: this.searcher.search(e.target.value)
      });
    }
  
    handlePageChange = (pageIndex) => {
      const { pathname } = history.location
      const numPage = pageIndex + 1
  
      history.push({
        pathname: pathname,
        search:`?numPage=${numPage}`
      })
    }

    handleStartDateChange = (value) => {
        this.setState({ startDate: value }, () => {
            const { startDate, endDate } = this.state
            this.props.dispatch(getOrdersStatistics(startDate,endDate))
        })
    }
    
    handleEndDateChange = (value) => {
        this.setState({ endDate: value }, () => {
            const { startDate, endDate } = this.state
            this.props.dispatch(getOrdersStatistics(startDate,endDate))
        })
    }
  
    render() {
      const { orders: { statistics : { items:orders, totalOrdersAmount, totalOrders, totalProductsQuantity, totalCustomers, bestCustomers, bestSalesProducts } }, translate, Intl } = this.props
      const { startDate, endDate } = this.state

      const smallStats = [{
        label: translate('turnover'),
        value: `${totalOrdersAmount ? totalOrdersAmount : 0} €`,
        /* percentage: "12.4%",
        increase: true, */
        chartLabels: [null, null, null, null, null],
        /* decrease: false, */
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.primary.toRGBA(0.1),
            borderColor: colors.primary.toRGBA(),
            data: [4, 4, 4, 9, 20]
          }
        ]
      }, {
        label: translate('customersOrders'),
        value: `${totalOrders ? totalOrders : 0}`,
        /* percentage: "12.4%",
        increase: true, */
        chartLabels: [null, null, null, null, null],
        /* decrease: false, */
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.success.toRGBA(0.1),
            borderColor: colors.success.toRGBA(),
            data: [1, 9, 1, 9, 9]
          }
        ]
      },
      {
        label: translate('productsSold'),
        value: `${totalProductsQuantity ? totalProductsQuantity : 0}`,
        /* percentage: "12.4%",
        increase: true, */
        chartLabels: [null, null, null, null, null],
        /* decrease: false, */
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.warning.toRGBA(0.1),
            borderColor: colors.warning.toRGBA(),
            data: [9, 9, 3, 9, 9]
          }
        ]
      },
      {
        label: translate('customers'),
        value: `${totalCustomers ? totalCustomers : 0}`,
        /* percentage: "12.4%",
        increase: true, */
        chartLabels: [null, null, null, null, null],
        /* decrease: false, */
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.salmon.toRGBA(0.1),
            borderColor: colors.salmon.toRGBA(),
            data: [3, 3, 4, 9, 4]
          }
        ]
      },
    ]

  
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row noGutters className="page-header py-4">
                <PageTitle title={translate('stats')} subtitle={translate('orders')} className="text-sm-left mb-3 col-md-6" />
                <Col sm="6" className="d-flex ml-auto">
                    <InputGroup className="justify-content-end my-auto date-range ml-auto">
                        <DatePicker
                            locale={Intl.locale}
                            size="sm"
                            selected={startDate}
                            onSelect={this.handleStartDateChange}
                            placeholderText={translate('startDate')}
                            className="text-center"
                            dateFormat="dd/MM/yyyy"
                            maxDate={endDate}
                            /* showYearDropdown
                            yearDropdownItemNumber={3}
                            scrollableYearDropdown */
                        />
                        <DatePicker
                            locale={Intl.locale}
                            size="sm"
                            selected={endDate}
                            onSelect={this.handleEndDateChange}
                            placeholderText={translate('endDate')}
                            className="text-center"
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            maxDate={new Date()}
                            /* showYearDropdown
                            yearDropdownItemNumber={3}
                            scrollableYearDropdown */
                        />
                        <InputGroupAddon type="append">
                        <InputGroupText className="px-5">
                            <i className="material-icons">&#xE916;</i>
                        </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
            {smallStats.map((stats, idx) => (
                <Col key={idx} md="3" className="mb-4">
                <SmallStats
                    chartData={stats.datasets}
                    id={`small-stats-${idx}`}
                    chartLabels={stats.chartLabels}
                    label={stats.label}
                    value={stats.value}
                    percentage=""
                />
                </Col>
            ))}
            </Row>
            <Row>
                <Col md="6" sm="6" className="mb-4">
                    <BestCustomers title={translate('bestCustomers')} items={bestCustomers} />
                </Col>
                <Col md="6" sm="6" className="mb-4">
                    <BestSales title={translate('bestSellers')} items={bestSalesProducts} />
                </Col>
            </Row>
            <Row>
                <Col lg="6" className="mb-4">
                    <ProductSales title={translate('productsSold')} items={bestSalesProducts} />
                </Col>
                <Col lg="6" className="mb-4">
                    <LatestOrders title={translate('customersOrders')} items={orders} />
                </Col>
            </Row>
        </Container>
      );
    }
  }
  
  const mapStateToProps = ({ orders, Intl }) => {
    return { orders, Intl }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(OrdersStats)))
  