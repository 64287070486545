import React from "react"
import { hot } from 'react-hot-loader/root'
import Book from "./Book"
import ProductsList from "./ProductsList"
import { connect } from 'react-redux'
import DisplayShopAs from '../../components/shop/book/DisplayShopAs'
import { updateDisplayAs } from '../../reducers/shop/book.reducer'


class Shop extends React.Component{
    

    changeDisplayAs = (displayAs) => {
        this.props.dispatch(updateDisplayAs(displayAs))
    }

    render(){  
        const { book, match } = this.props

        return (
            <React.Fragment>
                {book && book.type === 1 && book.displayAs === 3 && <DisplayShopAs changeDisplayAs={this.changeDisplayAs} />}
                {book && book.type === 1 && book.displayAs === 1 && <Book />}
                {book && (book.type === 2 || book.displayAs === 2) && <ProductsList match={match} />}
            </React.Fragment>
        )
    }
}

function mapStateToProps( { shop : { book } } ) {
    return { book }
}

export default hot(connect(mapStateToProps)(Shop))
