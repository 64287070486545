import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge
} from "shards-react"
import { withTranslate } from 'react-redux-multilingual'


const BestCustomers = ({ title, items = [], translate }) => (
  <Card small className="lo-stats h-100">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>

    <CardBody className="p-0">
      <Container fluid className="px-0">
            {items && items.length > 0 && <table className="table mb-0">  
                <thead className="py-2 bg-light text-semibold border-bottom">
                    <tr>
                    <th width="22.5%">{translate('customers')}</th>
                    <th width="22.5%">{translate('totalSales')}</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, idx) => (
                    <tr key={idx}>
                        <td>
                            {item.firstname} {item.lastname}
                        </td>
                        <td>
                            <Badge pill theme="success">{item.totalOrdersAmount} €</Badge>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>}
            {items.length === 0 && <Row className="px-3">
                <Col><p className="text-center font-size-sm text-light m-0">{translate('noData')}</p></Col>
            </Row>}
      </Container>
    </CardBody>
  </Card>
)

export default withTranslate(BestCustomers)
