import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Container,
    Row,
    FormTextarea
} from "shards-react"
  
import { Link } from 'react-router-dom'
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { getOrder } from '../../reducers/shop/orders.reducer'
import  Steps from '../../components/shop/order/Steps'
import { round } from 'mathjs'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Invoice from '../../components/invoice/Invoice'
import { withTranslate } from 'react-redux-multilingual'

  
class OrderDetail extends React.Component {

    componentDidMount() {
        const { match: { params : { idOrder } } } = this.props

        this.props.dispatch(getOrder(idOrder))
    }
  
    render() {
        const { orders : { history : { items = [] } }, match : { params : {key, idOrder } }, customer, translate } = this.props
        
         // Find invoice address
         const indexInvoiceAddress =  customer.addresses.findIndex(address => address.name === 'invoice')
         const invoiceAddress = customer.addresses[indexInvoiceAddress]
         
         // Find order
        const indexOrder = items.findIndex(item => item.idOrder === parseInt(idOrder))
        const order = items[indexOrder]

        // Invoice
        const invoiceData = {
            lastname: invoiceAddress.lastname,
            firstname: invoiceAddress.firstname,
            email: customer.email,
            phone: invoiceAddress.phone,
            address: invoiceAddress.address,
            postcode: invoiceAddress.postcode,
            city: invoiceAddress.city,
            ref: order.ref,
            transactionby: translate(order.transactionBy),
            items: (order.orderdetail) ? order.orderdetail : [],
            totalPriceInclTax: order.totalPriceInclTax,
            shippingCostsInclTax: order.shippingCostsInclTax,
            discount: order.discount
        }
        
        return (
        <React.Fragment>
            {order && <Container fluid className="main-content-container bg-dark">
                <Row className="page-header justify-content-center py-5">
                    <Col xl="7" md="11">
                        <Steps step={2} bookKey={key} />
                        <Card>
                            <CardHeader className="border-bottom bg-light">
                                {translate('order')} n°{order.ref}

                                {order.transactionBy === 'Carte bancaire' && 
                                <PDFDownloadLink document={<Invoice invoice={invoiceData} translate={translate} />} fileName={`${translate('order')}-${order.ref}.pdf`} className="float-right">{({ blob, url, loading, error }) => (<Button theme="primary">{loading ? translate('inProgress') : translate('downloadAreceipt')}</Button>)}
                                </PDFDownloadLink>}
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h6>{translate('products')}</h6>
                                        <div className="table-responsive">
                                            <table className="table mb-0 mt-3">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th scope="col" className="border-0">
                                                            {translate('nom')}
                                                        </th>
                                                        <th scope="col" className="border-0 text-center">
                                                            {translate('quantity')}
                                                        </th>
                                                        <th scope="col" className="border-0 text-right">
                                                            {translate('price')} {translate('exclTax')}
                                                        </th>
                                                        <th scope="col" className="border-0 text-right">
                                                            {translate('price')} {translate('inclTax')}
                                                        </th>
                                                        <th scope="col" className="border-0 text-right">
                                                            {translate('total')} TTC
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order.orderdetail && order.orderdetail.map((product,idx) => 
                                                        (
                                                            <tr key={idx}>
                                                                <td>{product.productName}</td>
                                                                <td className="text-center">{product.productQuantity}</td>
                                                                <td className="text-right">{product.productPriceExclTax} €</td>
                                                                <td className="text-right">{product.productPrice} €</td>
                                                                <td className="text-right">{round(product.productPrice * product.productQuantity,2)} €</td>
                                                            </tr>         
                                                        )
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="4" className="text-right">{translate('shippingCosts')}</td>
                                                        <td className="text-right">{round(order.shippingCostsInclTax,2)} €</td>
                                                    </tr>
                                                    {order.discount > 0 && <tr>
                                                        <td colSpan="4" className="text-right">{translate('discount')}</td>
                                                        <td className="text-right">- {order.discount} €</td>
                                                    </tr>}
                                                    <tr>
                                                        <td colSpan="4" className="text-right">{translate('total')}</td>
                                                        <td className="text-right">{round(order.totalPriceInclTax,2)} €</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h6 className="">{translate('commentAssociated')}</h6>
                                        <FormTextarea readOnly defaultValue={order.comment} style={{height:'100px'}} />
                                    </Col>
                                </Row>   
                            </CardBody>
                            <CardFooter className="border-top bg-light">
                                <Button tag={Link}  className="d-block d-sm-inline-block" to={`/shop/book/${key}/customer/orders`}>&larr; {translate('backToOrderList')}</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>}
        </React.Fragment>
        )}
  }
  
  const mapStateToProps = ({ shop: { cart, orders, customer } }) => {
    return { cart, orders, customer }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(OrderDetail)))
  