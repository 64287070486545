import axios, { setLocaleHeader } from '../../utils/axios'
import { history } from '../../utils/history'

// Types d’actions
// ---------------
const constants = {
    GET_BOOK_SUCCESS: 'GET_BOOK_SUCCESS',
    CHANGE_BOOK_PAGE: 'CHANGE_BOOK_PAGE',
    GET_BOOK_ERROR: 'GET_BOOK_ERROR',
    NEW_BOOK_LOADED: 'NEW_BOOK_LOADED',
    UPDATE_DISPLAY_AS: 'UPDATE_DISPLAY_AS'
};

// Réducteur
// ---------
const defaultState = {
    idBook: 0,
    activePage: 1,
    totalPages: 0,
    ratioPage: 0,
    pages : []
}

export function book(state = defaultState, action) {
  switch (action.type) {
    case 'GET_BOOK_SUCCESS':
        const book = action.payload

        // Must not change the active page
        if(state.activePage !== book.activePage){
            book.activePage = state.activePage
        }

        return {
            ...state,
            ...book
        }
    case 'GET_BOOK_ERROR':
        return {
            ...state,
            ...defaultState
        }
    case 'CHANGE_BOOK_PAGE':
        return {
            ...state,
            activePage : action.payload.activePage
        }
    case 'UPDATE_DISPLAY_AS':{
        const displayAs = action.payload

        return {
            ...state,
            displayAs
        }
    }
    default:
        return state
  }
}

// Action Creators
// ---------------
export function getBook(key){
    return async (dispatch, getState) => {
        const { shop: { book }, Intl : { locale } } = getState()

        try {
            // If new book loaded
            if(book.key !== key){
                // Reset Cart, Book, Interface, Order
                dispatch({ type: constants.NEW_BOOK_LOADED })
            }

            setLocaleHeader(locale)

            // Get book
            const response = await axios.get(`/books/key/${key}`)
            const newbook = response.data
            dispatch({ type: constants.GET_BOOK_SUCCESS, payload: newbook })
        } catch (error) {
            dispatch({ type: constants.GET_BOOK_ERROR })
            history.push('/not-found')
        }
    }
}

export function updateDisplayAs(displayAs){
    return async (dispatch) => {
        dispatch({ type: constants.UPDATE_DISPLAY_AS, payload: displayAs })
    }
}

/* export function getProductsPage(activePage){
    return async (dispatch, getState) => {
        //const { shop : { book } } = getState()

        try {
            //const response = await axios.get(`/book/${idBook}`)
            //const products = response.data
            const book = await productsDetail
            dispatch({ type: constants.GET_PRODUCTS_PAGE_SUCCESS, payload: book })
        } catch (error) {
            dispatch({ type: constants.GET_PRODUCTS_PAGE_ERROR })
        }
    }
} */

export function changeActivePage(activePage){
    return dispatch => {
        dispatch({type: constants.CHANGE_BOOK_PAGE, payload: { activePage : activePage } } )
    }
}