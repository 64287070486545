import React from "react"
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { getProduct } from '../../../reducers/shop/products.reducer'
import { hot } from 'react-hot-loader/root'

class Area extends React.Component {
    static propTypes = {
        dispatch: func.isRequired
    }

    handleClick = (e, value) => {
        e.preventDefault()

        const idProduct = parseInt(value)

        this.props.dispatch(getProduct(idProduct))
    }

    render(){
        const { area } = this.props

        return (
            <React.Fragment>
                { area.type && area.type === 'link' && <a className="area" style={{
                width: `${area.width}%`,
                height:`${area.height}%`,
                top:`${area.y}%`,
                left:`${area.x}%`}} href={area.value ? area.value : 'https://module-market.fr' } target="_blank" rel="noopener noreferrer"></a>}

                { area.type && area.type === 'product' && <a className="area" style={{
                width: `${area.width}%`,
                height:`${area.height}%`,
                top:`${area.y}%`,
                left:`${area.x}%`}} href="#" onClick={(e)=>{this.handleClick(e, area.value)}}></a>}
            </React.Fragment>
        )
    }
}

export default hot(connect()(Area))
