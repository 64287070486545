import { Button, Card, CardBody, Col, Container, Form, FormGroup, FormInput, Row } from "shards-react"
import { func, object } from 'prop-types'

import { Link } from "react-router-dom";
import React from "react"
import { connect } from 'react-redux';
import emailValidator from 'email-validator'
import { history } from '../../utils/history'
import { hot } from 'react-hot-loader/root';
import { login } from '../../reducers/app/user.reducer'
import { withTranslate } from 'react-redux-multilingual'


/* eslint jsx-a11y/anchor-is-valid: 0 */

class Login extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    user: object.isRequired,
    userInterface: object.isRequired
  }

  constructor(props){
    super(props)

    this.state = {
      email: '',
      password: '',
      submitted: false
    };
  }

  componentDidMount = () => {
    const { logged }  = this.props.user
    if (logged) {
      history.push(process.env.REACT_APP_REDIRECT_AFTER_LOGIN_SUCCESS)
    }
  }

  // Autobind with arrow func
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  // Autobind with arrow func
  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({ submitted: true });
    const { email, password } = this.state;

    if (emailValidator.validate(email) && password) {
        this.props.dispatch(login(email, password));
    }
  }

  render() {
    const { waiting } = this.props.userInterface;
    const { email, password, submitted } = this.state;
    const { translate } = this.props

    return (
      <Container fluid className="main-content-container h-100 px-4 mt-5">
        <Row noGutters className="h-100">
          <Col md="5" className="auth-form mx-auto my-auto">
            <Card>
              <CardBody>
                {/* Title */}
                <div className="font-size-lg font-weight-bold font-family-montserrat text-center text-primary mb-3">Module E-commerce</div>

                {/* Form Fields */}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <label htmlFor="email">Email</label>
                    <FormInput
                      type="text"
                      id="email"
                      autoComplete="email"
                      placeholder={translate('enterYourEmail')}
                      name="email"
                      onChange={this.handleChange}
                      value={email}
                    />
                    {submitted && (!email || !emailValidator.validate(email)) &&
                      <small className="text-danger">{translate('email')} {translate('email')}</small>
                    }
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password">{translate('password')}</label>
                    <FormInput
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      placeholder={translate('enterPassword')}
                      name="password"
                      onChange={this.handleChange}
                      value={password}
                    />
                    {submitted && (!password) &&
                        <small className="text-danger">{translate('password')} {translate('password')}</small>
                    }
                  </FormGroup>
                  {waiting &&
                      <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
                  }
                  <Button
                    pill
                    theme="primary"
                    className="d-table mx-auto"
                    type="submit"
                  >
                    {translate('login')}
                  </Button>
                </Form>
              </CardBody>
            </Card>
            {/* Meta Details */}
            <div className="auth-form__meta d-flex mt-4">
              <Link to="/app/forgot-password">{translate('lostPassword')}</Link>
              <Link to="/app/register" className="ml-auto">
                {translate('createAccount')}{translate('space')}?
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}


function mapStateToProps( { user, userInterface } ) {
  return { user, userInterface }
}

export default hot(connect(mapStateToProps)(withTranslate(Login)))
