import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarToggler
} from "shards-react";
import { func, object } from 'prop-types'

import React from "react";
import { NavLink as RouteNavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { logout } from '../../../reducers/app/user.reducer'
import { withTranslate, IntlActions } from 'react-redux-multilingual'
import flagFR from '../../../images/fr.svg'
import flagEN from '../../../images/en.svg'



class WebNavbar extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    user: object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      collapseOpen: false,
      open: false
    };
  }

  // @autobind with array func
  toggleNavbar = () => {
    this.setState({
      ...this.state,
      ...{
        collapseOpen: !this.state.collapseOpen
      }
    });
  }

  // @autobind with array func
  toggleDropdown = () => {
    this.setState({
      ...this.state,
      ...{
        open: !this.state.open
      }
    });
  }

  handleLogout = () => {
    this.props.dispatch(logout())
  }

  render() {
    const { logged, lastname, firstname }  = this.props.user
    const { translate } = this.props

    return (
      <Navbar type="dark" className="web-navbar p-0" expand="md" sticky="true">
        <NavLink tag={RouteNavLink} to="/" className="font-size-lg font-weight-bold font-family-montserrat ml-4 text-white">Module-market</NavLink>
        <NavbarToggler className="mr-2" onClick={this.toggleNavbar} />

        <Collapse open={this.state.collapseOpen} navbar>
          <Nav navbar className="ml-auto">
          {!logged && <React.Fragment>
              <NavItem>
                <NavLink tag={RouteNavLink} to="/app/register" className="d-flex justify-content-center align-items-center bg-info py-3 text-white">
                  <span><i className="material-icons font-size-lg">person</i></span>&nbsp;<span>{translate('createAccount')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RouteNavLink} to="/app/login" className="d-flex justify-content-center align-items-center py-3 btn-primary text-dark">
                  <span><i className="material-icons font-size-lg">how_to_reg</i></span>&nbsp;<span>{translate('login')}</span>
                </NavLink>
              </NavItem>
            </React.Fragment>
          }
          {logged && lastname && firstname && 
            <Dropdown className="nav-item py-2 mr-5" open={this.state.open} toggle={this.toggleDropdown} direction="down">
              <DropdownToggle nav caret>
                {lastname} {firstname}
              </DropdownToggle>
              <DropdownMenu small>
                <DropdownItem tag={RouteNavLink} to="/app/books">
                  {translate('dashboard')}
                </DropdownItem>
                <DropdownItem onClick={this.handleLogout}>
                  {translate('logout')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          }
            {/* <NavItem>
                <NavLink href="#" onClick={() => { this.props.dispatch(IntlActions.setLocale('fr'))}}  className="d-flex justify-content-center align-items-center py-3">
                  <img width="30px" src={flagFR} alt=""/>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#" onClick={() => { this.props.dispatch(IntlActions.setLocale('en'))}} className="d-flex justify-content-center align-items-center py-3">
                  <img width="30px" src={flagEN} alt=""/>
                </NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps( { user } ) {
  return { user };
}

export default hot(connect(mapStateToProps)(withTranslate(WebNavbar)));