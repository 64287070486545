import { applyMiddleware, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'

import { composeWithDevTools } from 'redux-devtools-extension'
//import { createLogger } from 'redux-logger'
import rootReducer from '../reducers'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
//import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import thunkMiddleware from 'redux-thunk'
//import crossBrowserListener from './redux-persist-listener'

//const loggerMiddleware = createLogger()

export const persistConfig = {
    key: 'root',
    storage,
    //stateReconciler: hardSet
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
    persistedReducer,
    { Intl: { locale: 'fr' }},
    (process.env.NODE_ENV === 'development') ? composeWithDevTools(applyMiddleware(thunkMiddleware)) : applyMiddleware(thunkMiddleware),
    //composeWithDevTools(applyMiddleware(thunkMiddleware,loggerMiddleware))
)

export const persistor = persistStore(store)

//window.addEventListener('storage', crossBrowserListener(store, persistConfig))




// Old version wihout tabsync
/* 
import { applyMiddleware, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'

import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import rootReducer from '../reducers'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunkMiddleware from 'redux-thunk'

//const loggerMiddleware = createLogger()

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
    persistedReducer,
    composeWithDevTools(
        applyMiddleware(
            thunkMiddleware,
            //loggerMiddleware
        )
    )
)

export const persistor = persistStore(store) */