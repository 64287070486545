import React from "react"
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button
} from "shards-react"
import dateFormat from "dateformat"
import { Link } from 'react-router-dom'
import { withTranslate } from 'react-redux-multilingual'



const LatestOrders = ({ title, items, translate }) => (
  <Card small className="lo-stats h-100">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>

    <CardBody className="p-0">
      <Container fluid className="px-0">
        
        {items && items.length > 0 && <table className="table mb-0">
          <thead className="py-2 bg-light text-semibold border-bottom">
            <tr>
              <th width="22.5%">{translate('ref')}</th>
              <th width="22.5%">{translate('date')}</th>
              <th width="22.5%" className="text-center">{translate('products')}</th>
              <th width="22.5%" className="text-center">{translate('total')}</th>
              <th width="10%" className="text-center">{translate('action')}</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, idx) => (
              <tr key={idx}>
                <td className="lo-stats__image">
                 #{item.ref}
                </td>
                <td className="lo-stats__items">
                  <span>{dateFormat(new Date(item.createdAt), "dd/mm/yyyy HH:MM:ss")}</span>
                </td>
                <td className="lo-stats__items text-center">{item.totalProductsQuantity}</td>
                <td className="lo-stats__total text-center text-success">
                  <Badge pill theme="success">{item.totalPriceInclTax}€</Badge>
                </td>
                <td className="lo-stats__total text-center text-success">
                <ButtonGroup size="sm" className="d-table mx-auto">
                  <Button theme="white" tag={Link} to={`/app/order/${item.idOrder}`}>
                    <i className="material-icons">&#xE254;</i>
                  </Button>
                </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>}
        {(!items || items.length === 0) && <Row className="px-3">
            <Col><p className="text-center font-size-sm text-light m-0">{translate('noData')}</p></Col>
          </Row>}
      </Container>
    </CardBody>
    
  </Card>
)

export default withTranslate(LatestOrders)
