import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from "shards-react"

import React from "react"
import { hot } from 'react-hot-loader/root'
import  Steps from '../../components/shop/order/Steps'
import { resetPassword } from '../../reducers/shop/customer.reducer'
import ResetPasswordForm from "../../components/forms/ResetPasswordForm"
import { withTranslate } from 'react-redux-multilingual'


class ResetPassword extends React.Component{

    render(){
        const { match: { params : { token, key } }, translate } =  this.props
        
        return (
            <Container fluid className="main-content-container bg-dark">
                <Row className="page-header justify-content-center py-5">
                    <Col xl="7" md="11">
                        <Steps step={2} bookKey={key} />
                        <Card>
                            <CardHeader className="border-bottom bg-light">{translate('login')}</CardHeader>
                            <CardBody>
                                <Row className="justify-content-center">
                                    <Col lg="6">
                                        <div className="border p-4 rounded" >
                                            <ResetPasswordForm handler={resetPassword} token={token} />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="border-top bg-light"></CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default hot(withTranslate(ResetPassword))