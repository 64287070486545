import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#e1e5eb'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#e1e5eb',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '81.67%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '18.33%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableFooter = ({totalPriceInclTax, shippingCostsInclTax, discount, translate}) => {
    return(  
        <>
            <View style={styles.row}>
                <Text style={styles.description}>{translate('shippingCosts')}</Text>
                <Text style={styles.total}>{shippingCostsInclTax}</Text>
            </View>
            {discount > 0 && <View style={styles.row}>
                <Text style={styles.description}>{translate('discount')}</Text>
                <Text style={styles.total}>- {discount}</Text>
            </View>}
            <View style={styles.row}>
                <Text style={styles.description}>{translate('total')}</Text>
                <Text style={styles.total}>{totalPriceInclTax}</Text>
            </View>
        </>
    )
};
  
  export default InvoiceTableFooter