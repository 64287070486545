import React from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import PageTitle from "../../components/common/PageTitle"
import { 
    Container,
    Row,
    Col
} from "shards-react"

import ConfigPayment from "../../components/user-profile/ConfigPayment"
import ConfigPrice from "../../components/user-profile/ConfigPrice"
import ConfigStock from "../../components/user-profile/ConfigStock"
import ShippingCosts from "../../components/user-profile/ShippingCosts"
import CollectionShops from "../../components/user-profile/CollectionShops"
import { withTranslate } from 'react-redux-multilingual'
import DiscountPrices from '../../components/user-profile/DiscountPrices'



class UserShop extends React.Component {

    render() {
        const { location, translate } = this.props
        
        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle title={translate('settings')} subtitle={translate('account')} md="12" className="ml-sm-auto mr-sm-auto" />
                </Row>
                <Row>
                    <Col md="12">
                        <ConfigPrice />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <ConfigPayment location={location} />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <ConfigStock />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <CollectionShops />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <ShippingCosts />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <DiscountPrices />
                    </Col>
                </Row>
          </Container>
        )
    }
}

const mapStateToProps = ( { user } ) => { 
    return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(UserShop)))