import React from "react"
import { NavItem, NavLink, Collapse, DropdownItem } from "shards-react";
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { toggleHelp } from '../../reducers/app/userInterface.reducer'

class Help extends React.Component {
    handleClick = () => {
        this.props.dispatch(toggleHelp())
    }

    render() {
        const { children, className, helpVisible } = this.props

        return (
            <NavItem className={`dropdown notifications ${className}`}>
                <NavLink
                className="nav-link-icon text-center py-0"
                onClick={this.handleClick}
                >
                <div className="nav-link-icon__wrapper">
                    <i className="material-icons text-primary">help</i>
                </div>
                </NavLink>
                <Collapse
                open={helpVisible}
                className="dropdown-menu dropdown-menu-small"
                >
                {children}
                <DropdownItem className="notification__all text-center"></DropdownItem>
                </Collapse>
            </NavItem>
        );
    }
}

function mapStateToProps( { userInterface : { helpVisible } } ) {
    return { helpVisible }
  }

export default hot(connect(mapStateToProps)(Help))
