import { Nav, NavItem, NavLink, Navbar, Modal, ModalBody, ModalHeader } from "shards-react"
import React from "react"
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'


class ShopFooter extends React.Component {

    constructor(props){
        super(props)

        this.state = { open: false }
    }

    toggleModal = () => {
        this.setState({
            open: !this.state.open
        })
    }

    render(){
        const { book : { activeEshop, terms }, translate } = this.props
        const { open } = this.state

        return (
            <React.Fragment>
            {activeEshop && <React.Fragment>
                    <Navbar type="dark" className="web-navbar py-2" expand="md" >
                        <Nav navbar>
                            <NavItem>
                                <NavLink className="cursor-pointer" onClick={this.toggleModal}>{translate('termsAndConditions')}</NavLink>
                            </NavItem>
                        </Nav>
                    </Navbar>
                    <Modal open={open} size="lg" centered toggle={this.toggleModal}>
                        <ModalHeader className="bg-light" toggle={this.toggleModal}>{translate('termsAndConditions')}</ModalHeader>
                        <ModalBody>
                            <div className="terms" dangerouslySetInnerHTML={{ __html: terms }}></div>
                        </ModalBody>
                    </Modal>
                </React.Fragment>
            }
            {process.env.NODE_ENV === 'development' &&
                <div className="media-queries">
                    <div className="media-querie d-block d-sm-none">xs</div>
                    <div className="media-querie d-none d-sm-block d-md-none">sm</div>
                    <div className="media-querie d-none d-md-block d-lg-none">md</div>
                    <div className="media-querie d-none d-lg-block d-xl-none">lg</div>
                    <div className="media-querie d-none d-xl-block">xl</div>
                </div>}
            </React.Fragment>
        )
    }
}


function mapStateToProps( { shop : { book } } ) {
    return { book }
}
  
export default hot(connect(mapStateToProps)(withTranslate(ShopFooter)))
