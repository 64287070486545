import { Col, Container, Row } from "shards-react"

import React from "react"
import { hot } from 'react-hot-loader/root'

class FrequentlyAskedQuestions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section>
            <Container className="py-5">

            <Row noGutters className="justify-content-center">
                <Col md="12">
                    <h1 className="main-title mt-4 font-weight-bold">Questions fréquentes</h1>
                    <p className="mt-5 font-size-lg">Mettre en oeuvre un application de vente en ligne est un véritable projet d’entreprise, avec des enjeux et des conséquences importantes, qui soulève de nombreuses questions. C’est pourquoi nous vous proposons cette section "Questions fréquentes". Elle a pour objectif de rassembler les principales questions que se posent nos clients.</p>
                    
                    <h3 className="mt-5">1. Qu’est ce que la plateforme Pdfmarket ?</h3>
                    <p>PdfMarket est une la plateforme de click and collect à destination des entreprises (commerçants, restaurateurs, bouchers, chocolatiers…). Avec notre solution, vous disposez d’une visibilité sur internet via une boutique virtuelle en ligne. En plus de votre adresse physique vous proposez à votre clientèle un nouveau canal de vente avec la commande  ou l’achat en ligne.Avec pdfmarket, vous permettez à vos clients de commander en ligne vos produits et de venir les retirer dans votre point de vente.</p>

                    <h3 className="mt-5">2. Quels sont les avantages de pdfMarket ?</h3>
                    <ul>
                        <li>Bon de commande en ligne</li>
                        <li>Achat en ligne par CB</li>
                        <li>Gestion des coordonnées des clients</li>
                        <li>Tableau de bord, statistiques...</li>
                    </ul>

                    <h3 className="mt-5">3. Comment ça marche pdfMarket ?</h3>
                    <p>il suffit d’avoir un pdf pour créer des zones réactives pour associer une image, un descriptif et un prix.
                    Un nouveau lien est créer donner la possibilité d’ajouter les produits au panier.</p>

                    <h3 className="mt-5">4. Quels moyens de paiement sont disponibles sur pdfmarket ?</h3>
                    <p>La mise en place du paiement en ligne par CB est rapide via l’application intégrée Stripe.
                    Votre déploiement en ligne est effectif en moins de 24h.</p>

                    <h3 className="mt-5">5. Puis-je tester vos solutions ?</h3>
                    <p>Oui, c’est gratuit.  Il vous suffit d’avoir un pdf sous la main.<br/>
                    Connectez-vous  à la plateforme : identifiant : contact@module-market.fr / mot de passe : pdfmarket</p>

                    <h3 className="mt-5">6. Je n’ai pas de site internet ?</h3>
                    <p>Vous n’avez pas de site internet, nous pouvons nous chargez pour vous de l’achat du nom de domaine, de l’hébergement et de la création de vos pages. demandez nous un devis.</p>

                    <h3 className="mt-5">7. J’ai un site internet, comment relier mon pdfMarket ?</h3>
                    <p>Vous avez un site, il suffit de créer un bouton  la boutique pour relier votre site à votre pdfMarket.</p>

                    <h3 className="mt-5">8. Vous n’avez pas de pdf ?</h3>
                    <p>Pas de soucis, nous pouvons le réaliser pour vous pour 20€HT/page. Demandez-nous un devis.</p>

                    <h3 className="mt-5">9. Puis-je déposer mes conditions générales de vente ?</h3>
                    <p>Oui au moment de la création de votre boutique e-commerce.</p>

                    <h3 className="mt-5">10. Avons nous besoin d’une formation pour l’utilisation du produit ?</h3>
                    <p>L’interface est intuitive et ergonomique. Tout à été pensé pour vous simplifier la prise en main. Nous sommes à votre disposition si vous avez besoin d’aide.</p>

                    <h3 className="mt-5">11. Puis-je gérer modifier mon catalogue en cours de route ?</h3>
                    <p>Oui c’est possible par contre vous serez dans l’obligation de recréer vos liens vers vos produits.
                    PdfMarket a été pensé en priorité pour les entreprises ayant peu de produits.</p>

                    <h3 className="mt-5">12. Comment je suis alerté par une commande ?</h3>
                    <p>Vous recevez vos commandes directement sur votre adresse mail renseignée lors de l’achat de notre solution.</p>

                    <h3 className="mt-5">13. J’ai besoin d’une solution sur mesure ?</h3>
                    <p>Pas de soucis, faites nous part de vos besoins, nous pouvons mettre en place des formules adaptées.</p>
                </Col>
            </Row>
            </Container>
        </section>
      </React.Fragment>
    )
  }
}

export default hot(FrequentlyAskedQuestions);
