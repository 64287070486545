/* eslint jsx-a11y/anchor-is-valid: 0 */

import {
    Button,
    Form,
    FormGroup,
    FormInput,
  } from "shards-react"
  import { func, object } from 'prop-types'
  
  import React from "react"
  import { connect } from 'react-redux'
  import emailValidator from 'email-validator'
  import { hot } from 'react-hot-loader/root'
  import { withTranslate } from 'react-redux-multilingual'

  
class RegisterForm extends React.Component {
    static propTypes = {
      dispatch: func.isRequired,
      userInterface: object.isRequired
    }
  
    constructor(props){
      super(props)
  
      this.state = {
        lastname: '',
        firstname: '',
        email: '',
        password: '',
        submitted: false
      }
    }
  
    // Autobind with arrow func
    handleChange = (e) => {
      const { name, value } = e.target
      this.setState({ [name]: value })
    }
  
    // Autobind with arrow func
    handleSubmit = (e) => {
      e.preventDefault()
  
      this.setState({ submitted: true })
      const { lastname, firstname, email, password } = this.state
      const register = this.props.handler
  
      if (lastname && firstname && email && emailValidator.validate(email) && password && password.length > 7) {
          this.props.dispatch(register(lastname, firstname, email, password))
      }
    }
  
  
    render(){
      const { userInterface : { waiting }, translate } = this.props
      const { lastname, firstname, email, password, submitted } = this.state
  
      return (
        <Form onSubmit={this.handleSubmit}>
            <FormGroup>
                <label htmlFor="lastname">{translate('name')}</label>
                <FormInput
                    type="text"
                    id="lastname"
                    placeholder={translate('enterYourName')}
                    name="lastname"
                    onChange={this.handleChange}
                />
                {submitted && !lastname &&
                    <small className="text-danger">{translate('name')} {translate('required')}</small>
                }
            </FormGroup>
            <FormGroup>
                <label htmlFor="firstname">{translate('surname')}</label>
                <FormInput
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder={translate('enterYourSurname')}
                    onChange={this.handleChange}
                />
                {submitted && !firstname &&
                    <small className="text-danger">{translate('surname')} {translate('required')}</small>
                }
            </FormGroup>
            <FormGroup>
                <label htmlFor="email">{translate('email')}</label>
                <FormInput
                    type="email"
                    id="email"
                    name="email"
                    placeholder={translate('enterYourEmail')}
                    onChange={this.handleChange}
                />
                {submitted && (!email || !emailValidator.validate(email)) &&
                    <small className="text-danger">{translate('email')} {translate('required')}</small>
                }
            </FormGroup>
            <FormGroup>
                <label htmlFor="password">{translate('password')}</label>
                <FormInput
                    type="password"
                    id="password"
                    name="password"
                    placeholder={translate('enterPassword')}
                    onChange={this.handleChange}
                />
                {submitted && (!password || password.length < 8) &&
                    <small className="text-danger">{translate('passwordGreaterThan')}</small>
                }
            </FormGroup>
            {waiting &&
                <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
            }
            <Button
            theme="primary"
            className="float-right"
            type="submit"
            >
            <i className="material-icons">person</i> {translate('createAccount')}
            </Button>
        </Form>
        )}
  }
  
  function mapStateToProps( { userInterface } ) {
    return { userInterface };
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(RegisterForm)))
  