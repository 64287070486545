import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  FormInput,
  Row
} from "shards-react";
import { func, object } from 'prop-types'

import { Link } from "react-router-dom";
import React from "react";
import { connect } from 'react-redux'
import emailValidator from 'email-validator'
import { hot } from 'react-hot-loader/root'
import {lostPassword} from '../../reducers/app/user.reducer'
import { withTranslate } from 'react-redux-multilingual'


class ForgotPassword extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    userInterface: object.isRequired
  }
  
  constructor(props){
    super(props)

    this.state = {
      email: '',
      submitted: false
    }
  }

  // Autobind with arrow func
  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  // Autobind with arrow func
  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({ submitted: true })
    const { email } = this.state

    if (email && emailValidator.validate(email)) {
        this.props.dispatch(lostPassword(email))
    }
  }

  render(){
    const { waiting } = this.props.userInterface
    const { email, submitted } = this.state
    const { translate } = this.props

    return(
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100">
          <Col lg="3" md="5" className="auth-form mx-auto my-auto">
            <Card>
              <CardBody>
                {/* Title */}
                <div className="font-size-lg font-weight-bold font-family-montserrat text-center text-primary mb-3">Module E-commerce</div>

                {/* Form Fields */}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">{translate('email')}</label>
                    <FormInput
                      type="email"
                      id="exampleInputEmail1"
                      name="email"
                      placeholder={translate('enterYourEmail')}
                      autoComplete="email"
                      onChange={this.handleChange}
                    />
                    {submitted && (!email || !emailValidator.validate(email)) &&
                      <small className="text-danger">{translate('email')} {translate('required')}</small>
                    }
                    <small className="form-text text-muted text-center">
                    {translate('textLostPassword')}
                    </small>
                  </FormGroup>
                  {waiting &&
                    <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
                  }
                  <Button
                    pill
                    theme="primary"
                    className="d-table mx-auto"
                    type="submit"
                  >
                     {translate('generatePassword')}
                  </Button>
                </Form>
              </CardBody>
            </Card>

            {/* Meta Details */}
            <div className="auth-form__meta d-flex mt-4">
              <Link to="/app/login" className="mx-auto">
                {translate('backToLoginPage')}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}


function mapStateToProps( { userInterface } ) {
  return { userInterface }
}

export default hot(connect(mapStateToProps)(withTranslate(ForgotPassword)))
