import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  FormInput,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react"
import { func, object } from 'prop-types'

import Loader from '../common/Loader'
import React from "react"
import { connect } from 'react-redux'
import emailValidator from 'email-validator'
import { hot } from 'react-hot-loader/root'
import { udapteUser } from '../../reducers/app/user.reducer'
import { withTranslate } from 'react-redux-multilingual'


class UserAccountDetails extends React.Component {

  static propTypes = {
    dispatch: func.isRequired,
    user: object.isRequired,
  }

  constructor(props) {
    super(props)

    const { user } = this.props

    this.state = {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      password: '',
      submitted: false
    };
  }
  
  // Autobind with arrow func
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  // Autobind with arrow func
  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({ submitted: true });
    const { email, password, firstname, lastname } = this.state
    const { user } = this.props
    const updatedUser = {}
    let error = false

    // Check email
    if(email && email !== user.email){
      if(emailValidator.validate(email)){
        updatedUser.email = email
      } else {
        error = true
      }
    }

    // Check password
    if(password){
      if(password.length > 7){
        updatedUser.password = password
      } else {
        error = true
      }
    }

    // Check firstname && lastname
    if(!firstname || firstname.length === 0 || !lastname || lastname.length === 0){
      error = true
    } else {
      updatedUser.firstname = firstname
      updatedUser.lastname = lastname
    }

    // Update user if no error
    if (!error) {
      this.props.dispatch(udapteUser(updatedUser))
    }
  }

  render(){
    const { firstname, lastname, email, password, submitted } = this.state
    const { translate } = this.props

    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{translate('userInformation')}</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form onSubmit={this.handleSubmit}>
                  <Row form>
                    {/* First Name */}
                    <Col md="6" className="form-group">
                      <label htmlFor="lastname">{translate('name')}</label>
                      <FormInput
                        id="lastname"
                        name="lastname"
                        placeholder={translate('enterYourName')}
                        value={lastname}
                        onChange={this.handleChange}
                      />
                      {submitted && !lastname &&
                        <small className="text-danger">{translate('name')} {translate('required')}</small>
                      }
                    </Col>
                    {/* Last Name */}
                    <Col md="6" className="form-group">
                      <label htmlFor="firstname">{translate('surname')}</label>
                      <FormInput
                        id="firstname"
                        name="firstname"
                        placeholder={translate('enterYourSurname')}
                        value={firstname}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    {/* Email */}
                    <Col md="6" className="form-group">
                      <label htmlFor="email">Email</label>
                      <FormInput
                        type="email"
                        id="email"
                        name="email"
                        placeholder="enterYourEmail"
                        value={email}
                        autoComplete="email"
                        onChange={this.handleChange}
                      />
                      {submitted && email && !emailValidator.validate(email) &&
                        <small className="text-danger">{translate('email')} {translate('required')}</small>
                      }
                    </Col>
                    {/* Password */}
                    <Col md="6" className="form-group">
                      <label htmlFor="password">{translate('password')}</label>
                      <FormInput
                        type="password"
                        id="password"
                        name="password"
                        placeholder={translate('enterPassword')}
                        value={password}
                        autoComplete="current-password"
                        onChange={this.handleChange}
                      />
                      {submitted && password && password.length < 8 &&
                        <small className="text-danger">{translate('passwordGreaterThan')}</small>
                      }
                    </Col>
                  </Row>
                  <Loader className="d-block" />
                  <Button theme="primary" type="submit">{translate('update')}</Button>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
  }
}

const mapStateToProps = ( { user } ) => { 
  return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(UserAccountDetails)))
