import { Button, Col, Row, Modal, ModalBody, ModalHeader } from "shards-react"

import React from "react"
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


class DisplayShopAs extends React.Component{
    render() {
        const { changeDisplayAs, translate } = this.props

        return (
            <Modal backdropClassName="bg-primary opacity-1" size="md" centered={true} open={true}>
                <ModalHeader className="bg-light">{translate('seeProductsOfStore')}</ModalHeader>
                <ModalBody>
                    <Row className="justify-content-center">
                        <Col>
                            <div className="px-2 text-center">
                                <Button theme="white" block onClick={() => changeDisplayAs(1)}>
                                    <i className="material-icons font-size-xxl">menu_book</i>
                                    <span className="d-block font-weight-bold">{translate('asReader')}</span>
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className="px-2 text-center">
                                <Button theme="white" block onClick={() => changeDisplayAs(2)}>
                                    <i className="material-icons font-size-xxl">view_list</i>
                                    <span className="d-block font-weight-bold">{translate('asList')}</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>)
    }
}

function mapStateToProps( { shop : { book } } ) {
    return { book }
}
export default hot(connect(mapStateToProps)(withTranslate(DisplayShopAs)))
