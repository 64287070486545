import {
    NavItem,
    NavLink
} from "shards-react"
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'
import moment from 'moment'
moment().format()
  
  class RemainingCredits extends React.Component {
  
    toggleUserActions = () => {
      this.setState({
        visible: !this.state.visible
      })
    }
  
    // Autobind with arrow func
    handleClick = (e) => {
      e.preventDefault()
  
    }
  
    render() {
        const { credits, creditsExpiredAt } = this.props.user
        const { locale } = this.props.Intl
        const { translate } = this.props

        const creditsExpiredAtTranslate = (locale === 'fr') ? moment(creditsExpiredAt).format('DD/MM/YYYY') : moment(creditsExpiredAt).format('YYYY-MM-DD')

        return (
            <NavItem className="py-1">
                <NavLink tag="div" className={creditsExpiredAt ? "px-3 py-2 d-none d-sm-block" : "px-3 py-3 d-none d-sm-block" }>{translate('credits')} : {!credits ? translate('none') : credits === 1 ? translate('package1TitleBis') : translate('package2titleBis')}
                {creditsExpiredAt && <small className="d-block">{translate('expiredAt')}{translate('space')}: {creditsExpiredAtTranslate}</small>}
                </NavLink>
            </NavItem>
        )
    }
  }
  
  const mapStateToProps = ( { user, Intl } ) => { 
    return { user, Intl }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(RemainingCredits)))