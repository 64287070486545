import { Container } from "shards-react";
import React from "react";

export const Error404 = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>404</h2>
        <h3>Page non trouvée</h3>
        <p>La page que vous recherchez n'existe pas.</p>
      </div>
    </div>
  </Container>
)

export const Error500 = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>500</h2>
        <h3>Erreur interne</h3>
        <p>Veuillez nous excuser pour le dérangement.</p>
      </div>
    </div>
  </Container>
)

export const Maintenance = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>500</h2>
        <h3>Application en maintenance</h3>
        <p>Veuillez nous excuser pour le dérangement.</p>
      </div>
    </div>
  </Container>
)

export const Loading = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h3>Chargement...</h3>
      </div>
    </div>
  </Container>
)
