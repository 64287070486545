import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    ListGroup,
    ListGroupItem,
    FormTextarea
  } from "shards-react"
  
  import PageTitle from "../../components/common/PageTitle"
  import React from "react"
  import { connect } from 'react-redux'
  import { hot } from 'react-hot-loader/root'
  import { getOrder } from '../../reducers/app/orders.reducer'
  import { getStatus } from '../../utils/status'
  import { round } from 'mathjs'
  import dateFormat from "dateformat"
  import { withTranslate } from 'react-redux-multilingual'

  
  class OrderDetail extends React.Component {
  
    componentDidMount() {
        const { match : { params : { idOrder } } } = this.props

        this.props.dispatch(getOrder(idOrder))
    }

    render() {
        const { user, orders : { items = [] }, match : { params : { idOrder } }, translate } = this.props
        const index = items.findIndex(item => item.idOrder === parseInt(idOrder))
        const order = items[index]

        return (
            <React.Fragment>
                {order && 
                <Container fluid className="main-content-container px-4 pb-4">
                    <Row noGutters className="page-header py-4">
                        <PageTitle title={`${translate('order')} n°${order.ref}`} subtitle={translate('orders')} className="text-sm-left mb-3" />
                    </Row>
                    <Row>
                        {/* Editor */}
                        <Col lg="9" md="12">
                            <Card small className="mb-4">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0 font-weight-bold">{translate('products')}</h6>
                                </CardHeader>
                                <CardBody className="p-0 pb-3">
                                    <table className="table mb-0">
                                        <thead className="bg-light">
                                            <tr>
                                                <th scope="col" className="border-0" width="20%">
                                                    {translate('name')}
                                                </th>
                                                <th scope="col" className="border-0 text-center" width="20%">
                                                    {translate('quantity')}
                                                </th>
                                                <th scope="col" className="border-0 text-right" width="20%">
                                                    {translate('price')} <sup>{translate('exclTax')}</sup>
                                                </th>
                                                <th scope="col" className="border-0 text-right" width="20%">
                                                    {translate('price')} <sup>{translate('inclTax')}</sup>
                                                </th>
                                                <th scope="col" className="border-0 text-right" width="20%">
                                                    {translate('total')} <sup>{translate('inclTax')}</sup>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order.orderdetail && order.orderdetail.map((product,idx) => 
                                                (
                                                    <tr key={idx}>
                                                        <td>{product.productName}</td>
                                                        <td className="text-center">{product.productQuantity}</td>
                                                        <td className="text-right">{product.productPriceExclTax}</td>
                                                        <td className="text-right">{product.productPrice} €</td>
                                                        <td className="text-right">{round(product.productPrice * product.productQuantity,2)} €</td>
                                                    </tr>         
                                                )
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="4" className="text-right">{translate('shippingCosts')}</td>
                                                <td className="text-right">{order.shippingCostsInclTax} €</td>
                                            </tr>
                                            {order.discount > 0 && <tr>
                                                <td colSpan="4" className="text-right">{translate('discount')}</td>
                                                <td className="text-right">- {order.discount} €</td>
                                            </tr>}
                                            <tr>
                                                <td colSpan="4" className="text-right">{translate('total')}</td>
                                                <td className="text-right">{round(order.totalPriceInclTax,2)} €</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </CardBody>
                            </Card>
                            <Row>
                                {order.customer && order.customer.addresses && order.customer.addresses.map((address, idx) => {       
                                    return (
                                        <Col lg="6" md="12" key={idx}>
                                            <Card small className="mb-4">
                                                <CardHeader className="border-bottom">
                                                    <h6 className="m-0 font-weight-bold">
                                                        {address.name === 'delivery' ? translate('shippingAddress') : translate('billingAddress')}
                                                    </h6>
                                                </CardHeader>
                                                <CardBody className="">
                                                    <div>{address.lastname} {address.firstname}</div>
                                                    <div>{address.phone}</div>
                                                    <div>{address.address}</div>
                                                    <div>{address.postcode}</div>
                                                    <div>{address.city}</div>
                                                </CardBody>
                                            </Card>
                                        </Col>)})
                                }  
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Card small className="mb-4">
                                        <CardHeader className="border-bottom">
                                            <h6 className="m-0 font-weight-bold">
                                                {translate('commentAssociated')}
                                            </h6>
                                        </CardHeader>
                                        <CardBody>
                                            <FormTextarea readOnly defaultValue={order.comment} style={{height:'100px'}} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="3" md="12">
                            <Card small className="mb-3">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0 font-weight-bold">{translate('infos')}</h6>
                                </CardHeader>
                                <ListGroup flush>
                                <ListGroupItem className="p-3">
                                    <span className="d-flex mb-2">
                                        <i className="material-icons mr-1">score</i>
                                        <strong className="mr-1">{translate('order')} {translate('number')} : {order.ref}</strong> 
                                    </span>
                                    <span className="d-flex mb-2">
                                        <i className="material-icons mr-1">done</i>
                                        <strong className="mr-1">{translate('status')} : <span className={`text-${getStatus(order.status).color}`}>{ translate(getStatus(order.status).name) }</span></strong>
                                    </span>
                                    <span className="d-flex mb-2">
                                        <i className="material-icons mr-1">calendar_today</i>
                                        <strong className="mr-1">{translate('date')} : {dateFormat(new Date(order.createdAt), "dd/mm/yyyy HH:MM:ss")}</strong>
                                    </span>
                                    <span className="d-flex mb-2">
                                        <i className="material-icons mr-1">face</i>
                                        <strong className="mr-1">{translate('customer')} : {order.customer.firstname} {order.customer.lastname} </strong>
                                    </span>
                                    <span className="d-flex mb-2">
                                        <i className="material-icons mr-1">email</i>
                                        <strong className="mr-1">{translate('email')} : {order.customer.email}</strong>
                                    </span>
                                    <span className="d-flex mb-2">
                                        <i className="material-icons mr-1">payments</i>
                                        <strong className="mr-1">{translate('transaction')} : {translate(order.transactionBy)}</strong>
                                    </span>
                                    {order.hasOwnProperty('cart') && order.cart.hasOwnProperty('idShippingCosts') && order.cart.idShippingCosts === 0 &&
                                        <>
                                            <span className="d-flex mb-2">
                                                <i className="material-icons mr-1">local_shipping</i>
                                                <strong className="mr-1">{translate('shipping')} : {translate('collectedOnSite')}</strong> 
                                            </span>
                                            <span className="d-flex mb-2">
                                                <i className="material-icons mr-1">home</i>
                                                <strong className="mr-1">{translate('address')} : {order.address}</strong> 
                                            </span>
                                        </>
                                    }
                                    {order.hasOwnProperty('cart') && order.cart.hasOwnProperty('idShippingCosts') && order.cart.idShippingCosts === 2 && 
                                        <span className="d-flex mb-2">
                                            <i className="material-icons mr-1">local_shipping</i>
                                            <strong className="mr-1">{translate('shipping')} : {translate('postalPackage')}</strong> 
                                        </span>
                                    }
                                    {order.hasOwnProperty('cart') && order.cart.hasOwnProperty('idShippingCosts') && order.cart.idShippingCosts === 1 && 
                                        <span className="d-flex mb-2">
                                            <i className="material-icons mr-1">local_shipping</i>
                                            <strong className="mr-1">{translate('shipping')} : {translate('free')}</strong> 
                                        </span>
                                    }
                                </ListGroupItem>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </Container>}
            </React.Fragment>
        )
    }
  }
  
  const mapStateToProps = ({ orders, user }) => {
    return { orders, user }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(OrderDetail)))
  