import React from "react";
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { toggleSidebar } from '../../../reducers/app/userInterface.reducer'

class NavbarToggle extends React.Component {

  // Autobind with arrow func
  handleClick = (e) => {
    e.preventDefault()
    this.props.dispatch(toggleSidebar())
  }

  render() {
    return (
      <nav className="nav">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={this.handleClick} className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center">
          <i className="material-icons">&#xE5D2;</i>
        </a>
      </nav>
    )
  }
}

export default hot(connect()(NavbarToggle));
