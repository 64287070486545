import {
    Card,
    CardHeader,
    CardBody,
    FormCheckbox
} from "shards-react"

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'
import { udapteUser } from "../../reducers/app/user.reducer"

  
class ConfigStock extends React.Component {
  
    constructor(props){
        super(props)

        this.state = {
            activeStock: false
        }
    }

    componentDidMount(){
        const { activeStock } = this.props.user

        this.setState({
            activeStock
        })
    }

    toggleChange = () => {
        this.setState((state)=>({
            activeStock: !state.activeStock
        }),() => {
            this.props.dispatch(udapteUser({ activeStock: this.state.activeStock }))
        })

    }

    render(){
        const { translate } = this.props
        const { activeStock } = this.state
        
        return (
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">{translate('stock')}</h6>
                </CardHeader>
                <CardBody>
                    <p>
                    {translate('enableStockForProducts')}
                    <FormCheckbox toggle onChange={this.toggleChange} checked={activeStock} className="mr-2 float-right"></FormCheckbox>
                    </p>
                </CardBody>
            </Card>
        )
    }
}
  
const mapStateToProps = ( { user } ) => { 
    return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(ConfigStock)))
  