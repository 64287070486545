import { Button, Col, Form, FormGroup, FormInput, Row } from "shards-react"

import React from "react"
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'


class AdressForm extends React.Component{

    constructor(props){
        super(props)

        this.state = {
          lastname: '',
          firstname: '',
          address: '',
          postcode: '',
          city: '',
          phone: '',
          submitted: false
        }
    }

    // Autobind with arrow func
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = (e) => {
        e.preventDefault()

        this.setState({ submitted: true })
        const { lastname, firstname, address, postcode, city, phone } = this.state
        const createAddress = this.props.handler
        const completedForm = this.props.completedForm
        
        if (lastname && firstname && address && postcode && city && phone.length >= 10) {
            this.props.dispatch(createAddress(lastname, firstname, address, postcode, city, phone))
            completedForm(true)
        } else {
            completedForm(false)
        }
    }

    componentDidMount(){
        const { lastname: oldLastname, firstname: oldFirstname, address: oldAddress, postcode: oldPostcode, city: oldCity, phone: oldPhone } = this.state
        const { lastname, firstname, address, postcode, city, phone } = this.props.data
        const completedForm = this.props.completedForm


        if(oldLastname !== lastname && oldFirstname !== firstname && oldAddress !== address && oldPostcode !== postcode && oldCity !== city && oldPhone !== phone){

            this.setState({
                lastname,
                firstname,
                address,
                postcode,
                city,
                phone
            })
        }

        if(lastname.length > 0 &&  firstname.length > 0 && address.length > 0 && postcode.length > 0 && city.length > 0 && phone.length > 1) completedForm(true)
    }

    componentDidUpdate(prevProps, prevState){
        const { lastname: oldLastname, firstname: oldFirstname, address: oldAddress, postcode: oldPostcode, city: oldCity, phone: oldPhone } = prevProps.data
        const { lastname, firstname, address, postcode, city, phone } = this.props.data
        const completedForm = this.props.completedForm

        if(oldLastname !== lastname && oldFirstname !== firstname && oldAddress !== address && oldPostcode !== postcode && oldCity !== city && oldPhone !== phone){

            this.setState({
                lastname,
                firstname,
                address,
                postcode,
                city,
                phone
            })

        }

        if(oldLastname !== lastname && oldFirstname !== firstname && oldAddress !== address && oldPostcode !== postcode && oldCity !== city && oldPhone !== phone && lastname.length > 0 &&  firstname.length > 0 && address.length > 0 && postcode.length > 0 && city.length > 0 && phone.length > 1) completedForm(true)
    }

    render(){
        const { submitted, lastname, firstname, address, postcode, city, phone } = this.state
        const { translate } = this.props
        
        return (                           
            <Form onSubmit={this.handleSubmit}>
                <Row className="justify-content-center">
                    <Col xl="12" md="12">
                        <FormGroup>
                            <label htmlFor="lastname">{translate('name')}</label>
                            <FormInput
                                type="text"
                                id="lastname"
                                placeholder={translate('enterYourName')}
                                name="lastname"
                                onChange={this.handleChange}
                                value={lastname}
                            />
                            {submitted && !lastname &&
                                <small className="text-danger">{translate('name')} {translate('required')}</small>
                            }
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="firstname">{translate('surname')}</label>
                            <FormInput
                                type="text"
                                id="firstname"
                                name="firstname"
                                placeholder={translate('enterYourSurname')}
                                onChange={this.handleChange}
                                value={firstname}
                            />
                            {submitted && !firstname &&
                                <small className="text-danger">{translate('surname')} {translate('required')}</small>
                            }
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="phone">{translate('phone')}</label>
                            <FormInput
                                type="number"
                                id="phone"
                                name="phone"
                                placeholder={translate('enterYourPhoneNumber')}
                                onChange={this.handleChange}
                                value={phone}
                            />
                            {((submitted && phone.length < 10) || (!submitted && phone.length < 10)) &&
                                <small className="text-danger">{translate('phone')} {translate('required')}</small>
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                    <Col xl="12" md="12">
                        <FormGroup>
                            <label htmlFor="address">{translate('address')}</label>
                            <FormInput
                                type="text"
                                id="address"
                                placeholder={translate('enterYourAddress')}
                                name="address"
                                onChange={this.handleChange}
                                value={address}
                            />
                            {submitted && !address &&
                                <small className="text-danger">{translate('address')} {translate('required')}</small>
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl="6" md="12">
                        <FormGroup>
                            <label htmlFor="postcode">{translate('postalCode')}</label>
                            <FormInput
                                type="number"
                                id="postcode"
                                placeholder={translate('enterYourPostalCode')}
                                name="postcode"
                                onChange={this.handleChange}
                                value={postcode}
                            />
                            {submitted && !postcode &&
                                <small className="text-danger">{translate('postalCode')} {translate('required')}</small>
                            }
                        </FormGroup>
                    </Col>
                    <Col xl="6" md="12">
                        <FormGroup>
                            <label htmlFor="city">{translate('city')}</label>
                            <FormInput
                                type="text"
                                id="city"
                                placeholder={translate('enterYourCity')}
                                name="city"
                                onChange={this.handleChange}
                                value={city}
                            />
                            {submitted && !city &&
                                <small className="text-danger">{translate('city')} {translate('required')}</small>
                            }
                        </FormGroup>
                    </Col>
                    <Col xl="12">
                        <Button type="submit" className="float-right mt-3" ><i className="material-icons">add_location</i> {translate('save')}</Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}


export default hot(connect()(withTranslate(AdressForm)))
