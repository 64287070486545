import React from "react"
import { hot } from 'react-hot-loader/root'
import Bowser from "bowser"
import { withTranslate } from 'react-redux-multilingual'

const browser = Bowser.getParser(window.navigator.userAgent)
const isValidBrowser = browser.satisfies({
    chrome: '>81',
    firefox: '>74',
    opera: '>66',
    edge: '>=81',
    safari: '>13'
})

class ValidBrowser extends React.Component {
    render() {
        const { translate } = this.props

        return (
            <React.Fragment>
                {!isValidBrowser && <a href="https://browsehappy.com/" target="_blank" rel="noopener noreferrer" className="bg-warning text-center text-white d-block py-1" role="alert">
                    <small>{translate('pleaseUpdateYourBrowser')}</small>
                </a>}
            </React.Fragment>
        )
    }
}

export default hot(withTranslate(ValidBrowser))
