import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Container,
    Row
  } from "shards-react"
  
  import { Link } from 'react-router-dom'
  import React from "react"
  import ReactTable from "react-table"
  import dateFormat from "dateformat"
  import { connect } from 'react-redux'
  import { hot } from 'react-hot-loader/root'
  import { getOrders } from '../../reducers/shop/orders.reducer'
  import { getStatus } from '../../utils/status'
  import { history } from '../../utils/history'
  import  Steps from '../../components/shop/order/Steps'
  import { withTranslate } from 'react-redux-multilingual'

  
  
class CustomerOrders extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
        tableData: []
      }
  
      this.searcher = null
    }

    componentDidMount() {
        this.props.dispatch(getOrders())
    }
  
    /**
     * Handles the page size change event.
     */
    handlePageSizeChange = (e) => {
      this.setState({
        ...this.state,
        pageSize: e.target.value
      });
    }
  
    /**
     * Handles the global search.
     */
    handleFilterSearch = (e) => {
      this.setState({
        ...this.state,
        tableData: this.searcher.search(e.target.value)
      });
    }
  
    handlePageChange = (pageIndex) => {
      const { pathname } = history.location
      const numPage = pageIndex + 1
  
      history.push({
        pathname: pathname,
        search:`?numPage=${numPage}`
      })
    }
  
    render() {
      const { orders: { history : { items } }, match: { params : { key } }, translate } = this.props
      const { pageSize } = this.state
      const { search } = history.location
      let numPage = new URLSearchParams(search.substring(1)).get("numPage")
      numPage = parseInt(numPage) ? parseInt(numPage) - 1 : 0

  
      const tableColumns = [
        {
          Header: translate('ref'),
          accessor: "ref",
          minWidth: 130,
          maxWidth: 200,
          className: "text-center",
          sortable: false,
          Cell: row => `#${row.original.ref}`
        },
        {
          Header: translate('date'),
          accessor: "date",
          className: "text-center",
          minWidth: 180,
          Cell: row => dateFormat(new Date(row.original.createdAt), "dd/mm/yyyy HH:MM:ss")
        },
        {
          Header: translate('products'),
          accessor: "products",
          maxWidth: 120,
          minWidth: 80,
          className: "text-center",
          Cell: row => row.original.totalProductsQuantity
        },
        {
          Header: translate('status'),
          accessor: "status",
          minWidth: 100,
          Cell: row => {
            const status = getStatus(row.original.status)
  
            return (
              <span className={`text-${status.color}`}>
                {translate(status.name)}
              </span>
            )
          },
          className: "text-center"
        },
        {
          Header: translate('total'),
          accessor: "total",
          minWidth: 100,
          Cell: row => <span className="text-success">{row.original.totalPriceInclTax} €</span>,
          className: "text-center"
        },
        {
          Header: translate('transaction'),
          accessor: "transactionBy",
          minWidth: 100,
          Cell: row => translate(row.original.transactionBy),
          className: "text-center"
        },
        {
          Header: translate('actions'),
          accessor: "actions",
          maxWidth: 200,
          minWidth: 100,
          sortable: false,
          Cell: row => (
            <ButtonGroup size="sm" className="d-table mx-auto">
              <Button theme="white" tag={Link} to={`/shop/book/${key}/customer/order/${row.original.idOrder}`}>
                <i className="material-icons">visibility</i>
              </Button>
            </ButtonGroup>
          )
        }
      ];
  
      return (
        <Container fluid className="main-content-container bg-dark">
            <Row className="page-header justify-content-center py-5">
                <Col xl="7" md="11">
                    <Steps step={2} bookKey={key} />
                    <Card>
                        <CardHeader className="border-bottom bg-light">
                            {translate('orderHistory')}
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={tableColumns}
                                data={items}
                                pageSize={pageSize}
                                resizable={false}
                                showPageSizeOptions={false}
                                previousText={translate('prev')}
                                nextText={translate('next')}
                                ofText="/"
                                rowsText={translate('lines')}
                                noDataText={translate('noData')}
                                onPageChange={this.handlePageChange}
                                onPageSizeChange={this.handlePageChange}
                                page={numPage}
                                /* pages={ceil(totalOrders / pageSize)} */
                            />
                        </CardBody>
                        <CardFooter className="border-top bg-light">
                            <Button tag={Link}  className="d-block d-sm-inline-block" to={`/shop/book/${key}/authentication`}>&larr; {translate('returnToMyAccount')}</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
      )
    }
  }
  
  const mapStateToProps = ({ shop: { cart, orders } }) => {
    return { cart, orders }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(CustomerOrders)))
  