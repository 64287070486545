import "react-quill/dist/quill.snow.css";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  Row,
  Badge
} from "shards-react";

import DropzoneArea from '../../components/app/product/DropzoneArea'
import PageTitle from "../../components/common/PageTitle"
import React from "react"
import { connect } from 'react-redux'
import { importProducts, resetImportProducts } from '../../reducers/app/products.reducer'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


class ProductImport extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            submitted: false,
            errors: false,

            validateFile: false,
            validateDataMapping: false,
            
            importedData: { data: [], errors: [], meta: [], mapping: {} },
            processingData: false
        }
    }

    handleImportedData = (importedData) => {
        this.setState({importedData:{...importedData, mapping: {}}})
    }

    handleValidateFile = () => {
        this.setState((state)=>({validateFile:!state.validateFile}))
    }

    handleValidateDataMapping = () => {
        this.setState((state)=>({validateDataMapping:!state.validateFile}))
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const { importedData : { mapping } } = this.state
        const mappingKeys = Object.keys(mapping)
        const mappingValues = Object.values(mapping)
        const mappingValuesWhitoutEmpty = mappingValues.filter(item => item)
            
        if(mappingKeys.length >= 3 && mappingValuesWhitoutEmpty.length >= 3){
            this.setState({validateDataMapping:true})
        }
    }

    handleChangeMappingField = (e) => {
        const { name, value } = e.target
        const { importedData : { mapping } } = this.state
        
        if(mapping[name] && value === ''){
            const updatedMapping = { ...mapping }
            delete updatedMapping[name]

            this.setState((state) => ( { importedData: { ...state.importedData, mapping: { ...updatedMapping } } } ) )
        } else {
            this.setState((state) => ( { importedData: { ...state.importedData, mapping: { ...state.importedData.mapping, [name]: value } } } ) )
        }
    }

    formatedData = () => {
        const { importedData : { data, mapping } } = this.state
        const mappingKeys = Object.keys(mapping)
        const mappingValues = Object.values(mapping)
        const mappingValuesWhitoutEmpty = mappingValues.filter(item => item)

        if(mappingKeys.length > 0 && mappingValuesWhitoutEmpty.length > 0){

            const formatedData = data.map((row,idx)=>{
                let formatedRow = {}
                
                for (const key in mapping) {
                    formatedRow[mapping[key]] = row[key]
                }

                return formatedRow
            })

            return formatedData
        } else {
            return []
        }
    }

    handleProductsImport = () => {
        const formatedData = this.formatedData()
        if(formatedData.length > 0){
            this.setState({processingData:true})
            this.props.dispatch(importProducts(formatedData))
        }
    }

    componentWillUnmount(){
        this.props.dispatch(resetImportProducts())
    }


    render(){
        const { processingData, validateFile, validateDataMapping, importedData : { data, meta, mapping } } = this.state
        const { resultImport, translate } = this.props 
        const mappingValues = Object.values(mapping)
            
        return (
            <Container fluid className="main-content-container px-4 pb-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle title={translate('importFile')} subtitle={translate('products')} className="text-sm-left mb-3" />
                </Row>
                <Card className="p-0">
                    <CardHeader className="p-0"></CardHeader>
                    <CardBody>
                        <div className={validateFile ? 'd-none' : ''}>
                            <p className="mb-1"><Badge pill>1</Badge> {translate('uploadFile')} (csv,txt)</p>
                            <p className="d-block text-warning mb-2"><i className="material-icons">warning</i> {translate('firstLineFile')}</p>
                            <DropzoneArea validateFile={this.handleValidateFile} importedData={this.handleImportedData} />
                        </div>
                        
                        {validateFile && <div className="bg-light p-4 border">
                                <Button theme="white" className="float-right" disabled={processingData ? true : false} onClick={this.handleValidateFile}>{translate('backToThisStep')}</Button>
                                <p className="mb-1"><Badge pill className="text-white">1</Badge> {translate('validatedFile')}</p>
                        </div>}
                        
                        {!validateDataMapping && validateFile && <div className="mt-4">
                            <div className="mt-4 p-4 border">
                                <p className="mb-1"><Badge pill>2</Badge> {translate('fieldMapping')}</p>
                                <small>{translate('pleaseSelectProduct')}</small>
                                <Row className="mt-3 p-3">
                                    <Col>
                                        {translate('fileHeader')}
                                    </Col>
                                    <Col>
                                        {translate('fileData')}
                                    </Col>
                                    <Col>
                                        {translate('importAs')}
                                    </Col>
                                </Row>
                                <form className="text-center" onSubmit={this.handleSubmit}>
                                    {meta.fields.map((field,idx) => {
                                        return (
                                            <Row className="px-3 mb-2" key={idx}>
                                                <Col><FormInput value={field} readOnly /></Col>
                                                <Col><FormInput value={data[0][field]} readOnly /></Col>
                                                <Col>
                                                    <FormSelect value={mapping[field]} name={field} onChange={this.handleChangeMappingField}>
                                                        <option value="">{translate('doNotImport')}</option>
                                                        {!mappingValues.includes('name') || mapping[field] === 'name' ? <option value="name">{translate('productName')} (* {translate('required')})</option> : ''}
                                                        {!mappingValues.includes('ref') || mapping[field] === 'ref' ? <option value="ref">{translate('productRef')} (* {translate('required')})</option> : ''}
                                                        {!mappingValues.includes('price') || mapping[field] === 'price' ? <option value="price">{translate('productPrice')} (* {translate('required')})</option> : ''}
                                                        {!mappingValues.includes('desc') || mapping[field] === 'desc' ? <option value="desc">{translate('productDesc')}</option> : ''}
                                                    </FormSelect>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                    <Button disabled={ mappingValues.includes('ref') && mappingValues.includes('name') && mappingValues.includes('price') ? false : true } className="mt-3" theme="primary" type="submit" >{translate('validateData')}</Button>
                                </form>
                            </div>
                        </div>}
                        {validateDataMapping && <div className="bg-light p-4 border mt-3">
                                <Button theme="white" className="float-right" disabled={processingData ? true : false} onClick={this.handleValidateDataMapping}>{translate('backToThisStep')}</Button>
                                <p className="mb-1"><Badge pill className="text-white">2</Badge> {translate('validatedData')}</p>
                        </div>}
                        {validateFile && validateDataMapping && <div className="bg-light p-4 border mt-3">
                                <Button theme="primary" className="float-right" disabled={processingData ? true : false} onClick={this.handleProductsImport}>{processingData && (resultImport && !resultImport.importSuccess && !resultImport.importErrors) ? translate('inProgress') : translate('import')}</Button>
                                <p className="mb-1"><Badge pill className="text-white">3</Badge> {translate('importProducts')}</p>
                        </div>}
                        {resultImport && resultImport.importSuccess && <div className="bg-success text-white p-4 mt-3 mb-2">
                            <p className="mb-1">{translate('succesfullyImportedData')} {resultImport.importSuccess.length}</p>
                        </div>}
                        {resultImport && resultImport.importErrors && <div className="bg-warning text-white p-4">
                            <p className="mb-1">{translate('dataWithErrors')} {resultImport.importErrors.length}</p>
                                {resultImport.importErrors.length > 0 && <p className="mb-2">{resultImport.importErrors.map((value, idx) => { return (idx < 100) ? <span className="d-block">{`Ligne n°${value.line} - ${value.error}`}</span> : '...' })}</p>}
                        </div>}
                    </CardBody>
                </Card>
            </Container>
        )
    }
}



function mapStateToProps({ products : { resultImport } } ) {
    return { resultImport }
}

export default hot(connect(mapStateToProps)(withTranslate(ProductImport)))
