import { Modal, ModalBody, ModalHeader, Button } from "shards-react"

import { Link } from "react-router-dom"
import React from "react"
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


class NoCredit extends React.Component {
    render() {
        const { translate } = this.props

        return (
            <Modal size="md" className="text-center" centered={true} open={true} toggle={()=>{}}>
                <ModalHeader titleClass="text-primary">{translate('noCredit')}</ModalHeader>
                <ModalBody>
                    <p>{translate('takeAdvantage')}</p>
                    <Button tag={Link} to={`/app/offers`}>{translate('choosePackage')}</Button>
                </ModalBody>
            </Modal>
        )
    }
}

export default hot(withTranslate(NoCredit))
