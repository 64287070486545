import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Modal, ModalBody, ModalHeader, Row } from "shards-react"

import PageTitle from "../../components/common/PageTitle"
import React from "react"
import StripeCheckout from '../../components/common/StripeCheckout'
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { udapteUser } from "../../reducers/app/user.reducer"
import { withTranslate } from 'react-redux-multilingual'

class Offers extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      modalOpen: false,
      selectedOffer: null,
      offers: [
        {
            title: 'package1TitleBis',
            features:['package1Arg1','package1Arg2','package1Arg3','package1Arg4','package1Arg5','package1Arg6'],
            price: 432,
            purchaseType: 'offer-1'
        },
        {
          title: 'package2titleBis',
          features:['package2Arg1','package2Arg2','package2Arg3','package2Arg4','package2Arg5','package2Arg6'],
          price: 54,
          purchaseType: 'offer-2'
        }
      ]
    }
  }


  toggleModal = (selectedOffer) => {
    this.setState((state) => ({ modalOpen: !state.modalOpen, selectedOffer }))
  }

  updateCredits = async (paymentIntent) => {
    if(paymentIntent && paymentIntent.id){
      this.props.dispatch(udapteUser({ credits: { paymentType: 'stripe', paymentDetail: { id: paymentIntent.id } } } ) )
    }
  }
  
  render() {
    const { offers, modalOpen, selectedOffer } = this.state
    const { user, translate } = this.props

    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={translate('packages')} subtitle={translate('account')} className="text-sm-left" />
        </Row>
        <Row>
          {offers.map((offer, idx) => (
            <Col md="6" sm="12" key={idx}>
              <Card small className="card-post mb-4 text-center">
                    <CardHeader className="border-bottom">
                        <h5 className="card-title my-0 text-primary">{translate(offer.title)}</h5>
                    </CardHeader>
                    <CardBody>
                        <ul className="list-unstyled my-0">
                            {offer.features.map((feature, index) => (<li key={index}>{translate(feature)}</li>))}
                        </ul>
                        <div className="text-primary mt-4 font-size-md">{offer.price}<sup>€ TTC</sup></div>
                    </CardBody>
                    <CardFooter className="border-top">
                        <Button theme="primary" onClick={() => { this.toggleModal(offer)}}>
                          <i className="far fa-bookmark mr-1" /> {translate('subscribeToTheOffer')}
                        </Button>
                    </CardFooter>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {modalOpen && 
          <Modal size="md" centered={true} open={modalOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>{translate('package')} {translate(selectedOffer.title)}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg={12} className="text-center">
                      {selectedOffer && <StripeCheckout purchase={{ type: selectedOffer.purchaseType, price: selectedOffer.price }} client={{...user, type:'user'}} handlerAfterPaymentSuccess={this.updateCredits} />}
                    </Col>
                </Row>
            </ModalBody>
          </Modal>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps({ user }) {
  return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(Offers)))
