import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge
} from "shards-react"
import { withTranslate } from 'react-redux-multilingual'


const ProcuctSales = ({ title, items = [], translate }) => (
  <Card small className="lo-stats h-100">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <CardBody className="p-0">
      <Container fluid className="px-0">
        {items && items.length > 0 && <table className="table mb-0">  
          <thead className="py-2 bg-light text-semibold border-bottom">
              <tr>
                <th width="25%">{translate('productName')}</th>
                <th width="25%">{translate('ref')}</th>
                <th width="25%" className="text-right">{translate('quantity')}</th>
              </tr>
          </thead>
          <tbody>
              {items.map((item, idx) => (
              <tr key={idx}>
                  <td>
                    {item.productName}
                  </td>
                  <td>{item.productRef}</td>
                  <td className="text-right"><Badge pill theme="success">{item.totalProductQuantity}</Badge></td>
              </tr>
              ))}
          </tbody>
        </table>}
        {items.length === 0 && <Row className="px-3">
            <Col><p className="text-center font-size-sm text-light m-0">{translate('noData')}</p></Col>
          </Row>}
      </Container>
    </CardBody>
  </Card>
)

export default withTranslate(ProcuctSales)
