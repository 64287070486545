import { Col, Container, Row } from "shards-react";

import Footer from "../components/layout/AppFooter";
import MainNavbar from "../components/layout/navigation/MainNavbar";
import MainSidebar from "../components/layout/sidebars/MainSidebar";
import PropTypes from "prop-types";
import React from "react";

const DefaultLayout = ({ children, noNavbar, noFooter, noSidebar }) => (
  <Container fluid>
    <Row>
      {!noSidebar && <MainSidebar />}
      <Col
        className="main-content p-0"
        md={(!noSidebar) ? { size: 10, offset: 2 } : { size: 12 }}
        sm="12"
        tag="main"
      >
        {!noNavbar && <MainNavbar />}
        {children}
        {!noFooter && <Footer />}
      </Col>
    </Row>
    {process.env.NODE_ENV === 'development' &&
      <div className="media-queries">
          <div className="media-querie d-block d-sm-none">xs</div>
          <div className="media-querie d-none d-sm-block d-md-none">sm</div>
          <div className="media-querie d-none d-md-block d-lg-none">md</div>
          <div className="media-querie d-none d-lg-block d-xl-none">lg</div>
          <div className="media-querie d-none d-xl-block">xl</div>
      </div>}
  </Container>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
  /**
   * Whether to display the sidebar, or not.
   */
  noSidebar: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: true,
  noSidebar: false
};

export default DefaultLayout;
