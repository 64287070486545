import { Col, Container, Row } from "shards-react"
import React from "react"
import ShopNavBar from "../components/layout/navigation/ShopNavBar"
import ShopFooter from "../components/layout/ShopFooter"
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { getBook } from '../reducers/shop/book.reducer'
import { getCustomerCart } from '../reducers/shop/cart.reducer'



class ShopLayout extends React.Component {

  componentDidMount() {
    const { key } = this.props.match.params

    if(key) {
      this.props.dispatch(getBook(key))
    }
  }

  componentDidUpdate(prevProps){
    const { book: prevBook } = prevProps
    const { book } = this.props

    // If new book loaded
    if(prevBook.idBook !== book.idBook && book.idBook !== 0){
      this.props.dispatch(getCustomerCart(book.idBook))
    }
  }

  render(){
    const { children, match } = this.props

    return (
      <Container fluid>
        <Row>
            <Col
                className="main-content p-0"
                tag="main"
            >
            <ShopNavBar match={match} />
            {children}
            <ShopFooter match={match} />
            </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = ({ shop : { book, cart } }) => {
  return { book, cart }
}

export default hot(connect(mapStateToProps)(ShopLayout))