import { func, object } from 'prop-types'

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'

class Loader extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    userInterface: object.isRequired
  }

  render() {
    const { waiting } = this.props.userInterface
    const { className } = this.props

    return (
        <div className={className}>
            {waiting && <div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div>}
        </div>
    )
  }
}

function mapStateToProps( { userInterface }) {
  return { userInterface }
}

export default hot(connect(mapStateToProps)(Loader))

