import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { IntlReducer as Intl } from 'react-redux-multilingual'


import { alert } from './alert.reducer'
import { core } from './core.reducer'

import { books } from './app/books.reducer'
import { customers } from './app/customers.reducer'
import { images } from './app/images.reducer'
import { orders } from './app/orders.reducer'
import { products } from './app/products.reducer'
import { user } from './app/user.reducer'
import { userInterface } from './app/userInterface.reducer'
import { payment } from './payment.reducer'

import { book as bookShop } from './shop/book.reducer'
import { cart as cartShop } from './shop/cart.reducer'
import { customer } from './shop/customer.reducer'
import { customerInterface } from './shop/customerInterface.reducer'
import { orders as ordersShop } from './shop/orders.reducer'
import { products as productsShop } from './shop/products.reducer'

const appReducer = combineReducers({
  Intl,
  core,
  alert,
  payment,
  user,
  userInterface,
  products,
  books,
  images,
  orders,
  customers,
  shop: combineReducers({ customer, customerInterface, book : bookShop, cart: cartShop, orders: ordersShop, products: productsShop })
})

const rootReducer = (state, action) => {
  if(action.type === 'RESET_STORE' || action.type === 'CHECK_VERSION_CORE_ERROR'){
    storage.removeItem('persist:root')
    storage.removeItem('customer')
    storage.removeItem('user')
    state = undefined
  }
  
  if (action.type === 'LOGIN_FAILURE' || action.type === 'TOKEN_FAILURE' || action.type === 'LOGOUT_SUCCESS') {
    //state.core = undefined
    state.user = undefined
    state.products = undefined
    state.books = undefined
    state.userInterface = undefined
    state.images = undefined
    state.orders = undefined
    storage.removeItem('user')
  }

  if (action.type === 'CUSTOMER_TOKEN_ERROR' || action.type === 'CUSTOMER_LOGOUT_SUCCESS') {
    state.shop.customer = undefined
    state.shop.customerInterface = undefined
    state.shop.cart = undefined
    state.shop.orders = undefined
    state.shop.products = undefined
    storage.removeItem('customer')
  }

  if(action.type === 'NEW_BOOK_LOADED'){
    state.shop.customer = undefined
    state.shop.customerInterface = undefined
    state.shop.cart = undefined
    state.shop.orders = undefined
    state.shop.products = undefined
    state.shop.book = undefined
  }

  return appReducer(state, action)
}

export default rootReducer