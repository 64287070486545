/* eslint jsx-a11y/anchor-is-valid: 0 */

import {
    Button,
    Col,
    Form,
    FormGroup,
    FormInput,
    Row,
    FormTextarea
  } from "shards-react"
  
  import React from "react"
  import { connect } from 'react-redux'
  import emailValidator from 'email-validator'
  import { hot } from 'react-hot-loader/root'
  import { sendEmail } from '../../reducers/app/userInterface.reducer'
  import { withTranslate } from 'react-redux-multilingual'

  
  
  class ContactForm extends React.Component {
  
    constructor(props){
      super(props)
  
      this.state = {
        lastname: '',
        firstname: '',
        email: '',
        object: '',
        message: '',
        submitted: false
      }
    }
  
    // Autobind with arrow func
    handleChange = (e) => {
      const { name, value } = e.target
      this.setState({ [name]: value })
    }
  
    // Autobind with arrow func
    handleSubmit = (e) => {
      e.preventDefault()
  
      this.setState({ submitted: true })
      const { lastname, firstname, email, object, message } = this.state
  
      if (lastname && firstname && email && object && message && emailValidator.validate(email)) {
          this.props.dispatch(sendEmail(lastname, firstname, email, object, message))
      }
    }  
  
    render(){
      const { translate } = this.props
      const { waiting } = this.props.userInterface
      const { lastname, firstname, email, object, message, submitted } = this.state
  
      return (<Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>
            <FormGroup>
                    <label htmlFor="lastname">{translate('name')}</label>
                    <FormInput
                      type="text"
                      id="lastname"
                      placeholder={translate('enterYourName')}
                      name="lastname"
                      onChange={this.handleChange}
                    />
                    {submitted && !lastname &&
                      <small className="text-danger">{translate('name')} {translate('required')}</small>
                    }
                  </FormGroup>
            </Col>
            <Col>
            <FormGroup>
                    <label htmlFor="firstname">{translate('surname')}</label>
                    <FormInput
                      type="text"
                      id="firstname"
                      name="firstname"
                      placeholder={translate('enterYourSurname')}
                      onChange={this.handleChange}
                    />
                    {submitted && !firstname &&
                      <small className="text-danger">{translate('surname')} {translate('required')}</small>
                    }
                  </FormGroup>
            </Col>
          </Row>
                  
                  
                  <FormGroup>
                    <label htmlFor="email">{translate('email')}</label>
                    <FormInput
                      type="email"
                      id="email"
                      name="email"
                      placeholder={translate('enterYourEmail')}
                      onChange={this.handleChange}
                    />
                    {submitted && (!email || !emailValidator.validate(email)) &&
                      <small className="text-danger">{translate('email')} {translate('required')}</small>
                    }
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password">{translate('object')}</label>
                    <FormInput
                      type="text"
                      id="object"
                      name="object"
                      placeholder={translate('subjectMessage')}
                      onChange={this.handleChange}
                    />
                    {submitted && !object &&
                      <small className="text-danger">{translate('object')} {translate('required')}</small>
                    }
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password">{translate('message')}</label>
                    <FormTextarea
                      id="message"
                      name="message"
                      placeholder={translate('enterMessage')}
                      onChange={this.handleChange}
                      style={{height:'150px'}}
                    />
                    {submitted && !message &&
                      <small className="text-danger">{translate('message')} {translate('required')}</small>
                    }
                  </FormGroup>
                  {waiting &&
                    <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
                  }
                  <Button
                    className="d-table mx-auto btn-primary"
                    type="submit"
                  >
                    {translate('sendRequest')}
                  </Button>
                </Form>)
    }
  }
  
  function mapStateToProps( { userInterface } ) {
    return { userInterface }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(ContactForm)))
  