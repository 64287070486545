import {
    Button,
    Form,
    FormGroup,
    FormInput
} from "shards-react";
import { func, object } from 'prop-types'
import React from "react";
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


class ResetPasswordForm extends React.Component {
    static propTypes = {
        dispatch: func.isRequired,
        userInterface: object.isRequired
    }

    constructor(props){
        super(props)

        this.state = {
            password: '',
            submitted: false
        }
    }

    // Autobind with arrow func
    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    // Autobind with arrow func
    handleSubmit = (e) => {
        e.preventDefault()

        this.setState({ submitted: true })
        const { password } = this.state
        const resetPassword = this.props.handler
        const token = this.props.token


        if (password && password.length > 7 && token && token.length > 1) {
            this.props.dispatch(resetPassword(token, password))
        }
    }

    render(){
        const { userInterface: { waiting }, translate } = this.props
        const { password, submitted } = this.state

        return(
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    <label htmlFor="password">{translate('password')}</label>
                    <FormInput
                        type="password"
                        id="password"
                        name="password"
                        placeholder={translate('enterPassword')}
                        onChange={this.handleChange}
                    />
                    {submitted && (!password || password.length < 8) &&
                        <small className="text-danger">{translate('passwordGreaterThan')}</small>
                    }
                    <small className="form-text text-muted text-center">
                    {translate('newPassword')}
                    </small>
                </FormGroup>
                {waiting &&
                    <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
                }
                <Button
                    theme="primary"
                    className="d-table mx-auto"
                    type="submit"
                >
                    {translate('update')}
                </Button>
            </Form>)
    }
}

function mapStateToProps( { userInterface } ) {
    return { userInterface }
}

export default hot(connect(mapStateToProps)(withTranslate(ResetPasswordForm)))