import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from "shards-react"

import { Link } from "react-router-dom"
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import  Steps from '../../components/shop/order/Steps'
import { createDeliveryAddress, createInvoiceAddress } from '../../reducers/shop/customer.reducer'
import AdressForm from "../../components/forms/AdressForm"
import { withTranslate } from 'react-redux-multilingual'


class Address extends React.Component{

    constructor(props){
        super(props)
    
        this.state = {
            completedInvoiceAddressForm: false,
            completedDeliveryAddressForm: false,
        }
    }

    completedDeliveryAddressForm = (status) => {
        this.setState({ completedDeliveryAddressForm: status })
    }

    completedInvoiceAddressForm = (status) => {
        this.setState({ completedInvoiceAddressForm: status })
    }

    /* componentDidMount() {
        const { cart, match: { params : { key } } } = this.props

        if(!cart.hasOwnProperty('products') || (cart.hasOwnProperty('products') && cart.products.length === 0)){
            history.push(`/shop/book/${key}/cart`)
        }
    } */

    render(){
        const { completedInvoiceAddressForm, completedDeliveryAddressForm } = this.state
        const { cart, customer, match: { params : { key } }, translate } = this.props
        let itemsIsEmpty = (cart.products && cart.products.length > 0) ? false : true

        
        let deliveryAddress = {
            lastname: '',
            firstname: '',
            phone: '',
            address: '',
            postcode: '',
            city: ''
        }
        
        let invoiceAddress = {
            lastname: '',
            firstname: '',
            phone: '',
            address: '',
            postcode: '',
            city: ''
        }

        if(customer.addresses && customer.addresses.length > 0){
            customer.addresses.forEach(address => {
                if(address.name === 'invoice'){
                    invoiceAddress = { ...address }
                } else if(address.name === 'delivery'){
                    deliveryAddress = { ...address }
                }
            })
        }

        return (
            <Container fluid className="main-content-container bg-dark">
                <Row className="page-header justify-content-center py-5">
                    <Col xl="7" md="11">
                        <Steps step={3} bookKey={key} />
                        <Card>
                            <CardHeader className="border-bottom bg-light">03. {translate('address')}</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6" className="border-right mb-3">
                                        <Row className="justify-content-center">
                                            <Col md="11">
                                                <h5 className="mb-4">{translate('shippingAddress')}</h5>
                                                <AdressForm key="1" handler={createDeliveryAddress} data={deliveryAddress} completedForm={this.completedDeliveryAddressForm} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="6" offset="1">
                                        <Row className="justify-content-center">
                                            <Col md="11">
                                                <h5 className="mb-4">{translate('billingAddress')}</h5>
                                                <AdressForm key="2" handler={createInvoiceAddress} data={invoiceAddress} completedForm={this.completedInvoiceAddressForm} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="border-top bg-light">
                                <Button tag={Link} className="d-block d-sm-inline-block mb-2 mb-sm-0" to={`/shop/book/${key}/cart`}>&larr; {translate('returnToCart')}</Button>
                                { (!itemsIsEmpty && completedInvoiceAddressForm && completedDeliveryAddressForm) && <Button theme='primary'  className='float-sm-right d-block d-sm-inline-block text-white' tag={Link} to={`/shop/book/${key}/shipping`}>{translate('checkout')} &rarr;</Button>}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({ shop : { cart, customer } } ) => {
    return { cart, customer }
}

export default hot(connect(mapStateToProps)(withTranslate(Address)))