import {
    Nav,
    NavItem,
    NavLink,
    Navbar,
    DropdownItem,
    NavbarToggler,
    Collapse
} from "shards-react"

import React from "react"
import { NavLink as RouteNavLink } from "react-router-dom"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import Help from '../../common/Help'
import { logout } from '../../../reducers/shop/customer.reducer'
import { withTranslate, IntlActions } from 'react-redux-multilingual'
import flagFR from '../../../images/fr.svg'
import flagEN from '../../../images/en.svg'

class ShopNavBar extends React.Component {

    constructor(props){
      super(props)

      this.state = {
        collapseOpen: false
      } 
    }

    handleSignOut = (e) => {
        e.preventDefault()
        this.props.dispatch(logout())
    }

    toggleNavbar = () => {
      this.setState((state) => ({ collapseOpen: !state.collapseOpen }))
    }
    
    render() {
      const { collapseOpen } = this.state
      const { cart, customer, match : { params : { key } }, translate } = this.props
      const sumProducts = (cart && cart.products) ? Object.values(cart.products).reduce((acc, cur) => { acc = acc + cur['quantity']; return acc }, 0) : 0

      return (
        <Navbar type="dark" className="web-navbar py-1 px-2" expand="md" sticky="true">
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse open={collapseOpen} navbar>
            <Nav navbar>
              {/* <NavItem>
                <NavLink href="#" className="py-0 px-2" onClick={() => { this.props.dispatch(IntlActions.setLocale('fr'))}} >
                  <img width="30px" src={flagFR} alt=""/>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" className="py-0 px-2" onClick={() => { this.props.dispatch(IntlActions.setLocale('en'))}}>
                  <img width="30px" src={flagEN} alt=""/>
                </NavLink>
              </NavItem> */}
              <Help className="help cursor-pointer d-none d-sm-block">
                <DropdownItem className="text-center"><i className="material-icons text-primary">help</i> {translate('help')}</DropdownItem>
                <DropdownItem>
                    <div className="notification__icon-wrapper">
                    <div className="notification__icon">
                        <i className="material-icons">menu_book</i>
                    </div>
                    </div>
                    <div className="notification__content">
                    <span className="notification__category text-primary">{translate('moveInsidePdf')}</span>
                    <ul className="pl-3">
                      <li>{translate('useArrowAtTheBottom')}</li>
                      <li>{translate('goToApage')} <i className="material-icons text-dark">pan_tool</i> {translate('clickHoldDrag')}</li>
                    </ul>
                    </div>
                </DropdownItem>
                <DropdownItem>
                    <div className="notification__icon-wrapper">
                    <div className="notification__icon">
                        <i className="material-icons">add_shopping_cart</i>
                    </div>
                    </div>
                    <div className="notification__content">
                    <span className="notification__category text-primary">{translate('addProductToCart')}</span>
                    <ul className="pl-3">
                      <li>{translate('moveMouseOverClickable')}</li>
                      <li>{translate('click')}</li>
                      <li>{translate('modifyQuantity')}</li>
                      <li>{translate('clickAddToCart')}</li>
                    </ul>
                    </div>
                </DropdownItem>
                <DropdownItem>
                    <div className="notification__icon-wrapper">
                    <div className="notification__icon">
                        <i className="material-icons">shopping_cart</i>
                    </div>
                    </div>
                    <div className="notification__content">
                    <span className="notification__category text-primary">{translate('seeCartDetail')}</span>
                    <ul className="pl-3">
                      <li>{translate('clickToCartAtTheTop')}</li>
                    </ul>
                    </div>
                </DropdownItem>
                <DropdownItem>
                    <div className="notification__icon-wrapper">
                    <div className="notification__icon">
                        <i className="material-icons">payment</i>
                    </div>
                    </div>
                    <div className="notification__content">
                    <span className="notification__category text-primary">{translate('placeAnOrder')}</span>
                    <ul className="pl-3">
                      <li>{translate('clickToCartAtTheTop')}</li>
                      <li>{translate('clickToOrder')}</li>
                      <li>{translate('fillUpTheForm')}</li>
                    </ul>
                    </div>
                </DropdownItem>
              </Help>
            </Nav>
            <Nav navbar className="ml-auto">
                {!customer.logged && <NavItem>
                  <NavLink tag={RouteNavLink} to={`/shop/book/${key}/authentication`}>
                    <i className="material-icons">account_box</i> {translate('login')}
                  </NavLink>
                </NavItem>}
                {customer.logged && <NavItem>
                  <NavLink tag={RouteNavLink} to={`/shop/book/${key}/authentication`}>
                      <i className="material-icons">account_box</i> {translate('myAccount')}
                    </NavLink>
                </NavItem>}
                {customer.logged && <NavItem>
                  <NavLink tag="a" onClick={this.handleSignOut} className="cursor-pointer">
                    <i className="material-icons">exit_to_app</i> {translate('logout')}
                  </NavLink>
                </NavItem>}
              <NavItem>
                <NavLink tag={RouteNavLink} to={`/shop/book/${key}/cart`}>
                  <i className="material-icons">shopping_cart</i> {translate('cart')} <span className="badge badge-light">{sumProducts}</span> 
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      )
    }
  }

  function mapStateToProps( { shop : { book, cart, customer } } ) {
    return { book, cart, customer }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(ShopNavBar)))