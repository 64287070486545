import getSidebarNavItems from "../../data/sidebar-nav-items"
import axios, { setLocaleHeader } from '../../utils/axios'
import { setAlert } from '../alert.reducer'


// Types d’actions
// ---------------
const constants = {
    WAITING: 'WAITING',
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    TOGGLE_SIDEBAR_DROPDOWN: "TOGGLE_SIDEBAR_DROPDOWN",
    SHOP_PRODUCT_DETAIL_MODAL: "SHOP_PRODUCT_DETAIL_MODAL",
    UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
    UPLOAD_RESET: 'UPLOAD_RESET',
    CONVERT_PROGRESS: 'CONVERT_PROGRESS',
    CONVERT_SUCCESS: 'CONVERT_SUCCESS',
    CONVERT_RESET: 'CONVERT_RESET',
    UPLOAD_ERROR: 'UPLOAD_ERROR',
    CONVERT_ERROR: 'CONVERT_ERROR',
    TOGGLE_HELP: 'TOGGLE_HELP',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_ERROR: 'SEND_EMAIL_ERROR'
}

// Réducteur
// ---------
const defaultState = {
    menuVisible: false,
    navItems: getSidebarNavItems,
    uploading: false,
    uploadProgress: 0,
    successfullUploaded: false,
    uploadError: false,
    converting: false,
    convertProgress: 0,
    successfullConverted: false,
    convertError: false,
    helpVisible: false,
}

export function userInterface(state = defaultState, action) {
    switch (action.type) {
        case constants.TOGGLE_SIDEBAR:
            const { menuVisible } = state
            return {
                ...state,
                menuVisible: !menuVisible
            }
        case constants.TOGGLE_SIDEBAR_DROPDOWN:
            const { navItems } = state
            const newNavItems = sidebarDropdown(navItems, action.payload);
            return {
                ...state,
                navItems : { items : newNavItems }
            }
        case 'WAITING':
            const { status } = action
            return {
                ...state,
                waiting: status
            }
        case 'UPLOAD_PROGRESS':{
            const { uploadProgress } = action.payload
            return {
                ...state,
                uploadProgress
            }
        }
        case 'UPLOAD_SUCCESS':{
            return {
                ...state,
                successfullUploaded: true
            }
        }
        case 'UPLOAD_RESET':{
            return {
                ...state,
                successfullUploaded: false,
                uploading: false,
                uploadProgress: 0,
                uploadError: false
            }
        }
        case 'UPLOAD_ERROR':{
            return {
                ...state,
                uploadError: true
            }
        }
        case 'CONVERT_PROGRESS':{
            const { convertProgress } = action.payload
            return {
                ...state,
                convertProgress
            }
        }
        case 'CONVERT_SUCCESS':{
            return {
                ...state,
                successfullConverted: true
            }
        }
        case 'CONVERT_RESET':{
            return {
                ...state,
                successfullConverted: false,
                converting: false,
                convertProgress: 0,
                convertError: false
            }
        }
        case 'CONVERT_ERROR':{
            return {
                ...state,
                convertError: true
            }
        }
        case 'TOGGLE_HELP':{
            return {
                ...state,
                helpVisible: !state.helpVisible
            }
        }
        default:
            return state
    }
}


// Action Creators
// ---------------
export function toggleSidebar() {
    return (dispatch) => {
        dispatch({ type: constants.TOGGLE_SIDEBAR })
    }
}

export function toggleSidebarDropdown(item) {
    return (dispatch) => {
        dispatch({ type: constants.TOGGLE_SIDEBAR_DROPDOWN, payload: item })
    }
}

export function toggleHelp(){
    return (dispatch) => {
        dispatch({ type : constants.TOGGLE_HELP })
    }
}

export function waiting(status){
    return (dispatch) => {
        dispatch({ type: constants.WAITING, status })
    }
}

export function uploadProgress(percentCompleted){
    return (dispatch) => {
        dispatch({ type: constants.UPLOAD_PROGRESS, payload: { uploadProgress : percentCompleted } })
    }
}

export function uploadSucess(){
    return (dispatch) => {
        dispatch({ type: constants.UPLOAD_SUCCESS })
    }
}

export function uploadReset(){
    return (dispatch) => {
        dispatch({ type: constants.UPLOAD_RESET })
    }
}

export function uploadError(){
    return (dispatch) => {
        dispatch({ type: constants.UPLOAD_ERROR })
    }
}

export function convertProgress(percentCompleted){
    return (dispatch) => {
        dispatch({ type: constants.CONVERT_PROGRESS, payload: { convertProgress : percentCompleted } })
    }
}

export function convertSuccess(){
    return (dispatch) => {
        dispatch({ type: constants.CONVERT_SUCCESS })
    }
}

export function convertReset(){
    return (dispatch) => {
        dispatch({ type: constants.CONVERT_RESET })
    }
}

export function convertError(){
    return (dispatch) => {
        dispatch({ type: constants.CONVERT_ERROR })
    }
}

export function sendEmail(lastname, firstname, email, object, message){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()

        try{
            dispatch(waiting(true))

            setLocaleHeader(locale)

            const response = await axios.post('/core/send/email', {lastname, firstname, email, object, message})
            const data = response.data
    
            dispatch({ type: constants.SEND_EMAIL_SUCCESS })
            dispatch(setAlert('SUCCESS', data.message))
            dispatch(waiting(false))
        } catch(error){
            const { data } = error.response
            dispatch(setAlert('DANGER', data.message))
            dispatch(waiting(false))
        }

    }
}

// Private functions
// -----------------
function sidebarDropdown(navItems, item){

    let navItemIdx = null;
    const parentNavItem = Object.values(navItems.items).indexOf(item)


    navItems.items.forEach((navItem, _idx) => {

        if(parentNavItem !== -1 && parentNavItem === _idx){
            navItems.items[_idx].open = !navItems.items[_idx].open
        } else if(parentNavItem !== -1 && parentNavItem !== _idx) {
            navItems.items[_idx].open = false
        } else if('items' in navItem) {
            const __idx = navItem.items.indexOf(item)
            if(__idx !== -1){
                navItem[_idx].items[__idx].open = !navItems.items[_idx].open
                navItemIdx = __idx
            }
        }
    })

    navItems = navItems.items.map(i => {
        if('items' in i){
            i.items = i.items.map((_i, idx) => {
                if (idx !== navItemIdx) {
                    _i.open = false
                }
                return _i
            });
        }
        return i
    });
    
    return navItems
}