import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row, FormRadio } from "shards-react"

import { Link } from "react-router-dom"
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import  Steps from '../../components/shop/order/Steps'
import { withTranslate } from 'react-redux-multilingual'
import { updateCustomerShippingChoice } from '../../reducers/shop/cart.reducer'
import { round } from 'mathjs'


class Shipping extends React.Component{

    handleShipping(idShippingCosts, idCollectionShops = null){
        const { match: { params : { key } } } = this.props
        this.props.dispatch(updateCustomerShippingChoice(idShippingCosts, idCollectionShops))
    }

    render(){
        const { cart, book, match: { params : { key } }, translate } = this.props
        const idShippingCosts = book.idShippingCosts
        const collectionShopsIsEmpty = (book?.owner && book.owner?.collectionShops && typeof book.owner.collectionShops === 'object' && book.owner.collectionShops?.addresses && Array.isArray(book.owner.collectionShops.addresses) && book.owner.collectionShops.addresses.length > 0) ? false : true
        const shippingCostsRangesIsEmpty = (book?.owner && book.owner?.shippingCosts && typeof book.owner.shippingCosts === 'object' && book.owner.shippingCosts?.ranges && Array.isArray(book.owner.shippingCosts.ranges) && book.owner.shippingCosts.ranges.length > 0) ? false : true
        return (
            <Container fluid className="main-content-container bg-dark">
                <Row className="page-header justify-content-center py-5">
                    <Col xl="7" md="11">
                        <Steps step={4} bookKey={key} />
                        <Card>
                            <CardHeader className="border-bottom bg-light">04. {translate('shipping')}</CardHeader>
                            <CardBody>
                                <Row className="justify-content-center">
                                    <Col md="12">
                                        <div className="mb-3">{translate('availableShipping')}{translate('space')}:</div>
                                        {((idShippingCosts === 0 || idShippingCosts === 3) && collectionShopsIsEmpty) && 
                                            <Button theme='primary' outline block className='text-left d-sm-inline-block py-3 font-size-sm mb-2' onClick={()=>{this.handleShipping(0)}}>
                                               <span>{translate('collectedOnSite')}</span>
                                               <span className="float-right" >0€ <sup>{translate('inclTax')}</sup></span> 
                                            </Button>
                                        }
                                        {((idShippingCosts === 0 || idShippingCosts === 3) && !collectionShopsIsEmpty) && book.owner.collectionShops.addresses.map((address, idCollectionShops)=>{
                                            return ( <Button key={idCollectionShops} theme='primary' outline block className='text-left d-sm-inline-block py-3 font-size-sm mb-2' onClick={()=>{this.handleShipping(0, idCollectionShops)}}>
                                                <span>{translate('collectedOnSite')} : {address.address} {address.postalCode} {address.city} {address.phone}</span>
                                                <span className="float-right" >0€ <sup>{translate('inclTax')}</sup></span> 
                                            </Button>)
                                            })
                                        }
                                        {idShippingCosts === 1 &&
                                            <Button theme='primary' outline block className='text-left d-sm-inline-block py-3 font-size-sm mb-2' onClick={()=>{this.handleShipping(1)}}>
                                                <span>{translate('free')}</span>
                                                <span className="float-right" >0€ <sup>{translate('inclTax')}</sup></span> 
                                            </Button>
                                        }
                                        {((idShippingCosts === 2 || idShippingCosts === 3) && !shippingCostsRangesIsEmpty) &&
                                            <Button theme='primary' outline block className='text-left d-sm-inline-block py-3 font-size-sm mb-2' onClick={()=>{this.handleShipping(2)}}>
                                                <span>{translate('postalPackage')} <small><i>({translate('toShippingAdressDefinedInPreviousStep').toLowerCase()})</i></small></span>
                                                {book.owner.shippingCosts.ranges.map((range, index) => {
                                                    if(cart.totalPriceProductsInclTax >= range.priceSupOrEqualTo && cart.totalPriceProductsInclTax < range.priceLowerThan){
                                                        return <span className="float-right" key={index}> {round(range.shippingCost * ( 1 + ( book.owner.shippingCosts.vat / 100 ) ), 2)}€ <sup>{translate('inclTax')}</sup></span>
                                                    }
                                                })}
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="border-top bg-light">
                               <Button tag={Link} className="d-block d-sm-inline-block mb-2 mb-sm-0" to={`/shop/book/${key}/cart`}>&larr; {translate('returnToCart')}</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({ shop : { cart, customer, book } } ) => {
    return { cart, customer, book }
}

export default hot(connect(mapStateToProps)(withTranslate(Shipping)))