import { Col, Row, Button } from "shards-react"

import Dropzone from "react-dropzone"
import React from "react"
import classNames from "classnames"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { setAlert } from '../../../reducers/alert.reducer'
import Papa from 'papaparse'
import { withTranslate } from 'react-redux-multilingual'


const maxFileSize = 0.1 * 1000000 // 1Mo

Papa.parsePromise = function(file) {
    return new Promise(function(complete, error) {
      Papa.parse(file, {complete, error, header : true, skipEmptyLines : true }) 
    })
}

class DropzoneArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: '',
            loading: false,
            successfullUploaded: false,
            importedData: { data: [], errors: [], meta: []}
        }

        this.initialState = { ...this.state }
    }

    toggleModal = () => {
        this.setState((state) => ({modalOpen: !state.modalOpen}))
    }

    handleDrop = async(acceptedFiles, rejectedFiles, event) => { 
        const { translate } = this.props

        if(rejectedFiles.length > 0){
            if(rejectedFiles.length > 1){
                this.props.dispatch(setAlert('DANGER', translate('oneFilePerUpload')))
            } else if(rejectedFiles[0].size > maxFileSize){
                this.props.dispatch(setAlert('DANGER', `${translate('fileIsTooLarge')} (max. ${maxFileSize / 1000} Ko) !`, 4000))
            } else if(rejectedFiles[0].type !== 'text/csv' || rejectedFiles[0].type !== 'text/plain'){
                this.props.dispatch(setAlert('DANGER', translate('fileMustBeCsv')))
            }
        } else if(acceptedFiles.length > 0){

            this.setState({ file:acceptedFiles[0], loading: true })
            
            try{
                const results = await Papa.parsePromise(acceptedFiles[0])
                
                if(results.errors.length > 0){
                    const error = results.errors[0]

                    if(error.type === 'Delimiter'){
                        this.props.dispatch(setAlert('DANGER', translate('separatorNotFound')))
                    } else if(error.type === 'Quotes'){
                        this.props.dispatch(setAlert('DANGER', translate('errorInFile')))
                    } else {
                        // error.type === 'FieldMismatch'
                        this.props.dispatch(setAlert('DANGER', translate('formatError')))
                    }

                    this.setState({file:'', loading: false})
                } else {
                    // check if header has no empty column
                    results.meta.fields = results.meta.fields.filter(item => item)
                    // Remove duplicate headers
                    results.meta.fields = [...new Set(results.meta.fields)]

                    this.setState({importedData: results, loading: false, successfullUploaded: true})
                    this.props.importedData(results)
                }
            } catch(err){
                this.props.dispatch(setAlert('DANGER', err))
            }
        }
    }

    handleRemoveFile = () => { this.setState(this.initialState) }

    render() {

        const { validateFile, translate } = this.props

        return (
            <Row noGutters>
                <div className="file-manager-cards__dropzone w-100 p-2">
                    {!this.state.file && <Dropzone disabled={false} accept=".csv,text/csv,.txt,text/plain" maxSize={maxFileSize} multiple={false} onDrop={this.handleDrop}>
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                        <div
                            {...getRootProps()}
                            className={classNames("dropzone", {
                            "dropzone--isActive": isDragActive
                            })}
                        >
                            <input {...getInputProps()} />
                            <p className="m-0 py-5">
                            {isDragActive
                                ? translate('dropYourFileHere')
                                : translate('updoadFileOrClick')}
                            </p>
                        </div>
                        );
                    }}
                    </Dropzone>}
                    {this.state.loading &&
                        <div className="bg-light p-4 border">
                            <h6 className="mb-2">{translate('inProgress')}</h6>
                            <div className="spinner-border text-primary mt-3" role="status"><span className="sr-only">Loading...</span></div>
                        </div>
                    }
                    {this.state.file && this.state.importedData.data.length > 0 && <div className="file">
                        <Row>
                            <Col xl={12}>
                                <div className="bg-light p-4 border">
                                    <Button theme="white" className="float-right" onClick={this.handleRemoveFile}>{translate('removeFile')}</Button>
                                    <p className="m-0 font-weight-bold">{translate('fileLoaded')}</p>
                                    <small className="m-0">{this.state.file.name}</small>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div className="p-4 border mt-4">
                                    <div className="font-weight-bold">{translate('filePreview')}</div>
                                    <small className="d-block mb-3">{translate('DownloadedFile')} <b>{this.state.file.name}</b> {translate('with')} <b>{this.state.importedData.data.length}</b> {translate('linesAnd')} <b>{this.state.importedData.meta.fields.length}</b> {translate('columns')}</small>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead className="bg-light">
                                                <tr>
                                                {this.state.importedData.meta.fields.map((field,idx) => 
                                                    (
                                                        <th key={idx}>{field}</th>
                                                    )
                                                )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.importedData.data.map((value,idx) => {
                                                    if(idx < 5){
                                                        return <tr key={idx}>{this.state.importedData.meta.fields.map((field,idx2) => (<td key={idx2}>{value[field]}</td>))}</tr>
                                                    }

                                                    return null
                                                })}
                                                <tr>{this.state.importedData.meta.fields.map((field,idx)=>(<td key={idx}>...</td>))}</tr>
                                            </tbody>
                                            <tfoot>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className="text-center">
                                        <Button className="mt-4" onClick={validateFile}>{translate('validateFile')}</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>}
                </div>
            </Row>
        )
    }
}

function mapStateToProps(){
    return {}
}

export default hot(connect(mapStateToProps)(withTranslate(DropzoneArea)))
