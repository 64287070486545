import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'
import { setAlert } from '../alert.reducer'
import { history } from '../../utils/history'
import dateFormat from "dateformat"


// Types d’actions
// ---------------
const constants = {
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
    GET_ORDERS_ERROR: 'GET_ORDERS_ERROR',
    GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
    GET_ORDER_ERROR: 'GET_ORDER_ERROR',
    DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
    DELETE_ORDER_ERROR: 'DELETE_ORDER_ERROR',
    CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_ERROR: 'CREATE_ORDER_ERROR',
    UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_ERROR: 'UPDATE_ORDER_ERROR',
    GET_ORDERS_STATS_SUCCESS: 'GET_ORDERS_STATS_SUCCESS',
    GET_ORDERS_STATS_ERROR: 'GET_ORDERS_STATS_ERROR'
}

// Réducteur
// ---------
const defaultState = { items: [], statistics: {} }

export function orders(state = defaultState, action) {
  switch (action.type) {
    case 'GET_ORDERS_SUCCESS':{
      const orders = action.payload

      return {
          ...state,
          ...orders
      }
    }
    case 'GET_ORDER_SUCCESS':{
      const items = state.items
      const order = action.payload
      const updatedItems = [ ...items ]
      
      if(updatedItems && updatedItems.length > 0){
        const index = updatedItems.findIndex(item => item.idOrder === order.idOrder)
        updatedItems[index] = order
      } else {
        updatedItems.push(order)
      }
      
      return {
          ...state,
          items: updatedItems
      }
    }
    case 'GET_ORDERS_STATS_SUCCESS':{
      const ordersStats = action.payload

      return {
        ...state,
        statistics: ordersStats
      }
    }
    default:{
      return state
    }
  }
}

// Action Creators
// ---------------
export function getOrders() {
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    const user = JSON.parse(localStorage.getItem('user'))

    if(user){
        try {
          setAuthHeader(user.token)
          setLocaleHeader(locale)

          const response = await axios.get(`/orders`)
          const orders = response.data
          dispatch({type: constants.GET_ORDERS_SUCCESS, payload: orders })
        } catch (error) {
          dispatch({ type: constants.GET_ORDERS_ERROR })
        }
    }
  }
}

export function getOrder(idOrder) {
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    const user = JSON.parse(localStorage.getItem('user'))

    if(user){
      try {
        setAuthHeader(user.token)
        setLocaleHeader(locale)

        const response = await axios.get(`/orders/${idOrder}`)
        const order = response.data
        dispatch({type: constants.GET_ORDER_SUCCESS, payload: order })
      } catch (error) {
        dispatch({ type: constants.GET_ORDER_ERROR })
        history.push('/app/orders')
      }
    }
  }
}

export function updateOrder(idOrder, order){
  return async (dispatch, getState) => {
      const { Intl : { locale } } = getState()
      const user = JSON.parse(localStorage.getItem('user'))

      if(user){
          try {
              setAuthHeader(user.token)
              setLocaleHeader(locale)

              const response = await axios.put(`/orders/${idOrder}`, order)
              const data = response.data
              dispatch(setAlert('SUCCESS', data.message))
              dispatch({ type: constants.UPDATE_ORDER_SUCCESS })
              dispatch(getOrder(idOrder))
          } catch (error) {
              const { data } = error.response
              dispatch(setAlert('DANGER', data.message))
              dispatch({ type: constants.UPDATE_ORDER_ERROR })
          }
      }
    }
}

export function getOrdersStatistics(startDate, endDate) {
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    const user = JSON.parse(localStorage.getItem('user'))

    if(user){
        try {
          setAuthHeader(user.token)
          setLocaleHeader(locale)

          const response = await axios.get(`/orders/statistics?startDate=${dateFormat(startDate, "yyyy-mm-dd")}&endDate=${dateFormat(endDate, "yyyy-mm-dd")}`)
          const ordersStats = response.data
          dispatch({type: constants.GET_ORDERS_STATS_SUCCESS, payload: ordersStats })
        } catch (error) {
          dispatch({ type: constants.GET_ORDERS_STATS_ERROR })
        }
    }
  }
}

