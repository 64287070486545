import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from "shards-react"

import { Link } from "react-router-dom"
import React from "react"
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root'
import  Steps from '../../components/shop/order/Steps'
import { register, login, lostPassword, updateCustomer } from '../../reducers/shop/customer.reducer'
import RegisterForm from "../../components/forms/RegisterForm"
import LoginForm from "../../components/forms/LoginForm"
import ForgotPasswordForm from "../../components/forms/ForgotPasswordForm"
import ProfilCustomerForm from "../../components/forms/ProfilCustomerForm"
import { withTranslate } from 'react-redux-multilingual'


class Authentication extends React.Component{

    constructor(props){
        super(props)
    
        this.state = {
            completedRegisterForm: false,
            completedLoginForm: false,
        }
    }

    completedDeliveryAddressForm = (status) => {
        this.setState({ completedDeliveryAddressForm: status })
    }

    completedInvoiceAddressForm = (status) => {
        this.setState({ completedInvoiceAddressForm: status })
    }

    render(){
        const { cart, customer : { logged, firstname, lastname, email }, match: { params : { key } }, translate } =  this.props
        let itemsIsEmpty = (cart.products && cart.products.length > 0) ? false : true

        return (
            <Container fluid className="main-content-container bg-dark">
                <Row className="page-header justify-content-center py-5">
                    <Col xl="7" md="11">
                        <Steps step={2} bookKey={key} />
                        <Card>
                            <CardHeader className="border-bottom bg-light">
                                02. {translate('login')}
                            </CardHeader>
                            <CardBody>
                                {!logged && <Row>
                                    <Col className="border-right mb-3">
                                        <Row className="justify-content-center">
                                            <Col lg="11">
                                                <h5 className="mb-4">{translate('createAccount')}</h5>
                                                <RegisterForm handler={register} completedForm={this.completedInvoiceAddressForm} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="justify-content-center">
                                            <Col lg="11">
                                                <h5 className="mb-4">{translate('alreadyRegistered')}</h5>
                                                <LoginForm handler={login} />
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <Col lg="11">
                                                <div className="mt-4 mb-2">{translate('lostPassword')}</div>
                                                <ForgotPasswordForm handler={lostPassword} showLabel={false} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>}
                                {logged &&
                                    <Row>
                                        <Col md="6" className="border-right mb-3">
                                            <Row className="justify-content-center">
                                                <Col lg="10">
                                                    <h5 className="mb-4">{translate('infos')}</h5>
                                                    <ProfilCustomerForm handler={updateCustomer} firstname={firstname} lastname={lastname} email={email} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="6">
                                            {/* <Row className="justify-content-center mb-4">
                                                <Col xl="11">
                                                    <div className="bg-light rounded p-4">
                                                        <h5 className="mb-4">Adresse de livraison et de facturation</h5>
                                                        <Button tag={Link} className="d-block d-sm-inline-block btn-white" to={`/shop/book/${key}/address`}>Mettre à jour les adresses</Button>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <Row className="justify-content-center">
                                                <Col lg="11">
                                                    <div className="bg-light rounded p-4">
                                                        <h5 className="mb-4">{translate('orderHistory')}</h5>
                                                        <Button tag={Link} className="d-block d-sm-inline-block btn-white" to={`/shop/book/${key}/customer/orders`}>{translate('seeOrderDetails')}</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>}
                            </CardBody>
                            <CardFooter className="border-top bg-light">
                                <Button tag={Link} className="d-block d-sm-inline-block mb-2" to={`/shop/book/${key}/cart`}>&larr; {translate('returnToCart')}</Button>
                                {logged && !itemsIsEmpty && <Button theme='primary' className='float-sm-right d-block d-sm-inline-block' tag={Link} to={`/shop/book/${key}/address`}>{translate('checkout')} &rarr;</Button>}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({ shop : { cart, customer } } ) => {
    return { cart, customer }
}

export default hot(connect(mapStateToProps)(withTranslate(Authentication)))