import { func, object } from 'prop-types'
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { clearAlert } from '../../reducers/alert.reducer'

class Alert extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    alert: object.isRequired
  }

  closeAlert = (idMessage) => {
      this.props.dispatch(clearAlert(idMessage))
  }

  render() {
    const { alert : { messages } } = this.props

    return messages.map((message, index) => 
      <div key={index} className={`alert alert-${message.type} text-center`} style={{marginTop:`${index * 3}rem`}}>
          <span aria-hidden="true" className="alert-close" onClick={()=> { this.closeAlert(message.idMessage) } }>&times;</span>
          {message.message}
      </div>
    )
  }
}

function mapStateToProps( { alert } ) {
  return { alert }
}

export default hot(connect(mapStateToProps)(Alert))

