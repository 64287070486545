import { Button, Card, CardBody, CardFooter, CardHeader, CardImg, CardTitle, Col, Container, Row, FormTextarea, FormCheckbox } from "shards-react"

import { Link } from "react-router-dom"
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import  Steps from '../../components/shop/order/Steps'
import { history } from '../../utils/history'
import { createOrder } from '../../reducers/shop/orders.reducer'
import { getCart } from '../../reducers/shop/cart.reducer'
import StripeCheckout from '../../components/common/StripeCheckout'
import { withTranslate } from 'react-redux-multilingual'


class Payment extends React.Component{

    constructor(props){
        super(props)
        
        this.state = {
            orderInProcess: false,
            comment: "",
            acceptTerms: false
        }
    }

    handleChangeComment = (e) => {
        this.setState({ comment: e.target.value });
    }

    processingOrder = async(paymentIntent) => {
        const { book : { idBook }, cart : { idCart } } = this.props
        const { comment } =  this.state

        if(idBook && idCart){
            // Si commande par carte de crédit
            if(paymentIntent && paymentIntent.id){
                this.setState({ orderInProcess: true })
                
                this.props.dispatch(createOrder({ idCart, idBook, comment, transactionBy: "Carte bancaire", payment: { type: 'stripe', paymentIntent }}))
            } 
            // Si commande par email
            else {
                this.setState({ orderInProcess: true })

                this.props.dispatch(createOrder({ idCart, idBook, comment, transactionBy: "Email" }))
            }
        }
    }

    handleChange = () => {
        this.setState((state) => ({ acceptTerms: !state.acceptTerms }))
    }

    componentDidMount() {
        const { cart, book, customer, match: { params : { key } } } = this.props

        if(!cart || !cart.hasOwnProperty('products') || !customer.hasOwnProperty('addresses') 
        || (cart.hasOwnProperty('products') && cart.products.length === 0) 
        || (customer.hasOwnProperty('addresses') && customer.addresses.length === 0)){
            history.push(`/shop/book/${key}/cart`)
        }

        if(cart.hasOwnProperty('idCart') && book.hasOwnProperty('idBook') && cart.idCart !== 0 && book.idBook !== 0) {
            this.props.dispatch(getCart(cart.idCart, book.idBook))
        }
    }

    render(){
        const { cart, match: { params : { key } }, orderInProcess, book : { owner : { externalPaymentGateway, displayPricesWithoutTax }, activeEshop, terms }, customer, translate } = this.props
        const { comment, acceptTerms } = this.state
        const thumbsPath = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/images/thumbs/`

        return (
            <Container fluid className="main-content-container bg-dark">
                <Row className="page-header justify-content-center py-5">
                    <Col xl="7" md="11">
                        <Steps step={5} bookKey={key} />
                        <Card>
                            <CardHeader className="border-bottom bg-light">05. {translate('summary')}</CardHeader>
                            <CardBody>
                                {cart.products && cart.products.map((product, index)=> {
                                    return <Row key={index} className="justify-content-center mb-3">
                                        <Col xs="2" className="order-1">
                                            <CardImg crossOrigin="anonymous" className="img-thumbnail" src={product.images[0] ? `${thumbsPath}${product.images[0].name}` : `${thumbsPath}default.jpg`} />
                                        </Col>
                                        <Col xs="5" className="order-2">
                                            <CardTitle className="text-primary">{product.name}</CardTitle>
                                            <p className="mb-4 d-none d-sm-block">{product.desc.replace(/(<([^>]+)>)/ig,"").substring(0,80)}...</p>
                                            <p className="mb-0 d-none d-sm-block">
                                                { displayPricesWithoutTax ? product.priceExclTax : product.price} € <sup>{ displayPricesWithoutTax ? translate('exclTax') : translate('inclTax')}</sup>
                                            </p>
                                        </Col>
                                        <Col sm="2" xs="1" className="order-3 order-sm-3 align-self-center">
                                            { product.quantity }
                                        </Col>
                                        <Col sm="2" xs="3" className="order-4 order-sm-4 align-self-center text-center" >
                                            { displayPricesWithoutTax ? `${product.subTotalExclTax} €` : `${product.subTotalInclTax} €` } <sup>{ displayPricesWithoutTax ? translate('exclTax') : translate('inclTax')}</sup>
                                        </Col>
                                    </Row>
                                })}
                            </CardBody>
                           <CardFooter className="border-top bg-light">
                               <Row>
                                    <Col sm="3" >
                                        <Button tag={Link} className="d-block d-sm-inline-block mb-2 mb-sm-0" to={`/shop/book/${key}/cart`}>&larr; {translate('returnToCart')}</Button>
                                    </Col>
                                    <Col sm="6" >
                                        {cart && cart.shippingCosts && <table className="w-100">
                                            <tbody>
                                                <tr className="border-bottom">
                                                    <td className="text-light">
                                                        {translate('totalItems')} <small><i>{translate('ie')} {cart.totalPriceProductsExclTax}€ <sup>{translate('exclTax')}</sup></i></small>
                                                    </td>
                                                    <td className="text-right text-light">
                                                        {cart.totalPriceProductsInclTax}€ <sup>{translate('inclTax')}</sup>
                                                    </td>
                                                </tr>
                                                <tr className="border-bottom">
                                                    <td className="text-light">
                                                        {translate('shipping')} <small><i>{cart.shippingCosts.name.toLowerCase()}</i></small>
                                                    </td>
                                                    <td className="text-right text-light">
                                                        {cart.shippingCosts.totalPriceInclTax}€ <sup>{translate('inclTax')}</sup>
                                                    </td>
                                                </tr>
                                                {cart.discount > 0 && <tr className="border-bottom">
                                                    <td className="text-light">
                                                        {translate('discount')}
                                                    </td>
                                                    <td className="text-right text-light">
                                                        - {cart.discount}€ <sup>{translate('inclTax')}</sup>
                                                    </td>
                                                </tr>}
                                                <tr>
                                                    <td>
                                                        <b>{translate('total').toUpperCase()}</b> <small><i>{translate('ie')} {cart.totalPriceExclTax}€ <sup>{translate('exclTax')}</sup></i></small>
                                                    </td>
                                                    <td className="text-right font-weight-bold">
                                                        <b>{cart.totalPriceInclTax}€ <sup>{translate('inclTax')}</sup></b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                    </Col>
                               </Row>
                            </CardFooter>
                        </Card>
                        <Card className="mt-4">
                            <CardHeader className="border-bottom bg-light">{translate('leaveComment')}</CardHeader>
                            <CardBody>
                                <Row className="justify-content-center">
                                    <Col>
                                        <FormTextarea onChange={this.handleChangeComment} value={comment} style={{height:'150px'}} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="mt-4">
                            <CardHeader className="border-bottom bg-light">{(activeEshop) ? translate('payment') : translate('checkout')}</CardHeader>
                            <CardBody>
                                {activeEshop && <Row className="justify-content-center">
                                    <Col>
                                        <p className="mb-2">{translate('termsAndConditions')}</p>
                                        <div className="terms terms-min border p-3 bg-light" dangerouslySetInnerHTML={{ __html: terms }}></div>
                                        <FormCheckbox
                                            className="mt-2"
                                            toggle
                                            checked={acceptTerms}
                                            onChange={this.handleChange}
                                            > {translate('iAcceptTermsAndConditions')}</FormCheckbox>
                                    </Col>
                                </Row>}
                                {activeEshop && acceptTerms && <Row className="justify-content-center mt-4">
                                    <Col>
                                        <div className="border p-3 rounded">
                                            {externalPaymentGateway && externalPaymentGateway.hasOwnProperty('connectedAccountId') && externalPaymentGateway.connectedAccountId !== '' && 
                                            <StripeCheckout 
                                                connectedAccount={{...externalPaymentGateway}} 
                                                purchase={{
                                                    type:'order', 
                                                    idCart:cart.idCart,
                                                    idBook:cart.idBook,
                                                    price: cart.totalPriceInclTax
                                                }}
                                                client={{...customer, type:'customer'}} 
                                                handlerAfterPaymentSuccess={this.processingOrder} 
                                            />}
                                            {orderInProcess && <p className="text-primary">{translate('inProgress')}</p>}
                                        </div>
                                    </Col>
                                </Row>}
                                {!activeEshop && <Row className="justify-content-center">
                                    <Col>
                                        <Button theme='primary' block className='text-white py-3' disabled={orderInProcess} onClick={this.processingOrder}>{translate('checkout').toUpperCase()}</Button>
                                    </Col>
                                </Row>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({ shop : { cart, customer, book, orders } } ) => {
    return { cart, customer, book, orders }
}

export default hot(connect(mapStateToProps)(withTranslate(Payment)))