export const status = [
    {
        id: 1,
        name : "pending",
        color : "warning"
    },
    {
        id: 2,
        name : "completed",
        color : "success"
    },
    {
        id: 3,
        name : "cancelled",
        color : "danger"
    },
    {
        id: 4,
        name : "other",
        color : "info"
    }
]

export function getStatus(statusId){
    return status[statusId - 1]
}