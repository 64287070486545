import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { createStripePaymentIntent } from '../../reducers/payment.reducer'
import { Button } from "shards-react"
import { withTranslate } from 'react-redux-multilingual'


const DEFAULT_STATE = {
  error: null,
  cardComplete: false,
  processing: false,
  paymentIntentConfirm: false
}

class StripeCheckoutForm extends React.Component {

  constructor(props){
    super(props)

    this.state = DEFAULT_STATE
  }
  
  handleSubmit = async(event) => {
    event.preventDefault()

    const { stripe, elements, purchase, connectedAccount, client : { type, firstname, lastname, email }, handlerAfterPaymentSuccess } = this.props
    const { error, cardComplete } = this.state

    if (!stripe || !elements || !purchase || !firstname || !lastname || !email || !handlerAfterPaymentSuccess) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement('card').focus()
      return
    }

    if (cardComplete) {
      this.setState({ processing: true })
    
      const paymentIntent = await this.props.dispatch(createStripePaymentIntent(type,purchase,connectedAccount))

      if(!paymentIntent || !paymentIntent.client_secret){
        return
      } else {
        const result  = await stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: `${lastname} ${firstname}`,
            },
          },
          receipt_email: email
        })

        if (result.error) {
          this.setState({error: result.error})
        } else {
          if(result.paymentIntent.status === 'succeeded' && result.paymentIntent.id){
            this.setState({ paymentIntentConfirm: true })
            
            handlerAfterPaymentSuccess(result.paymentIntent)
          }
        }
      }

      this.setState({processing: false})
    }
  }

  render(){
    const { error, processing, cardComplete, paymentIntentConfirm } = this.state
    const { stripe, purchase, translate } = this.props

    const options = {
            iconStyle: 'solid',
            style: {
            base: {
                iconColor: '#d0be88',
                color: '#d0be88',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                color: '#d0be88',
                },
                '::placeholder': {
                color: '#d0be88',
                },
            },
            invalid: {
                iconColor: '#ff0022',
                color: '#ff0022',
            }
        },
    }

    return paymentIntentConfirm ? (
        <p className="text-success mb-2">{translate('successfulPayment')} </p>
      ) : (
      <form onSubmit={this.handleSubmit} className="text-center">
        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
              onChange={(event) => {
                this.setState({
                  error: event.error,
                  cardComplete: event.complete,
                })
              }}
              />
          </div>
        </div>

        {error && <div className="text-danger mt-3">{error.message}</div>}

        <Button className="mt-4" size="md" theme="primary" block disabled={ processing || !stripe || !cardComplete }>
          {processing ? translate('inProgress') :  `${translate('pay')} - ${purchase.price} €`}
        </Button>
      </form>
    )
  }
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// Call `loadStripe` with the same connected account ID used when creating
// the PaymentIntent.
let stripePromise

const getStripeForConnectedAccount = (connectedAccount) => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { stripeAccount: connectedAccount.connectedAccountId })
  }
  return stripePromise
}

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  }
  return stripePromise
}

const StripeCheckout = ({ connectedAccount, purchase, client, handlerAfterPaymentSuccess, dispatch, translate }) => {
  return (
    <Elements stripe={(connectedAccount) ? getStripeForConnectedAccount(connectedAccount) : getStripe() }>
      <ElementsConsumer>
        {({elements, stripe}) => (
          <StripeCheckoutForm elements={elements} connectedAccount={connectedAccount} stripe={stripe} purchase={purchase} client={client} handlerAfterPaymentSuccess={handlerAfterPaymentSuccess} dispatch={dispatch} translate={translate} />
        )}
      </ElementsConsumer>
    </Elements>
  )
}

export default hot(connect()(withTranslate(StripeCheckout)))
