import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'

// Types d’actions
// ---------------
const constants = {
    GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_ERROR: 'GET_CUSTOMERS_ERROR'
};

// Réducteur
// ---------
const defaultState = {}

export function customers(state = defaultState, action) {
  switch (action.type) {
        case 'GET_CUSTOMERS_SUCCESS':{
            const customers = action.payload

            return {
                ...state,
                ...customers
            }
        } default: {
            return state
        }
    }
}

// Action Creators
// ---------------
export function getCustomers() {
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))
    
        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)
                const response = await axios.get(`/customers`)
                const customers = response.data
                dispatch({type: constants.GET_CUSTOMERS_SUCCESS, payload: customers })
            } catch (error) {
                dispatch({ type: constants.GET_CUSTOMERS_ERROR })
            }
        }
    }
}