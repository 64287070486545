import axios, { setAuthHeader, setLocaleHeader } from '../utils/axios'
import { setAlert } from './alert.reducer'
import { checkValidToken } from './app/user.reducer'
import { history } from '../utils/history'


// Types d’actions
// ---------------
const constants = {
    'STRIPE_PAYMENT_INTENT_SUCCESS': 'STRIPE_PAYMENT_INTENT_SUCCESS',
    'STRIPE_PAYMENT_INTENT_ERROR': 'STRIPE_PAYMENT_INTENT_ERROR',
    'STRIPE_AUTHORIZATION_SUCCESS': 'STRIPE_AUTHORIZATION_SUCCESS',
    'STRIPE_AUTHORIZATION_ERROR': 'STRIPE_AUTHORIZATION_ERROR',
    'STRIPE_DEAUTHORIZATION_SUCCESS': 'STRIPE_DEAUTHORIZATION_SUCCESS',
    'STRIPE_DEAUTHORIZATION_ERROR': 'STRIPE_DEAUTHORIZATION_ERROR',
};

// Réducteur
// ---------
const defaultState = {}

export function payment(state = defaultState, action) {
  switch (action.type) {
    default:
      return state
  }
}

// Action Creators
// ---------------
export function createStripePaymentIntent(clientType, purchase, connectedAccount){
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    let userOrCustomer

    if(clientType === 'user'){    
      userOrCustomer = JSON.parse(localStorage.getItem('user'))
    } else (
      userOrCustomer = JSON.parse(localStorage.getItem('customer'))
    )

    if(userOrCustomer){
        try {
          setAuthHeader(userOrCustomer.token)
          setLocaleHeader(locale)
          const response = await axios.post(`/payments/stripe/payment-intents`, { purchase, connectedAccount })
          const paymentIntent = response.data

          dispatch({type: constants.STRIPE_PAYMENT_INTENT_SUCCESS })
          
          return paymentIntent
        } catch (error) {
          const { data } = error.response
          
          dispatch(setAlert('DANGER', data.message))
          dispatch({ type: constants.STRIPE_PAYMENT_INTENT_ERROR })
        }
    }
  }
}

export function stripeAuthorization(scope, code){
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    await dispatch(checkValidToken())
    
    const user = JSON.parse(localStorage.getItem('user'))

    if(user){
        try {
          setAuthHeader(user.token)
          setLocaleHeader(locale)
          const response = await axios.get(`/payments/stripe/connect/oauth?scope=${scope}&code=${code}`)
          const data = response.data

          dispatch(setAlert('SUCCESS', data.message, 5000))
          dispatch({ type: constants.STRIPE_AUTHORIZATION_SUCCESS })
          history.push('/app/shop')
        } catch (error) {
          const { data } = error.response
          
          dispatch(setAlert('DANGER', data.message, 5000))
          dispatch({ type: constants.STRIPE_AUTHORIZATION_ERROR })
          history.push('/app/shop')
        }
    }
  }
}

export function stripeDeauthorization(scope, code){
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()

    await dispatch(checkValidToken())
    
    const user = JSON.parse(localStorage.getItem('user'))

    if(user){
        try {
          setAuthHeader(user.token)
          setLocaleHeader(locale)
          
          const response = await axios.get(`/payments/stripe/connect/deauthorize`)
          const data = response.data

          dispatch(setAlert('SUCCESS', data.message))
          dispatch({ type: constants.STRIPE_DEAUTHORIZATION_SUCCESS })

          dispatch(checkValidToken())
        } catch (error) {
          const { data } = error.response
          
          dispatch(setAlert('DANGER', data.message))
          dispatch({ type: constants.STRIPE_DEAUTHORIZATION_ERROR })
        }
    }
  }
}
