import { Nav, DropdownItem, NavItem, NavLink } from "shards-react"
import React from "react"
import UserActions from "./UserActions"
import Help from "../../../common/Help"  
import RemainingCredits from './RemainingCredits'
import { withTranslate, IntlActions } from 'react-redux-multilingual'
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import flagFR from '../../../../images/fr.svg'
import flagEN from '../../../../images/en.svg'

class NavbarNav extends React.Component {
  
  render(){
    const { translate } = this.props

    return (<Nav navbar className="flex-row align-items-center">
      <Help className="help cursor-pointer">
        <DropdownItem className="text-center"><i className="material-icons text-primary">help</i> {translate('help')}</DropdownItem>
        <DropdownItem>
            <div className="notification__icon-wrapper">
            <div className="notification__icon">
                <i className="material-icons">menu_book</i>
            </div>
            </div>
            <div className="notification__content">
            <span className="notification__category text-primary">{translate('helpSellerTitle1')}</span>
            <ul className="pl-3">
              <li>{translate('helpSeller1')}</li>
              <li>{translate('helpSeller2')}</li>
              <li>{translate('helpSeller3')}</li>
            </ul>
            </div>
        </DropdownItem>
        <DropdownItem>
            <div className="notification__icon-wrapper">
            <div className="notification__icon">
                <i className="material-icons">menu_book</i>
            </div>
            </div>
            <div className="notification__content">
            <span className="notification__category text-primary">{translate('helpSellerTitle2')}</span>
            <ul className="pl-3">
              <li>{translate('helpSeller4')}</li>
              <li>{translate('helpSeller5')}<i className="material-icons text-dark">create</i></li>
              <li>{translate('helpSeller6')} 
              <small className="d-block"><i>{translate('helpSeller7')}</i></small></li>
              <li>{translate('helpSeller8')}</li>
            </ul>
            </div>
        </DropdownItem>
        <DropdownItem>
            <div className="notification__icon-wrapper">
            <div className="notification__icon">
                <i className="material-icons">menu_book</i>
            </div>
            </div>
            <div className="notification__content">
            <span className="notification__category text-primary">{translate('helpSellerTitle3')}</span>
            <ul className="pl-3">
              <li>{translate('helpSeller9')}</li>
              <li>{translate('helpSeller10')}<i className="material-icons text-dark">create</i></li>
              <li>{translate('helpSeller11')}</li>
              <li>{translate('helpSeller12')}</li>
              <li>{translate('helpSeller13')}</li>
            </ul>
            </div>
        </DropdownItem>
        <DropdownItem>
            <div className="notification__icon-wrapper">
            <div className="notification__icon">
                <i className="material-icons">storefront</i>
            </div>
            </div>
            <div className="notification__content">
            <span className="notification__category text-primary">{translate('helpSellerTitle4')}</span>
            <ul className="pl-3">
              <li>{translate('helpSeller14')}</li>
              <li>{translate('helpSeller15')}</li>
              <li>{translate('helpSeller16')}</li>
              <li>{translate('helpSeller17')}</li>
            </ul>
            </div>
        </DropdownItem>
        <DropdownItem>
            <div className="notification__icon-wrapper">
            <div className="notification__icon">
                <i className="material-icons">storefront</i>
            </div>
            </div>
            <div className="notification__content">
            <span className="notification__category text-primary">{translate('helpSellerTitle5')}</span>
            <ul className="pl-3">
              <li>{translate('helpSeller18')}</li>
              <li>{translate('helpSeller19')}<i className="material-icons text-dark">create</i></li>
              <li>{translate('helpSeller20')}</li>
              <li>{translate('helpSeller21')}</li>
              <li>{translate('helpSeller22')}</li>
              <li>{translate('helpSeller23')}</li>
              <li>{translate('helpSeller24')}</li>
              <li>{translate('helpSeller25')}</li>
            </ul>
            </div>
        </DropdownItem>
        <DropdownItem>
            <div className="notification__icon-wrapper">
            <div className="notification__icon">
                <i className="material-icons">storefront</i>
            </div>
            </div>
            <div className="notification__content">
            <span className="notification__category text-primary">{translate('helpSellerTitle6')}</span>
            <ul className="pl-3">
              <li>{translate('helpSeller26')}</li>
              <li>{translate('helpSeller27')}<i className="material-icons text-dark">miscellaneous_services</i>{translate('settings')}</li>
              <li>{translate('helpSeller28')}</li>
              <li>{translate('helpSeller29')}</li>
              <li>{translate('helpSeller30')}</li>
            </ul>
            </div>
        </DropdownItem>
        <DropdownItem>
            <div className="notification__icon-wrapper">
            <div className="notification__icon">
                <i className="material-icons">menu_book</i>
            </div>
            </div>
            <div className="notification__content">
            <span className="notification__category text-primary">{translate('helpSellerTitle7')}</span>
            <ul className="pl-3">
              <li>{translate('helpSeller31')}</li>
              <li>{translate('helpSeller32')}</li>
              <li>{translate('helpSeller33')}<i className="material-icons text-dark">create</i>{translate('helpSeller33')}</li>
              <li>{translate('helpSeller34')}</li>
              <li>{translate('helpSeller35')}</li>
            </ul>
            </div>
        </DropdownItem>
      </Help>
      <RemainingCredits/>
      <UserActions />
      {/* <NavItem>
        <NavLink href="#" onClick={() => { this.props.dispatch(IntlActions.setLocale('fr'))}}  className="d-flex justify-content-center align-items-center text-primary py-2 m0">
          <img width="30px" src={flagFR} alt=""/>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#" onClick={() => { this.props.dispatch(IntlActions.setLocale('en'))}} className="d-flex justify-content-center align-items-center text-primary py-2 m0">
          <img width="30px" src={flagEN} alt=""/>
        </NavLink>
      </NavItem> */}
    </Nav>)
  }
}


export default hot(connect()(withTranslate(NavbarNav)))