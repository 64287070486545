import { Button, Col, FormGroup, FormInput, FormSelect, Modal, ModalBody, ModalHeader, Row } from "shards-react";

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


class Area extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      resizer: false,
      modalOpen: false,
    }
  }

  activeResizer = () => {
    this.setState({
      resizer: true
    })
  }

  desactiveResizer = () => {
    this.setState({
      resizer: false
    })
  }

  toggleModal = () => {
    this.setState((state) => ({modalOpen: !state.modalOpen}))
  }
  
  // Autobind with arrow func
  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  render(){
    let { getRef, x, y, angle, width, height, deleteArea, type, value, updateArea, products, translate } = this.props
    const { resizer, modalOpen } = this.state

    x = `${x}%`
    y = `${y}%`
    width = `${width}%`
    height = `${height}%`

    return (
      <>
        <div
          style={{
            left: x,
            top: y,
            width: width,
            height: height,
            transform: `rotate(${angle}deg)`,
          }}
          className={`set-area ${resizer ? ' set-area--active' : ''}`}
          ref={getRef}
          onMouseEnter={this.activeResizer}
          onMouseLeave={this.desactiveResizer}
        >
          {resizer && <>
            <div onClick={deleteArea} className="cursor-pointer text-center" style={{position:'absolute',top:'10px',right:'-26px',width:'25px',height:'25px','backgroundColor':'#fff',border:'1px solid dodgerblue'}}><i className="material-icons font-size-md text-primary">delete</i></div>
            <div onClick={this.toggleModal} className="cursor-pointer text-center" style={{position:'absolute',top:'35px',right:'-26px',width:'25px',height:'25px','backgroundColor':'#fff',border:'1px solid dodgerblue','borderTop':'none'}}><i className="material-icons font-size-md text-primary">edit</i></div>
            <span style={{position:'absolute',top:'-5px',left:'-5px',width:'10px',height:'10px','backgroundColor':'hsla(0,0%,100%,.8)',border:'1px solid blue'}}></span>
            <span style={{position:'absolute',top:'-5px',right:'-5px',width:'10px',height:'10px','backgroundColor':'hsla(0,0%,100%,.8)',border:'1px solid blue'}}></span>
            <span style={{position:'absolute',bottom:'-5px',right:'-5px',width:'10px',height:'10px','backgroundColor':'hsla(0,0%,100%,.8)',border:'1px solid blue'}}></span>
            <span style={{position:'absolute',bottom:'-5px',left:'-5px',width:'10px',height:'10px','backgroundColor':'hsla(0,0%,100%,.8)',border:'1px solid blue'}}></span>
            <span style={{position:'absolute',top:'calc(50% - 5px)',left:'-5px',width:'10px',height:'10px','backgroundColor':'hsla(0,0%,100%,.8)',border:'1px solid blue'}}></span>
            <span style={{position:'absolute',top:'calc(50% - 5px)',right:'-5px',width:'10px',height:'10px','backgroundColor':'hsla(0,0%,100%,.8)',border:'1px solid blue'}}></span> 
            <span style={{position:'absolute',left:'calc(50% - 5px)',bottom:'-5px',width:'10px',height:'10px','backgroundColor':'hsla(0,0%,100%,.8)',border:'1px solid blue'}}></span> 
            <span style={{position:'absolute',left:'calc(50% - 5px)',top:'-5px',width:'10px',height:'10px','backgroundColor':'hsla(0,0%,100%,.8)',border:'1px solid blue'}}></span> 
          </>}
        </div>
        {modalOpen && 
          <Modal size="lg" centered={true} open={modalOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>{translate('setArea')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg={12}>
                      <form>
                          <FormGroup>
                              <label htmlFor="lastname">{translate('associateArea')}</label>
                              <FormSelect name="type" value={type} onChange={updateArea}>
                                  <option value="link">{translate('link')}</option>
                                  <option value="product">{translate('product')}</option>
                              </FormSelect>
                          </FormGroup>
                          {type === 'link' &&
                              <FormGroup>
                              <label htmlFor="lastname">{translate('externalLink')}</label>
                              <FormInput
                                  type="text"
                                  id="link"
                                  placeholder="https://module-market.fr"
                                  name="value"
                                  value={value}
                                  onChange={updateArea}
                              />
                              </FormGroup>}
                          {type === 'product' && products &&
                              <FormGroup>
                              <label htmlFor="lastname">{translate('productToAssociate')}</label>
                              <FormSelect name="value" value={value} onChange={updateArea}>
                                  <option key="0" value="0">{translate('chooseProduct')}</option>
                                  {products.items.map((product,index)=>{
                                  return <option key={index+1} value={product.idProduct}>{product.ref} - {product.name}</option>
                                  })}
                              </FormSelect>
                          </FormGroup>}
                        </form>
                        <Button onClick={this.toggleModal}>{translate('confirm')}</Button>
                    </Col>
                </Row>
            </ModalBody>
          </Modal>
        }
      </>
    )
  }
}

function mapStateToProps({ products }) {
  return { products }
}

export default hot(connect(mapStateToProps)(withTranslate(Area)))