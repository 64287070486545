import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }
})
  
const Invoice = ({invoice, translate}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <InvoiceTitle title={translate('receipt')}/>
            <InvoiceNo invoice={invoice} translate={translate}/>
            <BillTo invoice={invoice}/>
            <InvoiceItemsTable invoice={invoice} translate={translate} />
        </Page>
    </Document>
)
  
  export default Invoice