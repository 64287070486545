import axios, { setLocaleHeader } from '../../utils/axios'
import { toggleProductDetailModal } from './customerInterface.reducer'

// Types d’actions
// ---------------
const constants = {
    GET_SELLER_PRODUCT_SUCCESS: 'GET_SELLER_PRODUCT_SUCCESS',
    GET_SELLER_PRODUCT_ERROR: 'GET_SELLER_PRODUCT_ERROR',
    GET_SELLER_PRODUCTS_SUCCESS: 'GET_SELLER_PRODUCTS_SUCCESS',
    GET_SELLER_PRODUCTS_ERROR: 'GET_SELLER_PRODUCTS_ERROR',
};

// Réducteur
// ---------
const defaultState = { items: [], totalItems: 0 }

export function products(state = defaultState, action) {
  switch (action.type) {
    case 'GET_SELLER_PRODUCT_SUCCESS':{
        const product = action.payload
        const updatedProducts = [ ...state.items ]

        const index = updatedProducts.findIndex(item => item.idProduct === product.idProduct)
        updatedProducts[index] = product

        return {
            ...state,
            items: updatedProducts
        }
    }
    case 'GET_SELLER_PRODUCTS_SUCCESS':{
        const products = action.payload

        return {
            ...state,
            ...products
        }
    } 
    default:
        return state
  }
}

// Action Creators
// ---------------
export function getProduct(idProduct) {
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        try {
            setLocaleHeader(locale)
            
            const response = await axios.get(`/products/${idProduct}`)
            const product = response.data
            dispatch({type: constants.GET_SELLER_PRODUCT_SUCCESS, payload: product })
            dispatch(toggleProductDetailModal(idProduct))
        } catch (error) {
            dispatch({ type: constants.GET_SELLER_PRODUCT_ERROR })
        }
    }
}

export function getProductsByBook(idBook, offset, limit) {
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()

        try {
            setLocaleHeader(locale)

            const response = await axios.get(`/products/book/${idBook}?offset=${offset}&limit=${limit}`)
            const products = response.data

            dispatch({type: constants.GET_SELLER_PRODUCTS_SUCCESS, payload: products })
        } catch (error) {
            dispatch({ type: constants.GET_SELLER_PRODUCTS_ERROR })
        }
    }
}