import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableFooter from './InvoiceTableFooter'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#e1e5eb',
    },
});

  const InvoiceItemsTable = ({invoice, translate}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader translate={translate} />
        <InvoiceTableRow items={invoice.items} />
        <InvoiceTableFooter discount={invoice.discount} totalPriceInclTax={invoice.totalPriceInclTax} shippingCostsInclTax={invoice.shippingCostsInclTax} translate={translate} />
    </View>
  );
  
  export default InvoiceItemsTable