import axios from '../utils/axios'

// Types d’actions
// ---------------
const constants = {
    'SET_CORE': 'SET_CORE',
    'CHECK_VERSION_CORE_SUCCESS': 'CHECK_VERSION_CORE_SUCCESS',
    'CHECK_VERSION_CORE_ERROR': 'CHECK_VERSION_CORE_ERROR',
    'RESET_STORE': 'RESET_STORE'
}

const defaultState = {}

// Réducteur
// ---------
export function core(state = defaultState, action) {
  switch (action.type) {
    case 'SET_CORE': {
        const core = action.payload

        return {
          ...state,
          ...core
        }
    }
    default:
      return state
  }
}

// Action Creators
// ---------------
export function checkCore(){
  return async (dispatch, getState) => {
    const { core: coreState } = getState()

    try {
      const response = await axios.get('/core/infos')
      const corePayload = response.data

      // Check if the client/server is under maintenance or state change
      if(corePayload && coreState && corePayload.maintenance !== coreState.maintenance){
        dispatch({ type : constants.SET_CORE, payload: corePayload })
      }
      // Check if a new version is ready
      else if(corePayload && coreState && corePayload.version && coreState.version && corePayload.version !== coreState.version){
        // Reset
        await dispatch({ type : constants.RESET_STORE })
        await dispatch({ type : constants.SET_CORE, payload: corePayload })
        
        // reload app for files changes
        setTimeout(() => {
          window.location.reload()
        }, 250)
      } else {
        dispatch({ type : constants.CHECK_VERSION_CORE_SUCCESS })
      }
    } catch(error) {
      dispatch({ type : constants.CHECK_VERSION_CORE_ERROR })
    }
  }
}