import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'
import { setAlert } from '../alert.reducer';
import { checkValidTokenCustomer } from './customer.reducer'
import { history } from '../../utils/history'


// Types d’actions
// ---------------
const constants = {
    'GET_CART_SUCCESS': 'GET_CART_SUCCESS',
    'GET_CART_ERROR': 'GET_CART_ERROR',
    'ADD_PRODUCT_CART_SUCCESS': 'ADD_PRODUCT_CART_SUCCESS',
    'ADD_PRODUCT_CART_ERROR': 'ADD_PRODUCT_CART_ERROR',
    'SUB_PRODUCT_CART_SUCCESS': 'SUB_PRODUCT_CART_SUCCESS',
    'SUB_PRODUCT_CART_ERROR': 'SUB_PRODUCT_CART_ERROR',
    'REMOVE_PRODUCT_CART_SUCCESS': 'REMOVE_PRODUCT_CART_SUCCESS',
    'REMOVE_PRODUCT_CART_ERROR': 'REMOVE_PRODUCT_CART_ERROR',
    'EMPTY_CART_SUCCESS': 'EMPTY_CART_SUCCESS',
    'UPDATE_CART_BELONGS_TO_GUEST_SUCCESS': 'UPDATE_CART_BELONGS_TO_GUEST_SUCCESS',
    'UPDATE_CART_BELONGS_TO_GUEST_ERROR': 'UPDATE_CART_BELONGS_TO_GUEST_ERROR',
    'PRODUCTS_IN_CART_AVAILABLE':'PRODUCTS_IN_CART_AVAILABLE',
    'PRODUCTS_IN_CART_UNAVAILABLE':'PRODUCTS_IN_CART_UNAVAILABLE',
    'UPDATE_SHIPPING_CHOICE_SUCCESS': 'UPDATE_SHIPPING_CHOICE_SUCCESS',
    'UPDATE_SHIPPING_CHOICE_ERROR': 'UPDATE_SHIPPING_CHOICE_ERROR'
};

// Réducteur
// ---------
const defaultState = {}

export function cart(state = defaultState, action) {
    switch (action.type) {
        case 'GET_CART_SUCCESS': {
            const newCart = action.payload

            return {
                ...state,
                ...newCart
            }
        }
        case 'GET_CART_ERROR': {
            return defaultState
        }
        case 'EMPTY_CART_SUCCESS': {
            return defaultState
        }
        default:
            return state
    }
}

// Action Creators
// ---------------
export function updateProductToCart(idProduct, quantity, action){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        await dispatch(checkValidTokenCustomer())

        const customer = JSON.parse(localStorage.getItem('customer'))
        const { shop : { cart, book } } = getState()

        if(customer){
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)
                
                if(cart.idBook === book.idBook && cart.products){
                    let updatedQuantity = quantity
                    const productIsInCart = cart.products.find((product) => product.idProduct === idProduct)   
                    
                    if(productIsInCart){
                        switch(action){
                            case 'add' : {
                                updatedQuantity = productIsInCart.quantity + updatedQuantity
                                break
                            }
                            case 'sub' : {
                                updatedQuantity = productIsInCart.quantity - updatedQuantity
                                break
                            }
                            case 'rem' : {
                                updatedQuantity = 0
                                break
                            }
                        }
                    }

                    try {
                        // Update cart
                        await axios.put(`/carts/${cart.idCart}`, { idBook : book.idBook, idProduct, quantity: updatedQuantity })
                        dispatch({ type: constants.ADD_PRODUCT_CART_SUCCESS })

                        dispatch(setAlert('SUCCESS', locale === 'fr' ? 'Panier mis à jour avec succès' : 'Cart successfully updated'))

                        // Get cart
                        dispatch(getCart(cart.idCart, book.idBook))
                    } catch (error) {
                        const { data } = error.response
                        dispatch({ type: constants.ADD_PRODUCT_CART_ERROR })
                        dispatch(setAlert('DANGER', data.message))
                    }
                } else {
                    // Create cart
                    const response = await axios.post(`/carts/`, { idBook : book.idBook, idProduct, quantity })
                    const { idCart } = response.data
                    dispatch({ type: constants.ADD_PRODUCT_CART_SUCCESS })

                    dispatch(setAlert('SUCCESS', locale === 'fr' ? 'Panier mis à jour avec succès' : 'Cart successfully updated'))
                    
                    // Get cart
                    dispatch(getCart(idCart, book.idBook))
                }
            } catch (error) {
                const { data } = error.response
                dispatch({ type: constants.ADD_PRODUCT_CART_ERROR })
                dispatch(setAlert('DANGER', data.message))
            }
        }
    }
}

export function getCart(idCart, idBook){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(customer){
            // Get Cart
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)
               
                const response = await axios.get(`/carts/${idCart}?idBook=${idBook}`)
                const cart = response.data

                dispatch({ type: constants.GET_CART_SUCCESS, payload: cart })
            } catch (error) {
                dispatch({ type: constants.GET_CART_ERROR })
            }  
        }  
    }
}

export function getCustomerCart(idBook){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))
        
        if(customer){
            // Get Cart
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)
                
                const response = await axios.get(`/carts/customer?idBook=${idBook}`)
                const cart = response.data
                dispatch({ type: constants.GET_CART_SUCCESS, payload: cart })
            } catch (error) {
                dispatch({ type: constants.GET_CART_ERROR })
            } 
        }
    }
}

export function checkCartBelongsToGuest(guestToken, customerToken){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()

        if(guestToken && customerToken){
            try {
                setAuthHeader(customerToken)
                setLocaleHeader(locale)

                await axios.put(`/carts/guest`, { token: guestToken })
                dispatch({ type: constants.UPDATE_CART_BELONGS_TO_GUEST_SUCCESS })
            } catch (error) {
                dispatch({ type: constants.UPDATE_CART_BELONGS_TO_GUEST_ERROR })
            } 
        }
    }
}

export function resetCart(){
    return (dispatch) => {
        dispatch({ type: constants.EMPTY_CART_SUCCESS })
    }
}

export function updateCustomerShippingChoice(idShippingCosts, idCollectionShops){
    return async (dispatch, getState) => {
        const { Intl : { locale }, shop : { cart, book : { key } } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(customer){
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)

                await axios.put(`/carts/${cart.idCart}/shipping`, { idShippingCosts, idCollectionShops })
                dispatch({ type: constants.UPDATE_SHIPPING_CHOICE_SUCCESS })

                history.push(`/shop/book/${key}/payment`)
            } catch (error) {
                dispatch({ type: constants.UPDATE_SHIPPING_CHOICE_ERROR })
            }
        }
    }
}