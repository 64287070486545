// Types d’actions
// ---------------
const constants = {
    WAITING: 'WAITING',
    SHOP_PRODUCT_DETAIL_MODAL: 'SHOP_PRODUCT_DETAIL_MODAL',
    SET_SIZE_BOOK_SUCCESS: 'SET_SIZE_BOOK_SUCCESS'
}

// Réducteur
// ---------
const defaultState = {
    productDetailModal : {
        open : false,
        idProduct: 0
    }
}

export function customerInterface(state = defaultState, action) {
    switch (action.type) {
        case 'SHOP_PRODUCT_DETAIL_MODAL':{
            const { open } = state.productDetailModal
            const { idProduit } = action.payload

            return {
                ...state,
                productDetailModal:{
                    open: !open,
                    idProduct: idProduit
                }
            }
        } default: {
            return state
        }
    }
}


// Action Creators
// ---------------
export function toggleProductDetailModal(idProduit){
    return (dispatch) => {
        dispatch({ type: constants.SHOP_PRODUCT_DETAIL_MODAL, payload: { idProduit } })
    }
}