import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'
import { resetCart } from './cart.reducer'
import { history } from '../../utils/history'
import { setAlert } from '../alert.reducer'



// Types d’actions
// ---------------
const constants = {
    'CREATE_ORDER_SUCCESS': 'CREATE_ORDER_SUCCESS',
    'CREATE_ORDER_ERROR': 'CREATE_ORDER_ERROR',
    'RESET_ORDER': 'RESET_ORDER',
    'GET_ORDERS_SUCCESS': 'GET_ORDERS_SUCCESS',
    'GET_ORDERS_ERROR': 'GET_ORDERS_ERROR',
    'GET_ORDER_SUCCESS': 'GET_ORDER_SUCCESS',
    'GET_ORDER_ERROR': 'GET_ORDER_ERROR',
};

// Réducteur
// ---------
const defaultState = { current: {}, history: [] }

export function orders(state = defaultState, action) {
  switch (action.type) {
    case 'CREATE_ORDER_SUCCESS':{
        const order = action.payload

        return {
            ...state,
            current: order
        }
    }
    case 'GET_ORDERS_SUCCESS':{
        const orders = action.payload
  
        return {
            ...state,
            history: orders
        }
    }
    case 'GET_ORDER_SUCCESS':{
        const items = state.history.items
        const order = action.payload
        const updatedItems = [ ...items ]
        
        if(updatedItems && updatedItems.length > 0){
            const index = updatedItems.findIndex(item => item.idOrder === order.idOrder)
            updatedItems[index] = order
        } else {
            updatedItems.push(order)
        }

        return {
            ...state,
            history: {
                ...state.history,
                items:[
                    ...updatedItems
                ]
            }
        }
    }
    case 'RESET_ORDER':{
        return {
            ...state,
            current: {}
        }
    }
    default:
        return state
  }
}

// Action Creators
// ---------------
export function createOrder(order){
    return async (dispatch, getState) => {
        const { shop : { book }, Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(customer){
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)
                
                const response = await axios.post(`/orders/`, { ...order })
                const orderResponse = response.data
                dispatch({ type: constants.CREATE_ORDER_SUCCESS, payload: orderResponse })
                history.push(`/shop/book/${book.key}/payment/confirmation`)
                dispatch(resetCart())
            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
                dispatch({ type: constants.CREATE_ORDER_ERROR })
                history.push(`/shop/book/${book.key}/cart`)
            }
        }
    }
}

export function resetOrder(){
    return dispatch => {
        dispatch({ type: constants.RESET_ORDER })
    }
}

export function getOrders(){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(customer){
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)

                const response = await axios.get(`/orders/`)
                const data = response.data
                dispatch({ type: constants.GET_ORDERS_SUCCESS, payload: data })
            } catch (error) {
                dispatch({ type: constants.GET_ORDERS_ERROR })
            }
        }
    }
}

export function getOrder(idOrder){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const customer = JSON.parse(localStorage.getItem('customer'))

        if(customer){
            try {
                setAuthHeader(customer.token)
                setLocaleHeader(locale)

                const response = await axios.get(`/orders/${idOrder}`)
                const data = response.data
                dispatch({ type: constants.GET_ORDER_SUCCESS, payload: data })
            } catch (error) {
                dispatch({ type: constants.GET_ORDER_ERROR })
                const { shop : { book } } = getState()
                history.push(`/shop/book/${book.key}/customer/orders`)
            }
        }
    }
}