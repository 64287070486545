import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink
} from "shards-react";
import { func, object } from 'prop-types'

import React from "react";
import { NavLink as RouteNavLink } from "react-router-dom";
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { toggleSidebarDropdown } from '../../../reducers/app/userInterface.reducer'
import { withTranslate } from 'react-redux-multilingual'


class SidebarNavItems extends React.Component {

  static propTypes = {
    dispatch: func.isRequired,
    navItems: object.isRequired
  }

  // Autobind arrow func
  toggleDropdown = (item) => {
    this.props.dispatch(toggleSidebarDropdown(item))
  }

  render() {
    const { translate, navItems : { items }, handleToggleSidebar } = this.props

    return (
      <div className="nav-wrapper">
        {items.map((item, idx) => {
            const hasSubItems = item.items && item.items.length;

            return (
              <div key={idx}>
                <Nav className="nav--no-borders flex-column">
                  <NavItem style={{ position: "relative" }}>
                    <NavLink
                      className={hasSubItems && "dropdown-toggle"}
                      tag={hasSubItems ? "a" : RouteNavLink}
                      to={hasSubItems ? "#" : item.to}
                      onClick={() => this.toggleDropdown(item)}
                    >
                      {item.htmlBefore && (
                        <div
                          className="d-inline-block item-icon-wrapper"
                          dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                        />
                      )}
                      {item.translate && <span>{translate(item.translate)}</span>}
                      {item.htmlAfter && (
                        <div
                          className="d-inline-block item-icon-wrapper"
                          dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                        />
                      )}
                    </NavLink>

                    {hasSubItems && (
                      <Collapse tag={DropdownMenu} small open={item.open} style={{ top: 0 }}>
                        {item.items.map((subItem, idx) => (
                          <DropdownItem key={idx} tag={RouteNavLink} exact onClick={handleToggleSidebar} to={subItem.to} active={false}>
                            {translate(subItem.translate)}
                          </DropdownItem>
                        ))}
                      </Collapse>
                    )}
                  </NavItem>
                </Nav>
              </div>
            )
          }
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ userInterface : { navItems } }) => {
  return { navItems }
}

export default hot(connect(mapStateToProps)(withTranslate(SidebarNavItems)))
