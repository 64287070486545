import { Button, Col, Container, Row } from "shards-react"
import ContactForm from '../../components/forms/ContactForm'
import React from "react"
import { hot } from 'react-hot-loader/root'
import iconDev from '../../images/undraw_active_options_8je6.svg'
import iconTown from '../../images/undraw_quite_town_mg2q.svg'
import iconTeam from '../../images/undraw_online_collaboration_7pfp.svg'
import iconBusinessShop from '../../images/undraw_business_shop_qw5t.svg'
import logo from '../../images/logo_couleur.png'
import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'


/* eslint jsx-a11y/anchor-is-valid: 0 */

class Home extends React.Component {
  render() {
    const { translate } = this.props

    return (
      <React.Fragment>
        <section className="intro">
          <Row noGutters className="no-gutter justify-content-center">
            <Col md="6" lg="5" className="text-center">
              <img crossOrigin="anonymous" src={logo} alt="logo" style={{width:'170px'}} />
              <h1 className="main-title mt-4 font-weight-bold">{translate('slogan')}<br/>{translate('sloganb')}<br/>{translate('sloganc')}</h1>
              <p className="font-size-lg mt-5">{translate('slogan2')}<br/>{translate('slogan2b')}</p>
              <Button pill tag={HashLink} to={`/#choose-pack`} className="mt-4 py-3 px-4 font-size-md">{translate('prices')}</Button>&nbsp;
              {/* <Button pill tag='a' href="https://www.youtube.com/watch?v=lo8ceSvRGvs" target="_blank" className="btn-primary mt-4 py-3 px-4 font-size-md">{translate('video')}</Button> */}
            </Col>
          </Row>
        </section>
        <section className="why-use-it py-5 bg-primary">
          <Container className="py-5">
            <Row noGutters className="h-100 justify-content-center">
              <Col md="8" lg="12" className="text-center">
                <h2 className="font-family-montserrat text-white font-weight-bold">{translate('tools')}</h2>
                <p className="mt-5 font-size-lg text-white">{translate('enrichYourPdfs')}</p>
                <Row className="text-center">
                  <Col lg="6">
                    <div className="mt-4">
                      <h3 className="font-weight-bold text-white">{translate('mainTitleFeature')}</h3>
                      <p className="text-white">{translate('featuresTeaser1')}</p>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mt-4">
                      <h3 className="font-weight-bold text-white">{translate('mainTitleFeature2')}</h3>
                      <p className="text-white">{translate('featuresTeaser2')}</p>
                    </div>
                  </Col>
                </Row>
                <h2 className="font-family-montserrat text-white font-weight-bold mt-5">{translate('features')}</h2>
                <Row className="text-center">
                  <Col lg="6">
                    <div className="mt-4">
                      <div className="text-white py-1">{translate('titleFeature1')}</div>
                      <div className="text-white py-1">{translate('titleFeature2')}</div>
                      <div className="text-white py-1">{translate('titleFeature4')}</div>
                      <div className="text-white py-1">{translate('titleFeature6')}</div>
                      <div className="text-white py-1">{translate('titleFeature7')}</div>
                      <div className="text-white py-1">{translate('titleFeature8')}</div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mt-4">
                      <div className="text-white py-1">{translate('titleFeature10')}</div>
                      <div className="text-white py-1">{translate('titleFeature9')}</div>
                      <div className="text-white py-1">{translate('titleFeature11')}</div>
                      <div className="text-white py-1">{translate('titleFeature12')}</div>
                      <div className="text-white py-1">{translate('titleFeature13')}</div>
                      <div className="text-white py-1">{translate('titleFeature14')}</div>
                    </div>
                  </Col>
                </Row>
                <Row className="text-center justify-content-center mt-5">
                  <Col md="6">
                    <iframe className="mt-3" width="100%" height="315" src="https://www.youtube.com/embed/G55fhl1EmzU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="video"></iframe>
                  </Col>
                </Row>
                {/* <Row noGutters className="no-gutter justify-content-center mt-3">
                  <Col lg="4">
                    <Button pill tag={Link} to={`/frequently-asked-questions`} className="btn-info mt-4 py-3 px-4 font-size-md">{translate('frequentlyAskedQuestions')}</Button>&nbsp;
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </Container>
        </section>
        <section id="choose-pack" className="choose-pack bg-info">
          <div className="bg-image py-5">
            <Container className="py-5">
              <Row noGutters className="h-100 justify-content-center">
                <Col md="8" lg="12" className="text-center">
                  <h2 className="font-family-montserrat text-white font-weight-bold">{translate('choosePackage')}</h2>
                  <p className="mt-4 font-size-lg text-primary">{translate('ourOffers')}<br/>{translate('creditsAreAvailable')}</p>
                  
                  <Row className="mt-5">
                    <Col lg="4">
                      <div className="box d-flex flex-column justify-content-center">
                        <div className="recto">
                          <h4 className="text-white font-weight-bold">{translate('package1Title')}<br/>{translate('package1TitleBis')}</h4>
                          <span className="divider bg-primary"></span>
                          <p className="text-primary font-size-lg">{translate('package1Baseline')}<br/>{translate('package1BaselineBis')}</p>
                          <span className="divider bg-primary"></span>
                          <div className="text-white font-weight-bold font-size-lg line-height-1">{translate('package1price')}<small className="d-block mt-2 font-size-sm"><i>{translate('package1priceBis')}</i></small></div>
                        </div>
                        <div className="verso">
                            <div className="header">
                              <h4 className="title ">{translate('package1Title')}<br/>{translate('package1TitleBis')}</h4>
                            </div>
                            <div className="body p-4">
                              <p className="font-weight-bold">{translate('package1Arg1')}</p>
                              <p className="font-weight-bold">{translate('package1Arg2')}</p>
                              <p className="font-weight-bold">{translate('package1Arg3')}</p>
                              <Button tag={Link} to={`/app/register`} className="btn-primary mt-2">{translate('subscribeToTheOffer')}</Button>
                            </div>
                            <div className="footer">
                                <div className="price"></div>
                            </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="box d-flex flex-column justify-content-center">
                        <div className="recto">
                          <h4 className="text-white font-weight-bold">{translate('package2title')}<br/>{translate('package2titleBis')}</h4>
                          <span className="divider bg-primary"></span>
                          <p className="text-primary font-size-lg">{translate('package2Baseline')}<br/>{translate('package2BaselineBis')}</p>
                          <span className="divider bg-primary"></span>
                          <div className="text-white font-weight-bold font-size-lg line-height-1">{translate('package2price')}<small className="d-block mt-2 font-size-sm"><i>{translate('package2priceBis')}</i></small></div>
                        </div>
                        <div className="verso">
                            <div className="header">
                              <h4 className="title">{translate('package2title')}<br/>{translate('package2titleBis')}</h4>
                            </div>
                            <div className="body py-4 px-5">
                              <p className="font-weight-bold">{translate('package2Arg1')}</p>
                              <p className="font-weight-bold">{translate('package2Arg2')}</p>
                              <p className="font-weight-bold">{translate('package2Arg3')}</p>
                              <Button tag={Link} to={`/app/register`} className="btn-primary mt-1">{translate('subscribeToTheOffer')}</Button>
                            </div>
                            <div className="footer mt-3">
                                <div className="price"></div>
                            </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="box d-flex flex-column justify-content-center">
                        <div className="recto">
                          <h4 className="text-white font-weight-bold">{translate('package3title')}<br/>{translate('package3titleBis')}</h4>
                          <span className="divider bg-primary"></span>
                          <p className="text-primary font-size-lg">{translate('package3baseline')}<br/>{translate('package3baselineBis')}</p>
                          <span className="divider bg-primary"></span>
                          <div className="text-white font-weight-bold font-size-lg line-height-1">{translate('contactUs')}</div>
                        </div>
                        <div className="verso">
                          <div className="header">
                            <h4 className="title">{translate('package3title')}<br/>{translate('package3titleBis')}</h4>
                          </div>
                          <div className="body p-4">
                            <p className="font-weight-bold">{translate('package3Arg1')}</p>
                            <p className="font-weight-bold">{translate('package3Arg2')}</p>
                            <p className="font-weight-bold">{translate('package3Arg3')}</p>

                            <Button tag={HashLink} smooth to={`/#contact`} className="btn-primary mt-1">{translate('contactUs')}</Button>
                          </div>
                          <div className="footer mt-4">
                              <div className="price"></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section className="who-can-use bg-white py-5">
          <Container className="py-5">
            <Row noGutters className="h-100 justify-content-center">
              <Col md="8" lg="12" className="text-center">
                <h2 className="font-family-montserrat text-info"><span className="font-weight-bold">{translate('who')}</span> Module-market{translate('space')}?</h2>
                <Row>
                  <Col lg="3 pl-3">
                    <img crossOrigin="anonymous" src={iconDev} className="icon mt-5 mb-5" alt="Icon"/>
                    <h4 className="text-info font-weight-bold">{translate('salers')}</h4>
                    <p className="text-info">{translate('toHelpYouToDevelop')}</p>
                  </Col>
                  <Col lg="3">
                    <img crossOrigin="anonymous" src={iconTeam} className="icon mt-5 mb-5" alt="Icon"/>
                    <h4 className="text-info font-weight-bold">{translate('companies')}</h4>
                    <p className="text-info">{translate('soYourProduct')}</p>
                  </Col>
                  <Col lg="3">
                    <img crossOrigin="anonymous" src={iconBusinessShop} className="icon mt-5 mb-5" alt="Icon"/>
                    <h4 className="text-info font-weight-bold">{translate('traders')}</h4>
                    <p className="text-info">{translate('brandTurnover')}</p>
                  </Col>
                  <Col lg="3">
                    <img crossOrigin="anonymous" src={iconTown} className="icon mt-5 mb-5" alt="Icon"/>
                    <h4 className="text-info font-weight-bold">{translate('tourismCulture')}</h4>
                    <p className="text-info">{translate('toPresentAndOffer')}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="how-use-it py-5 bg-primary text-white">
          <Container className="py-5">
            <Row noGutters className="h-100 justify-content-center">
              <Col md="8" lg="12" className="text-center">
                <h2 className="font-family-montserrat text-white"><span className="font-weight-bold">{translate('howItWorks')}</span> Module-market{translate('space')}?</h2>
                <p className="mt-5 font-size-lg">{translate('ourApplication')}<br/>{translate('ourApplication2')}</p>
                <Row className="mt-5 justify-content-center text-justify">
                  <Col lg="5" className="px-5">
                    <h4 className="py-2 bg-white text-primary rounded text-center w-75 m-auto">{translate('sellerSide')}</h4>
                    <ul className="list-unstyled mt-5 px-2 ml-5">
                      <li>
                        <p className="font-weight-bold font-size-md position-relative ml-5"><span className="bullet bg-white text-primary">1</span> {translate('createAccount2')}</p>
                      </li>
                      <li>
                        <p className="font-weight-bold font-size-md mt-5 position-relative ml-5"><span className="bullet bg-white text-primary">2</span> {translate('uploadYourPdf')}</p>
                      </li>
                      <li>
                        <p className="font-weight-bold font-size-md mt-5 position-relative ml-5"><span className="bullet bg-white text-primary">3</span> {translate('importYourProduct')}</p>
                      </li>
                      <li>
                        <p className="font-weight-bold font-size-md mt-5 position-relative ml-5"><span className="bullet bg-white text-primary">4</span> {translate('sell')}</p>
                      </li>
                    </ul>
                  </Col>
                  <Col lg="5" className="px-5">
                    <h4 className="py-2 bg-white text-primary rounded text-center w-75 m-auto">{translate('customerSide')}</h4>
                    <ul className="list-unstyled mt-5 ml-5">
                      <li>
                        <p className="font-weight-bold font-size-md mt-5 position-relative ml-5"><span className="bullet bg-white text-primary">1</span> {translate('goToStore')}</p>
                      </li>
                      <li>
                        <p className="font-weight-bold font-size-md mt-5 position-relative ml-5"><span className="bullet bg-white text-primary">2</span> {translate('fillYourBasket')}</p>
                      </li>
                      <li>
                        <p className="font-weight-bold font-size-md mt-5 position-relative ml-5"><span className="bullet bg-white text-primary">3</span> {translate('createAccount2')}</p>
                      </li>
                      <li>
                        <p className="font-weight-bold font-size-md mt-5 position-relative ml-5"><span className="bullet bg-white text-primary">4</span> {translate('confirmPurchase')}</p>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row noGutters className="no-gutter justify-content-center">
                  <Col lg="4" className="mt-4">
                    <Button pill tag={Link} to={`/frequently-asked-questions`} className="bg-white text-primary mt-4 py-3 px-4 font-size-md">{translate('frequentlyAskedQuestions')}</Button>&nbsp;
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section> */}
        <section className="contact py-5" id="contact">
          <Container className="py-5">
            <Row noGutters className="h-100 justify-content-center">
              <Col md="8" lg="12" className="text-center">
                <h2 className="font-family-montserrat text-primary"><span className="font-weight-bold">{translate('youHave')}</span> {translate('aQuestion')}</h2>
                <p className="mt-5 font-size-lg text-primary">{translate('notHesitate')}</p>
                <Row className="justify-content-center text-left mt-5">
                  <Col lg="6">
                      <ContactForm/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    )
  }
}

export default hot(connect()(withTranslate(Home)))
