import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink
} from "shards-react"
import { func, object } from 'prop-types'

import { Link } from "react-router-dom"
import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { logout } from '../../../../reducers/app/user.reducer'
import { withTranslate } from 'react-redux-multilingual'


class UserActions extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    user: object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }
  }

  toggleUserActions = () => {
    this.setState({
      visible: !this.state.visible
    })
  }

  // Autobind with arrow func
  handleClick = (e) => {
    e.preventDefault()

    this.props.dispatch(logout())
  }

  render() {
    const { translate } = this.props
    const { firstname, lastname } = this.props.user
    const firstLetterFirstname = firstname.charAt(0)
    const firstLetterLastname = lastname.charAt(0)
    return (
      <NavItem tag={Dropdown} toggle={this.toggleUserActions} className="user-actions cursor-pointer py-2">
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-md-4">
          <span className="user-avatar rounded-circle mr-2">{firstLetterFirstname}{firstLetterLastname}</span>
          <span className="d-none d-md-inline-block">{firstname} {lastname}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="/app/profil">
            <i className="material-icons">&#xE7FD;</i> {translate('profil')}
          </DropdownItem>
          <DropdownItem tag={Link} to="/app/offers">
            <i className="material-icons">local_offer</i> {translate('packages')}
          </DropdownItem>
          <DropdownItem tag={Link} to="/app/shop">
            <i className="material-icons">miscellaneous_services</i> {translate('settings')}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this.handleClick} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> {translate('logout')}
          </DropdownItem>
        </Collapse>
      </NavItem>
    )
  }
}

const mapStateToProps = ( { user } ) => { 
  return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(UserActions)))