import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    FormInput,
    InputGroup
} from "shards-react"

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'
import { udapteUser } from '../../reducers/app/user.reducer'

class CollectionShops extends React.Component {
  
    constructor(props){
        super(props)

        this.state = {
            collectionShops: {
                addresses:[]
            },
            errors:[]
        }
    }

    componentDidMount(){
        const { collectionShops } = this.props.user

        if(collectionShops && collectionShops.hasOwnProperty('addresses')){
            this.setState({ collectionShops })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        
        const { collectionShops } = this.state

        this.props.dispatch(udapteUser({ collectionShops }))
    }

    addAddress = () => {
        const { collectionShops } = this.state
        const copyCollectionShops = { ...collectionShops }
        let newAddress

        newAddress = {
            address: "",
            postalCode: "",
            city: "",
            phone: "",
        }

        copyCollectionShops.addresses.push(newAddress)
        this.setState({ collectionShops: copyCollectionShops }, () => {
            this.checkAddresses()
        })
    }

    editAddress = (e,index) => {
        const { name, value } = e.target
        const { addresses } = this.state.collectionShops
        const newAddresses = [...addresses]
        const address = { ...newAddresses[index] }

        address[name] = value
        newAddresses[index] = address

        this.setState({
            ...this.state,
            collectionShops:{
                ...this.state.collectionShops,
                addresses: newAddresses
            }
        }, () => {
            this.checkAddresses()
        })  
    }

    deleteAddress =  (index) => {
        const { addresses } = this.state.collectionShops
        const copyAddresses = [ ...addresses ]

        // Remove specific address
        copyAddresses.splice(index, 1)

        this.setState({
            ...this.state,
            collectionShops:{
                ...this.state.collectionShops,
                addresses: copyAddresses
            }
        }, () => {
            this.checkAddresses()
        })
    }

    checkAddresses = () => {
        const { addresses } = this.state.collectionShops
        const errors = []
        
        addresses.map((address, index) => {
            const obj = {}

            // Check fields
            if(address.address.length <= 0){
                obj.address = 'requiredField'
            }
            if(address.postalCode.length <= 0){
                obj.postalCode = 'requiredField'
            }
            if(address.city.length <= 0){
                obj.city = 'requiredField'
            }
            if(address.phone.length <= 0){
                obj.phone = 'requiredField'
            }
            
            if(obj.hasOwnProperty('address') || obj.hasOwnProperty('city') || obj.hasOwnProperty('city') || obj.hasOwnProperty('phone'))
                errors[index] = obj

            return null
        })

        this.setState({ errors })
    }

    render(){
        const { translate } = this.props
        const { collectionShops, errors } = this.state
        const collectionShopsIsEmpty = collectionShops.addresses.length === 0
        const errorsIsEmpty = errors.length === 0

        return (
        <React.Fragment>
            <Form onSubmit={this.handleSubmit}>
                <Card small className='mb-4'>
                    <CardHeader className="border-bottom">
                            <Row>
                                <Col>
                                    <h6 className="m-0">{translate('collectionShops')} <i>"{translate('collectedOnSite')}"</i></h6>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Button onClick={this.addAddress} theme="white" size="sm">{translate('addShop')}</Button>
                                </Col>
                            </Row>
                    </CardHeader>
                    <CardBody className={collectionShopsIsEmpty ? '' : 'p-0'}>
                        <Container fluid className="px-0">
                            {!collectionShopsIsEmpty && <div className="table-responsive">
                                <table className="table no-border mb-0">
                                    <thead className="py-2 bg-light text-semibold">
                                    <tr>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('address')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('postalCode')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('city')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('phone')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {collectionShops.addresses.map((address,index) => {
                                            return <tr key={index}>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="address" className="text-right" value={address.address}
                                                        onChange={(e) => this.editAddress(e,index)} />
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('address') &&
                                                        <small className="text-danger">{translate(errors[index].address)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="postalCode" className="text-right" value={address.postalCode} onChange={(e) => this.editAddress(e,index)} />
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('postalCode') &&
                                                        <small className="text-danger">{translate(errors[index].postalCode)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="city" className="text-right" value={address.city} onChange={(e) => this.editAddress(e,index)} />
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('city') &&
                                                        <small className="text-danger">{translate(errors[index].city)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="phone" className="text-right" value={address.phone} onChange={(e) => this.editAddress(e,index)} />
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('phone') &&
                                                        <small className="text-danger">{translate(errors[index].phone)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    <Button onClick={()=> this.deleteAddress(index)} theme="white"><i className="material-icons">delete</i></Button>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                            {collectionShopsIsEmpty && <p className="mb-0">{translate('noAddressDefined')}</p>}
                        </Container>
                    </CardBody>
                    <CardFooter className="border-top">
                        <Row>
                            <Col className="">
                                <Button type="submit" size="sm" theme="primary" className="mr-2 float-right" disabled={(!errorsIsEmpty)}>{translate('save')}</Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Form>
        </React.Fragment>
        )
    }
}
  
const mapStateToProps = ( { user } ) => { 
    return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(CollectionShops)))
  