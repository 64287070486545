import { bool, func } from 'prop-types'

import { Col } from "shards-react";
import React from "react";
import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";
import classNames from "classnames";
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { toggleSidebar } from '../../../reducers/app/userInterface.reducer'


class MainSidebar extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    hideLogoText: bool.isRequired,
    menuVisible: bool.isRequired
  }

  static defaultProps = {
    hideLogoText: false
  }

  // Autobind with arrow func
  handleToggleSidebar = () => {
    this.props.dispatch(toggleSidebar())
  }

  render() {
    const { menuVisible } = this.props

    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      menuVisible && "open"
    );

    return (
      <Col
        tag="aside"
        className={classes}
        md={{ size: 2 }}
      >
        <SidebarMainNavbar handleToggleSidebar={this.handleToggleSidebar} hideLogoText={this.props.hideLogoText} />
        <SidebarNavItems handleToggleSidebar={this.handleToggleSidebar} />
      </Col>
    );
  }
}

const mapStateToProps = ({ userInterface : { menuVisible } }) => {
  return { menuVisible }
}

export default hot(connect(mapStateToProps)(MainSidebar));
