import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row
} from "shards-react"

import { Link } from 'react-router-dom'
//import FuzzySearch from "fuzzy-search"
import PageTitle from "../../components/common/PageTitle"
//import RangeDatePicker from "../../components/common/RangeDatePicker"
import React from "react"
import ReactTable from "react-table"
import dateFormat from "dateformat"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { getOrders, updateOrder } from '../../reducers/app/orders.reducer'
import { getStatus } from '../../utils/status'
import { history } from '../../utils/history'
import { withTranslate } from 'react-redux-multilingual'



class Orders extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 20,
      tableData: []
    }

    this.searcher = null
  }

  componentDidMount() {
    this.props.dispatch(getOrders())

    // Initialize the fuzzy searcher.
    /* this.searcher = new FuzzySearch(tableData, ["customer", "status"], {
      caseSensitive: false
    }); */
  }

  /**
   * Handles the page size change event.
   */
  handlePageSizeChange = (e) => {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch = (e) => {
    this.setState({
      ...this.state,
      tableData: this.searcher.search(e.target.value)
    });
  }

  handleItemConfirm = (row) => {
    this.props.dispatch(updateOrder(row.original.idOrder, { status : 2 }))
  }

  handlePageChange = (pageIndex) => {
    const { pathname } = history.location
    const numPage = pageIndex + 1

    history.push({
      pathname: pathname,
      search:`?numPage=${numPage}`
    })
  }

  render() {
    const { orders: { items }, translate } = this.props
    const { pageSize } = this.state
    const { search } = history.location
    let numPage = new URLSearchParams(search.substring(1)).get("numPage")
    numPage = parseInt(numPage) ? parseInt(numPage) - 1 : 0

    const tableColumns = [
      {
        Header: translate('ref'),
        accessor: "ref",
        minWidth: 130,
        maxWidth: 200,
        className: "text-center",
        sortable: false,
        Cell: row => `#${row.original.ref}`
      },
      {
        Header: translate('date'),
        accessor: "date",
        className: "text-center",
        minWidth: 180,
        Cell: row => dateFormat(new Date(row.original.createdAt), "dd/mm/yyyy HH:MM:ss")
      },
      {
        Header: translate('customers'),
        accessor: "customer",
        className: "text-center",
        maxWidth: 350,
        minWidth: 220,
        Cell: row => `${row.original.customer.firstname} ${row.original.customer.lastname}`
      },
      {
        Header: translate('products'),
        accessor: "products",
        maxWidth: 120,
        minWidth: 80,
        className: "text-center",
        Cell: row => row.original.totalProductsQuantity
      },
      {
        Header: translate('status'),
        accessor: "status",
        minWidth: 100,
        Cell: row => {
          const status = getStatus(row.original.status)

          return (
            <span className={`text-${status.color}`}>
              {translate(status.name)}
            </span>
          )
        },
        className: "text-center"
      },
      {
        Header: translate('total'),
        accessor: "total",
        minWidth: 100,
        Cell: row => <span className="text-success">{row.original.totalPriceInclTax} €</span>,
        className: "text-center"
      },
      {
        Header: translate('transaction'),
        accessor: "transactionBy",
        minWidth: 100,
        Cell: row => translate(row.original.transactionBy),
        className: "text-center"
      },
      {
        Header: translate('actions'),
        accessor: "actions",
        maxWidth: 200,
        minWidth: 100,
        sortable: false,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button theme="white" tag={Link} to={`/app/order/${row.original.idOrder}`}>
              <i className="material-icons">&#xE254;</i>
            </Button>
            <Button theme="white" onClick={() => this.handleItemConfirm(row)}>
              <i className="material-icons">&#xE5CA;</i>
            </Button>
          </ButtonGroup>
        )
      }
    ];

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={translate('allOrders')} subtitle={translate('orders')} className="text-sm-left mb-3" />
          {/* <Col sm="4" className="d-flex ml-auto my-auto">
            <RangeDatePicker className="justify-content-end" />
          </Col> */}
        </Row>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  {/* <span>Show</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect> */}
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex py-2" md="6">
                  {/* <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup> */}
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={items}
                pageSize={pageSize}
                resizable={false}
                showPageSizeOptions={false}
                previousText={translate('prev')}
                nextText={translate('next')}
                ofText="/"
                rowsText={translate('lines')}
                noDataText={translate('noOrderFound')}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageChange}
                page={numPage}
                /* pages={ceil(totalOrders / pageSize)} */
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = ({ orders }) => {
  return { orders }
}

export default hot(connect(mapStateToProps)(withTranslate(Orders)))
