import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, Row } from "shards-react"

import { Link } from "react-router-dom"
import React from "react"
import { connect } from 'react-redux'
import { history } from '../../utils/history';
import { hot } from 'react-hot-loader/root'
import { resetOrder } from '../../reducers/shop/orders.reducer'
import { withTranslate } from 'react-redux-multilingual'


class Confirmation extends React.Component{

    componentDidMount() {
        const { orders,  match: { params : { key } } } = this.props

        if(!orders.current.ref){
            history.push(`/shop/book/${key}/cart`)
        }
    }

    render(){
        const { orders, match: { params : { key } }, translate } = this.props

        return (
            <Container fluid className="main-content-container bg-dark">
            <Row className="page-header justify-content-center py-5">
                <Col xl="7" md="11">
                    <Form>
                        <Card>
                            <CardHeader className="border-bottom bg-primary text-white">{translate('orderConfirmation')} n°{orders.current.ref}</CardHeader>
                            <CardBody>
                                <CardTitle>{translate('thanksForOrder')}</CardTitle>
                                <p className="mb-4">{translate('weContactUs')}</p>
                            </CardBody>
                            <CardFooter className="border-top bg-light text-center">
                                {key && <Button tag={Link} theme="primary" to={`/shop/book/${key}`}>{translate('returnToShop')}</Button>}
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
            </Row>
            </Container>
        )
    }

    componentWillUnmount(){
        this.props.dispatch(resetOrder())
    }
}

const mapStateToProps = ({ shop : { orders } } ) => {
    return { orders }
}

export default hot(connect(mapStateToProps)(withTranslate(Confirmation)))
