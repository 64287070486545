import "react-quill/dist/quill.snow.css"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormSelect,
  FormInput,
  ListGroup,
  ListGroupItem,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormCheckbox,
  ButtonGroup
} from "shards-react";
import { func, object } from 'prop-types'
import { getBook, updateBook, createBookFromProductList } from '../../reducers/app/books.reducer'
import ReactQuill from "react-quill"
import Area from '../../components/app/book/Area'
import DropzoneArea from '../../components/app/book/DropzoneArea'
import NoCredit from '../../components/app/book/NoCredit'
import ChoiceTypeBook from '../../components/app/book/ChoiceTypeBook'
import PageTitle from "../../components/common/PageTitle"
import { Prompt } from 'react-router'
import React from "react"
import { connect } from 'react-redux'
import { getProducts } from '../../reducers/app/products.reducer'
import { hot } from 'react-hot-loader/root'
import interact from 'interactjs'
import reactable from 'reactablejs'
import { withTranslate } from 'react-redux-multilingual'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import arrayMove from 'array-move'


const Reactable = reactable(Area)


class BookDetail extends React.Component {

    static propTypes = {
        dispatch: func.isRequired,
        books: object.isRequired,
        userInterface: object.isRequired
    }

    constructor(props){
        super(props)

        this.restrictArea = React.createRef();

        this.state = {
            idBook: 0,
            loaded: false,
            submitted: false,
            activeEshop: false,
            terms: '',
            openTermsModal: false,
            idShippingCosts: 0,
            type: 0,
            displayAs: 0
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    handleChangeType = async (type) => {
        this.setState({ type })

        if(type === 2){
            await this.props.dispatch(createBookFromProductList())
            this.setState({ loaded: true })
        }
    }

    handleChangeDisplay = (e) => {
        this.setState({ displayAs: e.target.value })
    } 

    handleSubmit = (e) => {
        e.preventDefault()

        this.setState({ submitted: true })
        const { idBook, ref, title, pages, activeEshop, terms, idShippingCosts, displayAs } = this.state

        if(title && title.length > 1 && ref && ref.length > 1 && idBook > 0 && pages.length > 0){
            this.props.dispatch(updateBook(idBook, ref, title, pages, activeEshop, terms, idShippingCosts, displayAs))
        }
    }

    goToPage = (directionOrNumPage) => {
        const { activePage, totalPages } = this.state

        if(directionOrNumPage === 'prev'){
            this.setState({ activePage: activePage > 1 ? activePage - 1 : totalPages })
        } else if(directionOrNumPage === 'next'){
            this.setState({ activePage: activePage < totalPages ? activePage + 1 : 1 })
        } else {
            this.setState({ activePage: directionOrNumPage + 1 })
        }
    }

    createArea = (indexPage) => {
        this.setState(prev => {
            const { pages } = prev

            pages[indexPage].areas.push({ width:10, height:10, y:0, x:0, type: 'product', value: ''})

            return {
                ...prev,
                pages
            }
        })
    }

    deleteArea = (indexPage, indexArea) => {
        this.setState(prev => {
            const { pages } = prev

            pages[indexPage].areas.splice(indexArea, 1)

            return {
                ...prev,
                pages
            }
        })
    }

    updateArea = (event, indexPage, indexArea) => {
        const { name, value } = event.target

        this.setState(prev => {
            const { pages } = prev

            if(name === 'type'){
                pages[indexPage].areas[indexArea].value = ''
            }
            pages[indexPage].areas[indexArea][name] = value

            return {
                ...prev,
                pages
            }
        })
    }

    resetArea = (indexPage, indexArea) => {
        this.setState(prev => {
            const { pages } = prev
            pages[indexPage].areas[indexArea].value = ''

            return {
                ...prev,
                pages
            }
        })
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        const { pages } = this.state
        const newPages = [ ...pages ]
        const newAreas = newPages[0].areas
        
        newPages[0].areas = arrayMove(newAreas, oldIndex, newIndex)

        this.setState({ pages: newPages })
    }

    updateCoordinatesArea = (event, indexArea, idBook, indexPage) => {

        const { pageWidth, pageHeight } = this.state

        if(event.type === 'dragmove'){
            this.setState(prev => {
                const { pages } = prev

                pages[indexPage].areas[indexArea].x = (pages[indexPage].areas[indexArea].x + ((event.dx * 100) / pageWidth))
                pages[indexPage].areas[indexArea].y = (pages[indexPage].areas[indexArea].y + ((event.dy * 100) / pageWidth))


                return {
                    ...prev,
                    pages
                }
            })
        } else if(event.type === 'resizemove'){
            const { width, height } = event.rect
            const { left, top } = event.deltaRect

            this.setState(prev => {
                const { pages } = prev
                pages[indexPage].areas[indexArea].x = (pages[indexPage].areas[indexArea].x + ((left * 100) / pageWidth))
                pages[indexPage].areas[indexArea].y = (pages[indexPage].areas[indexArea].y + ((top * 100) / pageWidth))
                pages[indexPage].areas[indexArea].width = ((width * 100) / pageWidth)
                pages[indexPage].areas[indexArea].height = ((height * 100) / pageHeight)

                return {
                    ...prev,
                    pages
                }
            })
        }
    }

    replaceProduct = () => {

    }

    resize = () => {
        const restrictArea = this.restrictArea.current

        if(restrictArea){
            this.setPageSize(restrictArea)
        }
    }

    setPageSize = (element) => {

        if(element){
            const { ratioPage } = this.state

            const width = element.offsetWidth
            const height = width * ratioPage

            this.setState(state => {
                const nextState = state

                nextState['pageWidth'] = width
                nextState['pageHeight'] = height

                return {
                    ...state,
                    ...nextState
                }
            })
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { idBook } = props.match.params

        if(parseInt(idBook) !== state.idBook){
            const books = props.books
            const book = books[idBook]

            if(book){
                return {
                    loaded: true,
                    ...book
                }
            }
        }

        if(state.loaded &&  !state.pages && props.books[idBook].pages){
            const { idBook } = state
            const book = props.books[idBook]

            return {
                ...state,
                ...book
            }
        }

        return null
    }

    async componentDidMount() {
        const { idBook } = this.state

        if(idBook){
            await this.props.dispatch(getBook(idBook))
            await this.props.dispatch(getProducts())
        }

        window.addEventListener('resize', this.resize)
    }

    componentDidUpdate() {
        const { pages, pageWidth, pageHeight } = this.state
        
        if(pages && !pageWidth && !pageHeight){
            const restrictArea = this.restrictArea.current
            if(restrictArea){
                this.setPageSize(restrictArea)
            }
        }
    }

    toggleActiveEshop = () => {
        this.setState((state)=>({ activeEshop: !state.activeEshop }))
    }

    handleChangeTerms = (text) => {
        this.setState({ terms: text })
    }

    toggleTermsModal = () => {
        this.setState((state)=>({ openTermsModal: !state.openTermsModal }))
    }

    changeIdShippingCosts = (e) => {
        this.setState({ idShippingCosts: e.target.value })
    }

    render(){
        const { submitted, ref, title, terms, activeEshop, openTermsModal, idBook, loaded, pages, activePage, idShippingCosts, type, displayAs } = this.state
        const { products, user, translate } = this.props
        const userHasCredits = Number.isInteger(user.credits) && user.credits > 0

        let currentIndex        

        if(activePage){
            currentIndex = activePage - 1
        }

        return (
            <React.Fragment>
                <Container fluid className="main-content-container px-4 pb-4">
                    {loaded && idBook && type && <Row noGutters className="page-header py-4">
                        <PageTitle sm="12" title={translate('editShop')} subtitle={translate('infos')} className="text-sm-left" />
                    </Row>}
                    {/* Check */}
                    {!userHasCredits && <NoCredit/>}
                    {/* Add book */}
                    {userHasCredits && !loaded && !idBook && type === 0 && <ChoiceTypeBook handleChangeType={this.handleChangeType} />}
                    {userHasCredits && !loaded && !idBook && type === 1 && <DropzoneArea />}
                    {/* Edit book */}
                    {loaded && idBook && type && <React.Fragment><Prompt message={translate('didYouRemember')} />
                    <Row className="justify-content-center">
                        <Col className="order-2" md={9}>
                            {type === 1 && pages && <Card small className="mb-3">
                                <CardHeader className="border-bottom">
                                    <Row className="align-items-center">
                                        <Col className="align-self-center">
                                            <h6 className="text-left m-0">{translate('edit')}</h6>
                                        </Col>
                                        <Col className="align-self-end text-right">
                                            <Button outline onClick={() => this.createArea(activePage - 1)}>{translate('addZone')}</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col className="text-right" xs={1}>
                                            <Button style={{height:"200px",width:"100%"}} className="px-1 d-none d-sm-inline-block"
                                                onClick={()=>{
                                                    this.goToPage('prev', currentIndex)
                                                }}><i className="material-icons font-size-lg">keyboard_arrow_left</i></Button>
                                        </Col>
                                        <Col xs={10} className="px-0">
                                            <div ref={this.restrictArea} style={{ width: '100%', height: '100%', position: 'absolute', overflow: 'hidden' }}>
                                                {Object.values(pages[activePage - 1].areas).map((area, indexArea) => {
                                                    return <Reactable key={indexArea}
                                                        draggable={{
                                                            onmove: (event) =>  {
                                                                this.updateCoordinatesArea(event, indexArea, idBook, this.state.activePage - 1)
                                                            },
                                                            modifiers: [
                                                                interact.modifiers.restrictRect({
                                                                    restriction: 'parent',
                                                                    endOnly: true
                                                                })
                                                            ],
                                                        }}
                                                        resizable={{
                                                            edges: { left: true, right: true, bottom: true, top: true },
                                                            onmove: (event) => {
                                                                this.updateCoordinatesArea(event, indexArea, idBook, this.state.activePage - 1)
                                                            },
                                                            modifiers: [
                                                                interact.modifiers.restrictEdges({
                                                                    outer: 'parent',
                                                                    endOnly: true
                                                                }),
                                                            ]
                                                        }}
                                                        {...area}
                                                        deleteArea={() => this.deleteArea(this.state.activePage - 1, indexArea)}
                                                        updateArea={(e)=>{this.updateArea(e, this.state.activePage - 1, indexArea)}}
                                                        products={products}
                                                    />
                                                })}
                                            </div>
                                            <img crossOrigin="anonymous" style={{width:"100%"}} src={`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/books/${idBook}/${pages[currentIndex].filename}`} alt="Page" />
                                        </Col>
                                        <Col xs={1}>
                                            <Button style={{height:"200px",width:"100%"}} className="px-1 d-none d-sm-inline-block"
                                                onClick={()=>{
                                                    this.goToPage('next', currentIndex)
                                                }}
                                            ><i className="material-icons font-size-lg">keyboard_arrow_right</i></Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>}
                            {type === 2 && <Card small className="mb-3">
                                <CardHeader className="border-bottom">
                                        <Row className="align-items-center">
                                            <Col className="align-self-center">
                                                <h6 className="text-left m-0">{translate('productList')}</h6>
                                            </Col>
                                            <Col className="align-self-end text-right">
                                                <Button outline onClick={() => this.createArea(activePage - 1)}>{translate('addProduct')}</Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        {pages && <SortableList resetArea={this.resetArea} deleteArea={this.deleteArea} updateArea={this.updateArea} axis='xy' items={pages[this.state.activePage - 1].areas} onSortEnd={this.onSortEnd} products={products} translate={translate} />}
                                        {pages && pages[activePage - 1].areas.length === 0 && <Row><Col><p className="text-center my-5">Aucun produit ajouté</p></Col></Row>}
                                        {!pages && <Row><Col><div className="text-center py-3"><div className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div></div></Col></Row>}
                                        
                                    </CardBody>
                            </Card>}
                        </Col>
                        <Col className="order-1" md={3}>
                            <Card small className="mb-3">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">{translate('infos')}</h6>
                                </CardHeader>

                                <ListGroup flush>
                                    <ListGroupItem className="p-3">
                                        <Row>
                                            <Col>
                                                <Form onSubmit={this.handleSubmit}>
                                                    <FormGroup>
                                                        <label htmlFor="ref">{translate('ref')}</label>
                                                        <FormInput name="ref" placeholder="#1" value={ref} onChange={this.handleChange} />
                                                        {submitted && (!ref || ref.length < 2) &&
                                                            <small className="text-danger">{translate('requiredField2minLength')}</small>
                                                        }
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <label htmlFor="title">{translate('title')}</label>
                                                        <FormInput name="title" placeholder="Catalogue produits" value={title} onChange={this.handleChange} />
                                                        {submitted && (!title || title.length < 2) &&
                                                            <small className="text-danger">{translate('requiredField2minLength')}</small>
                                                        }
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <label htmlFor="title">{translate('shipping')}{translate('space')}:</label>
                                                        <FormSelect name="shipping" value={idShippingCosts} onChange={this.changeIdShippingCosts}>
                                                            <option value="0">{translate('collectedOnSite')} ({translate('definedInAccountSettings').toLowerCase()})</option>
                                                            <option value="2">{translate('postalPackage')} ({translate('definedInAccountSettings').toLowerCase()})</option>
                                                            <option value="3">{translate('collectedOnSite')} {translate('or')} {translate('postalPackage').toLowerCase()} ({translate('definedInAccountSettings').toLowerCase()})</option>
                                                            <option value="1">{translate('free')}</option>
                                                            {/*<option value="3">{translate('leaveChoiceUser')} ( {translate('collectedOnSite').toLowerCase()} {translate('or').toLowerCase()} {translate('definedInAccountSettings').toLowerCase()} )</option>*/} 
                                                        </FormSelect>
                                                    </FormGroup>
                                                    {type === 1 && <FormGroup>
                                                    <label htmlFor="display-as">{translate('displayAs')}{translate('space')}:</label>
                                                        <FormSelect name="display-as" value={displayAs} onChange={this.handleChangeDisplay}>
                                                            <option value="1">{translate('reader')}</option>
                                                            <option value="2">{translate('productsList')}</option>
                                                            <option value="3">{translate('leaveChoiceUser')} ( {translate('reader').toLowerCase()} {translate('or').toLowerCase()} {translate('productsList').toLowerCase()} )</option>
                                                        </FormSelect>
                                                    </FormGroup>}
                                                    {user.activeEshop && user.externalPaymentGateway && user.externalPaymentGateway.hasOwnProperty("externalPaymentName") &&
                                                        <div className="bg-light p-2 mb-3 rounded">
                                                            <FormGroup className="mb-0">
                                                                <label>{translate('activeEshop')}</label>
                                                                <FormCheckbox toggle small className="display-inline float-right" onChange={this.toggleActiveEshop} checked={activeEshop}></FormCheckbox>
                                                            </FormGroup>
                                                            {activeEshop && 
                                                                <React.Fragment>
                                                                    <FormGroup className="mt-2 mb-2">
                                                                        <label htmlFor="title">{translate('legalesNotices')}</label>
                                                                        <Button size="sm" className="d-block" theme="white" onClick={this.toggleTermsModal}>{translate('defineTerms')}</Button>
                                                                        <Modal centered open={openTermsModal} size="lg" toggle={this.toggleTermsModal}>
                                                                            <ModalHeader>{translate('legalesNotices')}</ModalHeader>
                                                                            <ModalBody>
                                                                                <ReactQuill className="add-terms__editor" value={terms} onChange={this.handleChangeTerms}  />
                                                                                <Button className="d-block mt-3" onClick={this.toggleTermsModal}>{translate('confirm')}</Button>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </FormGroup>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    }
                                                    <Button type="submit">{translate('save')}</Button>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </Card>
                            {type === 1 && pages && <Card small className="mb-3">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">{translate('pages')}</h6>
                                </CardHeader>

                                <ListGroup flush>
                                <ListGroupItem className="p-3">
                                    <Row className="height-500 overflow-scroll">
                                        {pages.map((page, index)=>{
                                            return (
                                                <Col key={index} xs={6}>
                                                    <img crossOrigin="anonymous" className={`mb-3 cursor-pointer${page.position === currentIndex ? ' selected' : '' }`} style={{width:'100%'}} onClick={(e)=>{this.goToPage(page.position)}} src={`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/books/${idBook}/${page.filename}`} alt="Page" />
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </ListGroupItem>
                                </ListGroup>
                            </Card>}
                        </Col>
                    </Row></React.Fragment>}
                </Container>
            </React.Fragment>
        )
    }
}

const SortableItem = SortableElement(({area, indexArea, updateArea, resetArea, deleteArea, products, translate}) => (<Col className="py-2" md={3}>
    <div className="rounded border p-2 cursor-move">
        {area.value === '' &&
            <FormSelect name="value" className="my-2" value={area.value} onChange={(e)=>{updateArea(e, 0, indexArea)}}>
            <option key="0" value="0">{translate('chooseProduct')}</option>
            {products.items.map((product,index)=>{
                return <option key={index} value={product.idProduct}>{product.ref} - {product.name}</option>
            })}
        </FormSelect>}
        {area.value !== '' && area.type === 'product' && <div>
            {products.items.map((product)=>{
                if(product.idProduct === parseInt(area.value)){
                    return <div className="my-2 fixed-height-small">{product.name}</div>
                } 

                return null
            })}
        </div>}
        <ButtonGroup className="w-100">
            <Button theme="white" className="p-2" onClick={() => resetArea(0, indexArea)}>
                <i className="material-icons">edit</i>
            </Button>
            <Button theme="white" className="p-2" onClick={() => deleteArea(0, indexArea)}>
                <i className="material-icons">delete</i>
            </Button>
        </ButtonGroup>
    </div>
</Col>))

const SortableList = SortableContainer(({items, updateArea, resetArea, deleteArea, products, translate}) => {
  return (
    <Row>
      {items.map((area, index) => (
        <SortableItem key={`item-${index}`} index={index} indexArea={index} area={area} updateArea={updateArea} resetArea={resetArea} deleteArea={deleteArea} products={products} translate={translate} />
      ))}
    </Row>
  )
})



function mapStateToProps( { books, userInterface, products, user } ) {
    return { books, userInterface, products, user }
}

export default hot(connect(mapStateToProps)(withTranslate(BookDetail)))
