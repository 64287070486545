import { Col, Row } from "shards-react"

import Area from './Area'
import React from "react"
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root'

class Page extends React.Component{

    render() {
        const { left, right, idBook } = this.props

        return (
            <Row noGutters>
                {left && <Col xs="12" sm="6">
                    {left && left.areas && left.areas.map((area,idx) => <Area key={idx} area={area}/>)}
                    {left && left.filename && <img crossOrigin="anonymous" src={`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/books/${idBook}/${left.filename}`} width="100%" alt=""/>}
                </Col>}
                {right && <Col xs="12" sm="6">
                    {right && right.areas && right.areas.map((area,idx) => <Area key={idx} area={area}/>)}
                    {right && right.filename && <img crossOrigin="anonymous" src={`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/books/${idBook}/${right.filename}`} width="100%" alt=""/>}
                </Col>}
            </Row> )
    }
}

function mapStateToProps( { shop : { book, customerInterface : { bookSize } } } ) {
    return { book, bookSize }
}
export default hot(connect(mapStateToProps)(Page))
