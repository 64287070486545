export default {
  items: [
    {
      translate: 'pdf',
      htmlBefore: '<i class="material-icons">menu_book</i>',
      open: true,
      items: [
        {
          translate: 'myPdf',
          to: '/app/books',
        },
        {
          translate: 'addPdf',
          to: '/app/book/add',
        }
      ],
    },
    {
      translate: 'products',
      htmlBefore: '<i class="material-icons">storefront</i>',
      items: [
        {
          translate: 'myProducts',
          to: '/app/products',
        },
        {
          translate: 'addProduct',
          to: '/app/product/add',
        },
        {
          translate: 'importProducts',
          to: '/app/products/import',
        }
      ],
    },
    {
      translate: 'orders',
      htmlBefore: '<i class="material-icons">shopping_cart</i>',
      items: [
        {
          translate: 'allOrders',
          to: '/app/orders',
        },
        {
          translate: 'stats',
          to: '/app/orders/statistics',
        }
      ],
    },
    {
      translate: 'customers',
      htmlBefore: '<i class="material-icons">people</i>',
      items: [
        {
          translate: 'allCustomers',
          to: '/app/customers',
        }
      ],
    }
  ]
}
