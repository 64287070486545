import { Col, Container, Row } from "shards-react"

import React from "react"
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


class LegalesNotices extends React.Component {
  render() {
    const { translate } = this.props

    return (
      <React.Fragment>
        <section>
            <Container className="py-5">

            <Row noGutters className="justify-content-center">
                <Col md="12">
                    <h1 className="main-title mt-4 font-weight-bold">{translate('legalesNotices')}</h1>
                    <h3 className="mt-5">{translate('legNotiTitle1')}</h3>
                    <p className="mt-3">{translate('legNotiSentence2')}</p>
                    <p>{translate('legNotiSentence3')}</p>
                    <p>{translate('legNotiSentence4')}</p>

                    <h3 className="mt-5">{translate('legNotiTitle2')}</h3>
                    <p>{translate('legNotiSentence5')}</p>
                    <p>{translate('legNotiSentence6')}</p>
                    <p>{translate('legNotiSentence7')}</p>
                    <p>{translate('legNotiSentence8')}</p>

                    <h3 className="mt-5">{translate('legNotiTitle3')}</h3>
                    <p>{translate('legNotiSentence9')}</p>

                    <h3 className="mt-5">{translate('legNotiTitle4')}</h3>
                    <p>{translate('legNotiSentence10')}</p>

                    <h3 className="mt-5">
                    {translate('legNotiTitle5')}
                    </h3>
                    <p>{translate('legNotiSentence11')}</p>

                    <h3 className="mt-5">
                    {translate('legNotiTitle6')}
                    </h3>
                    <p>{translate('legNotiSentence12')}</p>

                    <h3 className="mt-5">
                        {translate('legNotiTitle7')}
                    </h3>
                    <p>{translate('legNotiSentence13')}</p>

                    <h3 className="mt-5">
                        {translate('legNotiTitle8')}
                    </h3>
                    <p>{translate('legNotiSentence15')}</p>

                    <h4 className="mt-4">
                        {translate('legNotiTitle9')}
                    </h4>
                    <p>{translate('legNotiSentence16')}</p>

                    
                    <h4 className="mt-4">
                        {translate('legNotiTitle10')}
                    </h4>
                    <p>{translate('legNotiSentence17')}</p>

                    
                    <h4 className="mt-4">
                        {translate('legNotiTitle11')}
                    </h4>
                    
                    <p>{translate('legNotiSentence18')}</p>

                    <h4 className="mt-4">
                        {translate('legNotiTitle12')}
                    </h4>
                    {translate('legNotiSentence18b')}
                    

                    <h4 className="mt-4">
                        {translate('legNotiTitle13')}
                    </h4>
                    <p>{translate('legNotiSentence19')}</p>

                    <h3 className="mt-5">
                        {translate('legNotiTitle14')}
                    </h3>
                    <p>{translate('legNotiSentence20')}</p>

                    <h4 className="mt-4">
                        {translate('legNotiTitle15')}
                    </h4>
                    <p>{translate('legNotiSentence21')}</p>

                    
                    <h3 className="mt-5">
                        {translate('legNotiTitle16')}
                    </h3>
                    <p>{translate('legNotiSentence22')}</p>

                    <h4 className="mt-4">
                        {translate('legNotiTitle17')}
                    </h4>
                    
                    <p>{translate('legNotiSentence23')}</p>

                    
                    <h4 className="mt-4">
                        {translate('legNotiTitle18')}
                    </h4>
                    
                    <p>{translate('legNotiSentence24')}</p>

                    
                    <h3 className="mt-5">
                        {translate('legNotiTitle19')}
                    </h3>
                    <p>{translate('legNotiSentence25')}</p>
                </Col>
            </Row>
            </Container>
        </section>
      </React.Fragment>
    )
  }
}

export default hot(withTranslate(LegalesNotices))
