import { AppLayout, ShopLayout, WebLayout } from "./layouts"

import BookDetail from "./views/app/BookDetail"
import Shop from "./views/shop/Shop"
import Books from "./views/app/Books"
import Cart from "./views/shop/Cart"
import Customers from "./views/app/Customers"
import Payment from "./views/shop/Payment"
import Shipping from "./views/shop/Shipping"
import ForgotPassword from "./views/app/ForgotPassword"
import Home from "./views/web/Home"
import LegalesNotices from "./views/web/LegalesNotices"
import FrequentlyAskedQuestions from "./views/web/FrequentlyAskedQuestions"
import Login from "./views/app/Login"
import Authentication from "./views/shop/Authentication"
import CustomerOrders from "./views/shop/Orders"
import CustomerOrderDetail from "./views/shop/OrderDetail"
import ResetPasswordCustomer from "./views/shop/ResetPassword"
import Address from "./views/shop/Address"
import Confirmation from "./views/shop/Confirmation"
import Orders from "./views/app/Orders"
import OrdersStats from "./views/app/OrdersStats"
import OrderDetail from "./views/app/OrderDetail"
import ProductDetail from "./views/app/ProductDetail"
import Products from "./views/app/Products"
import ProductsImport from "./views/app/ProductsImport"
import Register from "./views/app/Register"
import ResetPassword from "./views/app/ResetPassword"
import UserProfile from "./views/app/UserProfile"
import UserShop from "./views/app/UserShop"
import { Error500 } from './views/Errors'
import Offers from './views/app/Offers'
import TermsAndConditions from "./views/web/TermsAndConditions"

export default [
  {
    exact: true,
    path: "/",
    layout: WebLayout,
    component: Home
  },
  {
    exact: true,
    path: "/legales-notices",
    layout: WebLayout,
    component: LegalesNotices
  },
  {
    exact: true,
    path: "/terms-and-conditions",
    layout: WebLayout,
    component: TermsAndConditions
  },
  {
    exact: true,
    path: "/frequently-asked-questions",
    layout: WebLayout,
    component: FrequentlyAskedQuestions
  },
  {
    exact: true,
    path: "/shop/book/:key/",
    layout: ShopLayout,
    component: Shop
  },
  {
    exact: true,
    path: "/shop/book/:key/cart",
    layout: ShopLayout,
    component: Cart
  },
  {
    exact: true,
    path: "/shop/book/:key/authentication",
    layout: ShopLayout,
    component: Authentication
  },
  {
    exact: true,
    path: "/shop/book/:key/customer/orders",
    layout: ShopLayout,
    component: CustomerOrders,
    private: true
  },
  {
    exact: true,
    path: "/shop/book/:key/customer/order/:idOrder",
    layout: ShopLayout,
    component: CustomerOrderDetail,
    private: true
  },
  {
    exact: true,
    path: "/shop/book/:key/new-password/:token",
    layout: ShopLayout,
    component: ResetPasswordCustomer
  },
  {
    exact: true,
    path: "/shop/book/:key/address",
    layout: ShopLayout,
    component: Address,
    private: true
  },
  {
    exact: true,
    path: "/shop/book/:key/shipping",
    layout: ShopLayout,
    component: Shipping,
    private: true
  },
  {
    exact: true,
    path: "/shop/book/:key/payment",
    layout: ShopLayout,
    component: Payment,
    private: true
  },
  {
    exact: true,
    path: "/shop/book/:key/payment/confirmation",
    layout: ShopLayout,
    component: Confirmation,
    private: true
  },
  {
    exact: true,
    path: "/app/new-password/:token",
    layout: AppLayout,
    component: ResetPassword,
    props: {
      noNavbar: true,
      noSidebar: true
    }
  },
  {
    exact: true,
    path: "/app/login",
    layout: AppLayout,
    component: Login,
    props: {
      noNavbar: true,
      noSidebar: true
    }
  },
  {
    exact: true,
    path: "/app/register",
    layout: AppLayout,
    component: Register,
    props: {
      noNavbar: true,
      noSidebar: true
    }
  },
  {
    exact: true,
    path: "/app/forgot-password",
    layout: AppLayout,
    component: ForgotPassword,
    props: {
      noNavbar: true,
      noSidebar: true
    }
  },
  {
    exact: true,
    path: "/app/books",
    layout: AppLayout,
    component: Books,
    private: true,
  },
  {
    exact: true,
    path: "/app/book/add",
    layout: AppLayout,
    component: BookDetail,
    private: true,
  },
  {
    exact: true,
    path: "/app/book/:idBook",
    layout: AppLayout,
    component: BookDetail,
    private: true,
  },
  {
    exact: true,
    path: "/app/products",
    layout: AppLayout,
    component: Products,
    private: true,
  },
  {
    exact: true,
    path: "/app/products/import",
    layout: AppLayout,
    component: ProductsImport,
    private: true,
  },
  {
    exact: true,
    path: "/app/product/add",
    layout: AppLayout,
    component: ProductDetail,
    private: true,
  },
  {
    exact: true,
    path: "/app/product/:idProduct",
    layout: AppLayout,
    component: ProductDetail,
    private: true,
  },
  {
    exact: true,
    path: "/app/orders",
    layout: AppLayout,
    component: Orders,
    private: true,
  },
  {
    exact: true,
    path: "/app/orders/statistics",
    layout: AppLayout,
    component: OrdersStats,
    private: true,
  },
  {
    exact: true,
    path: "/app/order/:idOrder",
    layout: AppLayout,
    component: OrderDetail,
    private: true,
  },
  {
    exact: true,
    path: "/app/customers/",
    layout: AppLayout,
    component: Customers,
    private: true,
  },
  {
    exact: true,
    path: "/app/profil",
    layout: AppLayout,
    component: UserProfile,
    private: true,
  },
  {
    exact: true,
    path: "/app/offers",
    layout: AppLayout,
    component: Offers,
    private: true,
  },
  {
    exact: true,
    path: "/app/shop",
    layout: AppLayout,
    component: UserShop,
    private: true,
  },
  {
    exact: true,
    path: "/internal-error",
    layout: Error500
  }
];
