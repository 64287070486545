import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'

import { history } from '../../utils/history'
import { setAlert } from '../alert.reducer'

// Types d’actions
// ---------------
const constants = {
    GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_ERROR: 'GET_PRODUCT_ERROR',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',
    DELETE_PRODUCT_ERROR: 'DELETE_PRODUCT_ERROR',
    CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
    CREATE_PRODUCT_ERROR: 'CREATE_PRODUCT_ERROR',
    UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_ERROR: 'UPDATE_PRODUCT_ERROR',
    IMPORT_PRODUCT_SUCCESS: 'IMPORT_PRODUCT_SUCCESS',
    IMPORT_PRODUCT_ERROR: 'IMPORT_PRODUCT_ERROR',
    IMPORT_PRODUCT_RESET: 'IMPORT_PRODUCT_RESET'
}

// Réducteur
// ---------
const defaultState = { items: [], resultImport:{} }

export function products(state = defaultState, action) {
  switch (action.type) {
    case 'GET_PRODUCT_SUCCESS':{
        const product = action.payload
        const items = state.items
        const newItems = [...items]
        
        // Find if product exist
        const index = items.findIndex(item => item.idProduct === product.idProduct)

        // Product found
        if(index !== -1){
            newItems[index] = product
        } else {
            newItems.push(product)
        }

        return {
            ...state,
            items: newItems
        }
    }
    case 'GET_PRODUCTS_SUCCESS':{
        const products = action.payload

        return {
            ...state,
            ...products
        }
    }
    case 'IMPORT_PRODUCT_SUCCESS':
    case'IMPORT_PRODUCT_ERROR': {
        
        const resultImport = action.payload

        return {
            ...state,
            resultImport
        }
    }
    case 'IMPORT_PRODUCT_RESET':{
        return {
            ...state,
            resultImport: {}
        }
    }
    default:
      return state
  }
}

// Action Creators
// ---------------
export function getProduct(idProduct) {
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)
                
                const response = await axios.get(`/products/${idProduct}`)
                const product = response.data
                dispatch({type: constants.GET_PRODUCT_SUCCESS, payload: product })
            } catch (error) {
                dispatch({ type: constants.GET_PRODUCT_ERROR })
            }
        }
    }
}

export function getProducts() {
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)

                const response = await axios.get('/products')
                const products = response.data
                dispatch({type: constants.GET_PRODUCTS_SUCCESS, payload: products })
            } catch (error) {
                dispatch({ type: constants.GET_PRODUCTS_ERROR })
            }
        }
    }
}

export function addProduct(product){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        product.price = (typeof product.price === 'string')? parseFloat(product.price.replace(/[,]/, ".")).toFixed(4): product.price

        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)
                const response = await axios.post(`/products/`, product)
                const data = response.data
                dispatch(setAlert('SUCCESS', data.message))
                dispatch({ type: constants.CREATE_PRODUCT_SUCCESS })
                history.push('/app/products')
            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
                dispatch({ type: constants.CREATE_PRODUCT_ERROR })
            }
        }
    }
}

export function updateProduct(product){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(product.price){
            product.price = (typeof product.price === 'string')? parseFloat(product.price.replace(/[,]/, ".")).toFixed(4): product.price
        }

        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)

                const response = await axios.put(`/products/${product.idProduct}`, product)
                const data = response.data
                dispatch({ type: constants.UPDATE_PRODUCT_SUCCESS })
                dispatch(setAlert('SUCCESS', data.message))

                dispatch(getProduct(product.idProduct))
            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
                dispatch({ type: constants.UPDATE_PRODUCT_ERROR })
            }
        }
    }
}

export function importProducts(importedData){
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
              setAuthHeader(user.token)
              const response = await axios.post(`/products/import`, importedData)
              const data = response.data
              dispatch(setAlert('SUCCESS', data.message))
              dispatch({ type: constants.IMPORT_PRODUCT_SUCCESS, payload: data })
            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
                dispatch({ type: constants.IMPORT_PRODUCT_ERROR, payload: { importErrors:[], importSuccess:[]} })
            }
        }
    }
}

export function resetImportProducts(){
    return dispatch => {
        dispatch({ type: constants.IMPORT_PRODUCT_RESET })
    }
}