import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    FormInput,
    Row
} from "shards-react";
import { func, object } from 'prop-types'
import React from "react";
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import {resetPassword} from '../../reducers/app/user.reducer'
import { withTranslate } from 'react-redux-multilingual'


class ResetPassword extends React.Component {
    static propTypes = {
        dispatch: func.isRequired,
        userInterface: object.isRequired
    }

    constructor(props){
        super(props)

        const { token } = this.props.match.params

        this.state = {
            password: '',
            submitted: false,
            token: token
        }
    }

    // Autobind with arrow func
    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    // Autobind with arrow func
    handleSubmit = (e) => {
        e.preventDefault()

        this.setState({ submitted: true })
        const { password, token } = this.state

        if (password && password.length > 7 && token && token.length > 1) {
            this.props.dispatch(resetPassword(token, password))
        }
    }

    render(){
        const { userInterface: { waiting }, translate } = this.props
        const { password, submitted } = this.state

        return(
        <Container fluid className="main-content-container h-100 px-4">
            <Row noGutters className="h-100">
            <Col lg="3" md="5" className="auth-form mx-auto my-auto">
                <Card>
                <CardBody>
                    {/* Title */}
                    <div className="font-size-lg font-weight-bold font-family-montserrat text-center text-primary mb-3">Module-market</div>

                    {/* Form Fields */}
                    <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <label htmlFor="password">{translate('password')}</label>
                        <FormInput
                            type="password"
                            id="password"
                            name="password"
                            placeholder={translate('enterPassword')}
                            onChange={this.handleChange}
                        />
                        {submitted && (!password || password.length < 8) &&
                            <small className="text-danger">{translate('passwordGreaterThan')}</small>
                        }
                        <small className="form-text text-muted text-center">
                        {translate('newPassword')}
                        </small>
                    </FormGroup>
                    {waiting &&
                        <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
                    }
                    <Button
                        pill
                        theme="primary"
                        className="d-table mx-auto"
                        type="submit"
                    >
                        {translate('update')}
                    </Button>
                    </Form>
                </CardBody>
                </Card>
                {/* Meta Details */}
                <div className="auth-form__meta d-flex mt-4"></div>
            </Col>
            </Row>
        </Container>
        )
    }
}

function mapStateToProps( { userInterface } ) {
    return { userInterface }
}

export default hot(connect(mapStateToProps)(withTranslate(ResetPassword)))