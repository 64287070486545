import { Navbar, NavbarBrand } from "shards-react"
import { bool, func } from 'prop-types'

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'

class SidebarMainNavbar extends React.Component {
  
  static propTypes = {
    dispatch: func.isRequired,
    hideLogoText: bool.isRequired
  }

  static defaultProps = {
    hideLogoText: false
  }
  

  

  render() {
    const { hideLogoText, handleToggleSidebar } = this.props;
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
          type="light"
        >
          <NavbarBrand
            className="w-100 mr-0"
            href="#"
            style={{ lineHeight: "25px" }}
          >
            <div className="d-table m-auto">
              {!hideLogoText && (
                <span className="d-none d-md-inline ml-1 text-primary">
                  Module-market
                </span>
              )}
            </div>
          </NavbarBrand>
          {/* eslint-disable-next-line */}
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={handleToggleSidebar}
          >
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

export default hot(connect()(SidebarMainNavbar));
