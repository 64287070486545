import "./assets/bootstrap.scss"
import "./assets/main.scss"

import { Route, Router, Switch } from "react-router-dom"

import Alert from './components/common/Alert'
import ValidBrowser from './components/common/ValidBrowser'
import { Error404, Maintenance, Loading } from "./views/Errors";
import PrivateRoute from './components/common/PrivateRoute'
import React from "react"
import { checkCore } from './reducers/core.reducer'
import { checkValidToken } from './reducers/app/user.reducer'
import { checkValidTokenCustomer } from './reducers/shop/customer.reducer'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { history } from './utils/history'
import { hot } from 'react-hot-loader/root'
import routes from "./routes"
import withTracker from "./withTracker"

class App extends React.Component {
  static propTypes = {
    dispatch: func.isRequired
  }

  constructor(props) {
      super(props)

      this.props.dispatch(checkCore())

      history.listen((location) => {
        this.props.dispatch(checkCore())
        this.checkToken(location)
      })

      // First time
      this.checkToken(history.location)
  }

  checkToken = (location) => {
    // Check user token for app
    if(location.pathname.startsWith('/app')){
      this.props.dispatch(checkValidToken())
    }
    
    // Check customer token for shop
    if(location.pathname.startsWith('/shop')){
      this.props.dispatch(checkValidTokenCustomer())
    }
  }

  render() {
    const { maintenance } = this.props.core

    return (
      <Router history={history} basename={process.env.REACT_APP_BASENAME || ""}>
        {maintenance === 0 && <React.Fragment>
          <Alert />
          <ValidBrowser />
          <Switch>
            {routes.map((route, index) => (
                route.private
                ? <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker(props => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    )
                  })}
                />
                : <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker(props => {
                    return (
                      <route.layout {...route.props}  {...props}>
                        <route.component {...props} />
                      </route.layout>
                    )
                  })}
                />
            ))}
            <Route component={Error404} />
          </Switch>
        </React.Fragment>}
        {maintenance === 1 && <React.Fragment>
          <Route component={Maintenance} />
        </React.Fragment>}
        {maintenance === undefined && <React.Fragment>
          <Route component={Loading} />
        </React.Fragment>}
      </Router>
    )
  }
}

function mapStateToProps({ core }) {
  return { core }
}

export default hot(connect(mapStateToProps)(App))