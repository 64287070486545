import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'
import { setAlert } from '../alert.reducer'

// Types d’actions
// ---------------
const constants = {
    GET_IMAGES_SUCCESS: 'GET_IMAGES_SUCCESS',
    GET_IMAGES_ERROR: 'GET_IMAGES_ERROR',
    DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_ERROR: 'DELETE_IMAGE_ERROR',
    CREATE_IMAGE_SUCCESS: 'CREATE_IMAGE_SUCCESS',
    CREATE_IMAGE_ERROR: 'CREATE_IMAGE_ERROR',
    UPDATE_IMAGE_SUCCESS: 'UPDATE_IMAGE_SUCCESS',
    UPDATE_IMAGE_ERROR: 'UPDATE_IMAGE_ERROR',
    UPLOAD_IMAGE_PROGRESS: 'UPLOAD_IMAGE_PROGRESS',
    UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_ERROR: 'UPLOAD_IMAGE_ERROR',
    UPLOAD_IMAGE_RESET: 'UPLOAD_IMAGE_RESET',
}

// Réducteur
// ---------
const defaultState = { 
    items: [], 
    upload:{
        files: [],
        progress: {},
        inProcess: false
    } 
}

export function images(state = defaultState, action) {
  switch (action.type) {
    case 'GET_IMAGES_SUCCESS':{
        const items = action.payload

        return {
            ...state,
            items
        }
    }
    case 'GET_IMAGES_ERROR':{
        return state
    }
    case 'DELETE_IMAGE_SUCCESS':{
        const { idImage } = action.payload
        const { items } = state
        
        const newItems = [ ...items ]
        const filteredItems = newItems.filter(item => item.idImage !== idImage)

        return {
            ...state,
            items: filteredItems
        }
    }
    case 'DELETE_IMAGE_ERROR':{
        return state
    }
    case 'CREATE_IMAGE_SUCCESS':{
        return state
    }
    case 'CREATE_IMAGE_ERROR':{
        return state
    }
    case 'UPDATE_IMAGE_SUCCESS':{
        return state
    }
    case 'UPDATE_IMAGE_ERROR':{
        return state
    }
    case 'UPLOAD_IMAGE_PROGRESS':{
        const { file, percentage } = action.payload
        const { upload } = state

        let newUpload = { ...upload }

        // Set inProcess
        newUpload.inProcess = true

        // Add file if not exist
        if(!newUpload.files.includes(file.name)) newUpload.files.push(file.name)
        
        // Add file for progress
        newUpload.progress[file.name] = {
            state: 'pending',
            percentage
        }

        return {
            ...state,
            upload: newUpload
        }
    }
    case 'UPLOAD_IMAGE_SUCCESS':{
        const { file } = action.payload
        const { upload } = state

        let newUpload = { ...upload }
        newUpload.progress[file.name].state = 'success'

        return {
            ...state,
            upload: newUpload
        }
    }
    case 'UPLOAD_IMAGE_ERROR':{
        const { file } = action.payload
        const { upload } = state

        let newUpload = { ...upload }
        newUpload.progress[file.name].state = 'error'

        return {
            ...state,
            upload: newUpload
        }
    }
    case 'UPLOAD_IMAGE_RESET':{
        const { file } = action.payload
        const { upload } = state
        
        const newUpload = { ...upload }

        // Delete file in state
        const filteredFiles = newUpload.files.filter(item => item !== file.name)
        newUpload.files = filteredFiles
        
        // Delete progress in state
        delete newUpload.progress[file.name]
        
        // Reset if there is no file
        if(newUpload.files.length === 0){
            newUpload.inProcess = false
        }

        return {
            ...state,
            upload: newUpload
        }
    }
    default:
      return state
  }
}


// Action Creators
// ---------------
export function getImages(limit = 20) {
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)

                const response = await axios.get(`/images?limit=${limit}`)
                const items = response.data
                dispatch({type: constants.GET_IMAGES_SUCCESS, payload: items })
            } catch (error) {
                dispatch({ type: constants.GET_IMAGES_ERROR })
            }
        }
    }
}

export function addImage(file){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)


                try {
                    // Upload Image
                    const formData = new FormData()
                    formData.append("file", file, file.name)

                    await axios.post(`/images/upload`, formData, {
                        headers:{
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: (progressEvent) => {
                            let percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            // Progress upload started
                            dispatch(uploadProgress(file, percentage))
                        }
                    })

                    await setTimeout(()=>{
                        dispatch(uploadSucess(file))
                        dispatch(uploadReset(file))
                        dispatch(setAlert('SUCCESS', 'Fichier téléversé avec succès !'))
                        
                        // After last upload refresh images
                        const { images : { upload : { files } }} = getState()
                        if(files.length === 0){
                            dispatch(getImages())
                        }
                    }, 1000)
                } catch (error) {
                    const { data } = error.response
                    dispatch(setAlert('DANGER', data.message))
                    dispatch(uploadError(file))
                    dispatch(uploadReset(file))
                }

            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
            }
        }
    }
}

export function uploadProgress(file, percentage){
    return (dispatch) => {
        dispatch({ type: constants.UPLOAD_IMAGE_PROGRESS, payload: { file, percentage } })
    }
}

export function uploadSucess(file){
    return (dispatch) => {
        dispatch({ type: constants.UPLOAD_IMAGE_SUCCESS, payload: { file } })
    }
}

export function uploadReset(file){
    return (dispatch) => {
        dispatch({ type: constants.UPLOAD_IMAGE_RESET, payload: { file } })
    }
}

export function uploadError(file){
    return (dispatch) => {
        dispatch({ type: constants.UPLOAD_ERROR, payload: { file } })
    }
}

export function deleteImage(idImage){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
              setAuthHeader(user.token)
              setLocaleHeader(locale)

              const response = await axios.delete(`/images/${idImage}`)
              const data = response.data
              dispatch(setAlert('SUCCESS', data.message))
              dispatch({ type: constants.DELETE_IMAGE_SUCCESS, payload: { idImage } })
            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
                dispatch({ type: constants.DELETE_IMAGE_ERROR })
            }
        }
    }
}