import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import { updateProduct, getProducts } from "../../reducers/app/products.reducer"
import { func, object } from 'prop-types'

import { Link } from 'react-router-dom'
import PageTitle from "../../components/common/PageTitle"
import React from "react"
import ReactTable from "react-table"
import { connect } from 'react-redux'
import dateFormat from '../../utils/date'
import { hot } from 'react-hot-loader/root'
import { history } from '../../utils/history'
import { withTranslate } from 'react-redux-multilingual'
import FuzzySearch from "fuzzy-search"



class Products extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    products: object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 20,
      items: [],
      search: false,
      deleteTooltip: false
    }
  }

  async componentDidMount() {
    await this.props.dispatch(getProducts())
    
    const { products : { items } } = this.props

    this.setState({
      ...this.state,
      items
    })

    this.searcher = new FuzzySearch(items, ["ref","name"], {
      caseSensitive: false,
      sort: true
    })
  }

  componentDidUpdate(prevProps, prevState){
    
    const items = this.props.products.items
    const prevItems = prevProps.products.items

    if(JSON.stringify(prevItems) !== JSON.stringify(items)){
      this.setState({
        ...prevState,
        items
      })
    }
  }

  // Autobind with arrow func
  handlePageSizeChange = (e) => {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    })
  }

  activateProduct = (product) => {
    this.props.dispatch(updateProduct({ ...product, archive: 0 }))
  }

  disableProduct = (product) => {
    this.props.dispatch(updateProduct({ ...product, archive: 1 }))
  }

  handlePageChange = (pageIndex) => {
    const { pathname } = history.location
    const numPage = pageIndex + 1

    history.push({
      pathname: pathname,
      search:`?numPage=${numPage}`
    })
  }

  handleFilterSearch = (e) => {
    const search = (e.target.value.length > 0) ? true : false
    
    this.setState({
      ...this.state,
      items: this.searcher.search(e.target.value),
      search
    })
  }

  toggleDeleteToolip = () => {
    this.setState({ deleteTooltip: !this.state.deleteTooltip });
  }

  render() {
    const { translate } = this.props
    const { pageSize, items } = this.state
    const { search } = history.location
    let numPage = new URLSearchParams(search.substring(1)).get("numPage")
    numPage = parseInt(numPage) ? parseInt(numPage) - 1 : 0

    const tableColumns = [
      {
        Header: translate('ref'),
        accessor: "ref",
        minWidth: 130,
        maxWidth: 200,
        className: "text-center",
        sortable: false,
        Cell: row => row.original.ref
      },
      {
        Header: translate('name'),
        accessor: "name",
        className: "text-center",
        minWidth: 180,
        Cell: row => row.original.name
      },
      {
        Header: `${translate('price')} ${translate('exclTax')}`,
        accessor: "priceExclTax",
        maxWidth: 100,
        className: "text-center",
        sortable: false,
        Cell: row => `${row.original.priceExclTax} €`
      },
      {
        Header: `${translate('price')} ${translate('inclTax')}`,
        accessor: "price",
        maxWidth: 100,
        className: "text-center",
        sortable: false,
        Cell: row => `${row.original.price} €`
      },
      {
        Header: translate('createAt'),
        accessor: "date",
        className: "text-center",
        minWidth: 180,
        Cell: row => dateFormat(new Date(row.original.createdAt), "d mmmm yyyy")
      },
      {
        Header: translate('state'),
        accessor: "archive",
        className: "text-center",
        minWidth: 70,
        Cell: row => row.original.archive === 1 ? <span className="text-warning">{translate('archive')}</span> : <span className="text-success">-</span>
      },
      {
        Header: translate('actions'),
        accessor: "actions",
        maxWidth: 200,
        minWidth: 100,
        sortable: false,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button theme="white" tag={Link} to={`/app/product/${row.original.idProduct}`}>
              <i className="material-icons">edit</i>
            </Button>
            {row.original.archive === 1 && <Button theme="white" id="remove-button" onClick={() => this.activateProduct(row.original)}>
              <i className="material-icons">restore_from_trash</i>
            </Button>}
            {row.original.archive === 0 && <Button theme="white" id="remove-button" onClick={() => this.disableProduct(row.original)}>
              <i className="material-icons">delete</i>
            </Button>}
          </ButtonGroup>
        )
      }
    ];

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={translate('myProducts')} subtitle={translate('products')} className="text-sm-left mb-3" />
        </Row>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                <Col className="file-manager__filters__search d-flex" xs="6">
                  <InputGroup seamless size="md">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
                <Col className="d-flex justify-content-end" xs="6">
                  <Button tag={Link} to={`/app/product/add`}>{translate('addProduct')}</Button>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={items}
                pageSize={pageSize}
                resizable={false}
                showPageSizeOptions={false}
                previousText={translate('prev')}
                nextText={translate('next')}
                ofText="/"
                rowsText='lignes'
                noDataText={translate('noProductFound')}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageChange}
                page={numPage}
                /* pages={ceil(totalProducts / pageSize)} */
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = ({ products }) => {
  return { products }
}

export default hot(connect(mapStateToProps)(withTranslate(Products)))
