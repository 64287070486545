import {
    Card,
    CardHeader,
    CardBody,
    FormCheckbox
} from "shards-react"

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'
import { udapteUser } from "../../reducers/app/user.reducer"

  
class ConfigPrice extends React.Component {
  
    constructor(props){
        super(props)

        this.state = {
            displayPricesWithoutTax: false
        }
    }

    componentDidMount(){
        const { displayPricesWithoutTax } = this.props.user

        this.setState({
            displayPricesWithoutTax
        })
    }

    toggleChange = () => {
        this.setState((state)=>({
            displayPricesWithoutTax: !state.displayPricesWithoutTax
        }),() => {
            this.props.dispatch(udapteUser({ displayPricesWithoutTax: this.state.displayPricesWithoutTax }))
        })

    }

    render(){
        const { translate } = this.props
        const { displayPricesWithoutTax } = this.state
        
        return (
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">{translate('prices')}</h6>
                </CardHeader>
                <CardBody>
                    <p>
                    {translate('displayPricesWithoutTax')}
                    <FormCheckbox toggle onChange={this.toggleChange} checked={displayPricesWithoutTax} className="mr-2 float-right"></FormCheckbox>
                    </p>
                </CardBody>
            </Card>
        )
    }
}
  
const mapStateToProps = ( { user } ) => { 
    return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(ConfigPrice)))
  