import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row
} from 'shards-react'
import { deleteBook, getBooks } from '../../reducers/app/books.reducer'
import { func, object } from 'prop-types'

import { Link } from 'react-router-dom'
import PageTitle from '../../components/common/PageTitle'
import React from 'react'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import dateFormat from '../../utils/date'
import { hot } from 'react-hot-loader/root'
import { history } from '../../utils/history'
import { withTranslate } from 'react-redux-multilingual'


class Books extends React.Component {
    static propTypes = {
      dispatch: func.isRequired,
      books: object.isRequired,
    }

    constructor(props) {
      super(props);

      this.state = {
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 20
      }
    }

    componentDidMount() {
      this.props.dispatch(getBooks())
    }

    // Autobind with arrow func
    handlePageSizeChange = (e) => {
      this.setState({
        ...this.state,
        pageSize: e.target.value
      })
    }

    handleItemDelete = (idBook) => {
      if(typeof idBook === 'number')
        this.props.dispatch(deleteBook(idBook))
    }

    handlePageChange = (pageIndex) => {
      const { pathname } = history.location
      const numPage = pageIndex + 1
  
      history.push({
        pathname: pathname,
        search:`?numPage=${numPage}`
      })
    }

    render() {
      const { books, translate, Intl } = this.props
      const { pageSize } = this.state
      const { search } = history.location
      let numPage = new URLSearchParams(search.substring(1)).get("numPage")
      numPage = parseInt(numPage) ? parseInt(numPage) - 1 : 0

      const items = Object.values(books)

      // Sort by date
      items.sort((a,b)=>{
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)

        if(dateA.getTime() === dateB.getTime())
          return 1
        else
          return -1
      })

      const tableColumns = [
        {
          Header: translate('ref'),
          accessor: "ref",
          minWidth: 130,
          maxWidth: 200,
          className: "text-center",
          sortable: false,
          Cell: row => row.original.ref
        },
        {
          Header: translate('name'),
          accessor: "name",
          className: "text-center",
          minWidth: 250,
          Cell: row => row.original.title
        },
        {
          Header: translate('link'),
          accessor: "link",
          minWidth: 400,
          className: "text-center",
          sortable: false,
          Cell: row => <Link to={`/shop/book/${row.original.key}`} target="_blank">{window.location.origin}/shop/book/{row.original.key}</Link>
        },
        {
          Header: translate('createAt'),
          accessor: "date",
          className: "text-center",
          minWidth: 180,
          Cell: row => {
            
            return (Intl.locale === 'fr') ? dateFormat(new Date(row.original.createdAt), "dd/mm/yyyy") : dateFormat(new Date(row.original.createdAt), "yyyy-mm-dd")
          }
        },
        {
          Header: translate('actions'),
          accessor: "actions",
          maxWidth: 200,
          minWidth: 100,
          sortable: false,
          Cell: row => (
            <ButtonGroup size="sm" className="d-table mx-auto">
              <Button theme="white" tag={Link} to={`/app/book/${row.original.idBook}`}>
                <i className="material-icons">&#xE254;</i>
              </Button>
              <Button theme="white" onClick={() => this.handleItemDelete(row.original.idBook)}>
                <i className="material-icons">&#xE872;</i>
              </Button>
            </ButtonGroup>
          )
        }
      ];
  
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={translate('myPdf')} subtitle={translate('pdf')} className="text-sm-left mb-3" />
          </Row>
          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  <Col className="file-manager__filters__rows d-flex" md="6"></Col>
                  <Col className="d-flex justify-content-end" md="6">
                    <Button tag={Link} to={`/app/book/add`}>{translate('addPdf')}</Button>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div className="">
                <ReactTable
                  columns={tableColumns}
                  data={items}
                  pageSize={pageSize}
                  resizable={false}
                  showPageSizeOptions={false}
                  previousText="Précédent"
                  nextText="Suivant"
                  ofText="/"
                  rowsText='lignes'
                  noDataText='Aucun PDF trouvé'
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageChange}
                  page={numPage}
                  /* pages={ceil(totalbooks / pageSize)} */
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      );
    }
  }
  
  const mapStateToProps = ({ books, Intl }) => {
    return { books, Intl }
  }
  
  export default hot(connect(mapStateToProps)(withTranslate(Books)))
  