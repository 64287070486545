import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    FormSelect,
    FormInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "shards-react"

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'
import { number, sign } from 'mathjs'
import { udapteUser } from '../../reducers/app/user.reducer'
import { isNegativeZero } from '../../utils/math'

class ShippingCosts extends React.Component {
  
    constructor(props){
        super(props)

        this.state = {
            shippingCosts: {
                vat: 0,
                ranges:[]
            },
            errors:[]
        }
    }

    componentDidMount(){
        const { shippingCosts } = this.props.user

        if(shippingCosts && shippingCosts.hasOwnProperty('vat') && shippingCosts.hasOwnProperty('ranges')){
            this.setState({ shippingCosts })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        
        const { shippingCosts } = this.state

        this.props.dispatch(udapteUser({ shippingCosts }))
    }

    addRange = () => {
        const { shippingCosts } = this.state
        const copyShippingCosts = { ...shippingCosts }
        const rangesLength = copyShippingCosts.ranges.length
        let newRange
        
        if(rangesLength === 0){
            newRange = { 
                shippingCost: 10,
                priceSupOrEqualTo: 0,
                priceLowerThan: 100,
            }
        } else {
            newRange = { 
                shippingCost: number(copyShippingCosts.ranges[rangesLength - 1].shippingCost) + 10,
                priceSupOrEqualTo: number(copyShippingCosts.ranges[rangesLength - 1].priceLowerThan) + 1,
                priceLowerThan: number(copyShippingCosts.ranges[rangesLength - 1].priceLowerThan) + 100,
            }
        }

        copyShippingCosts.ranges.push(newRange)
        this.setState({ shippingCosts: copyShippingCosts })
    }

    editRange = (e,index) => {
        const { name, value } = e.target
        const { ranges } = this.state.shippingCosts
        let price = value

        try{
            // check if it is a positive number 
            if(sign(Number(price)) !== -1 && !isNegativeZero(Number(price))){
                number(price)

                const newRanges = [...ranges]
                const shippingCost = { ...newRanges[index] }

                if(price.includes('.') && price.indexOf('.') + 1 !== price.length){
                    const dotIndex = price.indexOf('.')
                    price = price.substring(0, dotIndex + 3)
                }

                shippingCost[name] = price
                newRanges[index] = shippingCost

                this.setState({
                    ...this.state,
                    shippingCosts:{
                        ...this.state.shippingCosts,
                        ranges: newRanges
                    }
                }, () => {
                    this.checkRanges()
                })        
            } else {
                throw new Error('Not a positive number')
            }
        } catch(e){
            return e
        }
    }

    deleteRange =  (index) => {
        const { shippingCosts } = this.state
        const copyShippingCosts = { ...shippingCosts }
        
        // Remove specific range
        copyShippingCosts.ranges.splice(index, 1)
        
        this.setState({ shippingCosts: copyShippingCosts })

        this.checkRanges()
    }

    checkRanges = () => {
        const { shippingCosts : { ranges } } = this.state
        const errors = []
        const rangesLength = ranges.length
        
        ranges.map((range, index) => {
            const obj = {}
            
            // Checks fields in a row
            if(number(range.shippingCost) <= 0)
                obj.shippingCost = 'mustBeGreeter'
            if(number(range.priceSupOrEqualTo) >= number(range.priceLowerThan)){
                obj.priceSupOrEqualTo = 'mustBeLower'
                obj.priceLowerThan = 'orMustBeGreeter'
            }
            if(number(range.priceLowerThan) <= 0)
                obj.priceLowerThan = 'mustBeGreeter'

            // Check fields with next row
            if(rangesLength > index + 1){
                if(number(range.priceLowerThan) >= number(ranges[index + 1].priceSupOrEqualTo))
                obj.priceLowerThan = 'mustBeLowerThanNextRange'
            }
            
            if(obj.hasOwnProperty('shippingCost') || obj.hasOwnProperty('priceSupOrEqualTo') || obj.hasOwnProperty('priceLowerThan'))
                errors[index] = obj

            return null
        })

        this.setState({ errors })
    }

    handleChangeVat = (e) => {
        const vat = number(e.target.value)
        const { shippingCosts } = this.state
        const copyShippingCosts = { ...shippingCosts }
        copyShippingCosts.vat = vat

        this.setState({ shippingCosts: copyShippingCosts })
      }

    render(){
        const { translate } = this.props
        const { shippingCosts, errors } = this.state
        const shippingCostsIsEmpty = shippingCosts.ranges.length === 0
        const errorsIsEmpty = errors.length === 0
        const vatIsEmpty = shippingCosts.vat === 0

        return (
        <React.Fragment>
            <Form onSubmit={this.handleSubmit}>
                <Card small className='mb-4'>
                    <CardHeader className="border-bottom">
                            <Row>
                                <Col>
                                    <h6 className="m-0">{translate('shippingCosts')}</h6>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Button onClick={this.addRange} theme="white" size="sm">{translate('addRange')}</Button>
                                </Col>
                            </Row>
                    </CardHeader>
                    <CardBody className={shippingCostsIsEmpty ? '' : 'p-0'}>
                        <Container fluid className="px-0">
                            {!shippingCostsIsEmpty && <div className="table-responsive">
                                <table className="table no-border mb-0">
                                    <thead className="py-2 bg-light text-semibold">
                                    <tr>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('shippingCostsNote1')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('shippingCostsNote2')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('shippingCostsNote3')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {shippingCosts.ranges.map((range,index) => {
                                            return <tr key={index}>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="shippingCost" className="text-right" value={range.shippingCost}
                                                        onChange={(e) => this.editRange(e,index)} />
                                                        <InputGroupAddon type="prepend">
                                                            <InputGroupText>€</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('shippingCost') &&
                                                        <small className="text-danger">{translate(errors[index].shippingCost)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="priceSupOrEqualTo" className="text-right" value={range.priceSupOrEqualTo} onChange={(e) => this.editRange(e,index)} />
                                                        <InputGroupAddon type="prepend">
                                                            <InputGroupText>€</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('priceSupOrEqualTo') &&
                                                        <small className="text-danger">{translate(errors[index].priceSupOrEqualTo)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="priceLowerThan" className="text-right" value={range.priceLowerThan} onChange={(e) => this.editRange(e,index)} />
                                                        <InputGroupAddon type="prepend">
                                                            <InputGroupText>€</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('priceLowerThan') &&
                                                        <small className="text-danger">{translate(errors[index].priceLowerThan)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    {index + 1  === shippingCosts.ranges.length && <Button onClick={()=> this.deleteRange(index)} theme="white"><i className="material-icons">delete</i></Button>}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                                <small className="d-block text-light mb-3 px-3"><i>{translate('warningShippingCostsNotIncludedInRange')}</i></small>
                            </div>}
                            {shippingCostsIsEmpty && <p className="mb-0">{translate('noRangeDefined')}</p>}
                        </Container>
                    </CardBody>
                    <CardFooter className="border-top">
                        <Row>
                            <Col>
                                {!shippingCostsIsEmpty && <FormSelect className="font-size-xs" name="vat" value={shippingCosts.vat} onChange={this.handleChangeVat}>
                                    <option value={0}>{translate('vatOnShippingCost')}</option>
                                    <option value={5.5}>5.5 %</option>
                                    <option value={10}>10 %</option>
                                    <option value={20}>20 %</option>
                                </FormSelect>}
                                {!shippingCostsIsEmpty && vatIsEmpty &&
                                    <small className="text-danger">{translate('requiredField')}</small>
                                }
                            </Col>
                            <Col className="">
                                <Button type="submit" size="sm" theme="primary" className="mr-2 float-right" disabled={(!errorsIsEmpty) || (!shippingCostsIsEmpty && vatIsEmpty)}>{translate('save')}</Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Form>
        </React.Fragment>
        )
    }
}
  
const mapStateToProps = ( { user } ) => { 
    return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(ShippingCosts)))
  