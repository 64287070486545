import { Col, Modal, ModalBody, ModalHeader, Progress, Row, Button } from "shards-react"

import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import React from "react"
import classNames from "classnames"
import { connect } from 'react-redux'
import { createBookFromPdf } from '../../../reducers/app/books.reducer'
import { hot } from 'react-hot-loader/root'
import { setAlert } from '../../../reducers/alert.reducer'
import { withTranslate } from 'react-redux-multilingual'
import moment from 'moment'
moment().format()

const maxFileSize = 25 * 1000000 // 40 Mo

class DropzoneArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: '',
      modalOpen: true,

      uploading: false,
      uploadProgress: 0,
      successfullUploaded: false,
      uploadError: false,

      converting: false,
      convertProgress: 0,
      successfullConverted: false,
      convertError: false,
    }
  }

  toggleModal = () => {
    this.setState((state) => ({modalOpen: !state.modalOpen}))
  }

  handleDrop = async(acceptedFiles, rejectedFiles, event) => {   
    const { translate } = this.props
    
    if(rejectedFiles.length > 0){
      if(rejectedFiles.length > 1){
        this.props.dispatch(setAlert('DANGER', translate('oneFilePerUpload')))
      } else if(rejectedFiles[0].size > maxFileSize){
        this.props.dispatch(setAlert('DANGER', `${translate('fileIsTooLarge')} (max. ${maxFileSize / 1000000}Mo) !`, 4000))
      } else if(rejectedFiles[0].type !== 'application/pdf'){
        this.props.dispatch(setAlert('DANGER', translate('fileMustBePdf')))
      }
    } else if(acceptedFiles.length > 0){
      this.setState({ file : acceptedFiles[0], uploading: true })
      this.props.dispatch(createBookFromPdf(acceptedFiles[0]))
    }
  }

  renderProgress = () => {
    const { uploading, uploadProgress, converting, convertProgress } = this.state

    if (uploading) {
      return ( <Progress theme="primary" value={uploadProgress ? uploadProgress : 0} /> )
    } else if( converting ){
      return ( <Progress theme="info" value={convertProgress ? convertProgress : 0} /> )
    }
  }

  static getDerivedStateFromProps(props, state) {
      const newState = {}

      if(state.uploading && state.successfullUploaded !== props.successfullUploaded){
        newState.successfullUploaded = props.successfullUploaded
        newState.uploading = false
        newState.converting = true
      }

      if(state.converting && state.successfullConverted !== props.successfullConverted){
        newState.successfullConverted = props.successfullConverted
        newState.converting = props.converting
      }

      if (state.uploading && props.uploadProgress !== state.uploadProgress) {
        newState.uploadProgress = props.uploadProgress
      }

      if (state.converting && props.convertProgress !== state.convertProgress) {
        newState.convertProgress = props.convertProgress
      }

      if ((props.uploadError !== state.uploadError) || (props.convertError !== state.convertError)) {
        newState.file = ''
        newState.modalOpen = true

        newState.uploading = false
        newState.uploadProgress = 0
        newState.successfullUploaded = false
        newState.uploadError = false

        newState.converting = false
        newState.convertProgress = 0
        newState.successfullConverted = false
        newState.convertError = false
      }

      if(Object.keys(newState).length === 0){
        return null
      } else {
        return {
          ...state,
          ...newState
        }
      }
  }

  render() {
    const { modalOpen } = this.state
    const { credits, translate } = this.props
    const userHasCredits = Number.isInteger(credits) && credits > 0

    return (
      <React.Fragment>
        {userHasCredits && <Modal size="lg" centered={true} open={modalOpen} toggle={()=>{}}>
          <ModalHeader titleClass="text-primary">{translate('importFile')} <small>(PDF)</small></ModalHeader>
          <ModalBody>
          <Row noGutters>
              <div className="file-manager-cards__dropzone w-100 p-2">
                {!this.state.file && <Dropzone disabled={false} accept="application/pdf" maxSize={maxFileSize} multiple={false} onDrop={this.handleDrop}>
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                      <div
                        {...getRootProps()}
                        className={classNames("dropzone", {
                          "dropzone--isActive": isDragActive
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className="m-0 py-5">
                          {isDragActive
                            ? translate('dropYourFileHere')
                            : translate('updoadFileOrClick')}
                        </p>
                      </div>
                    );
                  }}
                </Dropzone>}
                <div className="file">
                  {this.state.file &&
                    <Row>
                      <Col xl={12}>
                          <div className={`${this.state.converting ? 'text-info' : this.state.successfullConverted ? 'text-success text-center' : 'text-primary'} filename`}>
                            {this.state.uploading && `${translate('sendFileInProgress')} ${this.state.file.name}`}
                            {this.state.converting && `${translate('convertFileInProgress')} ${this.state.file.name}`}
                            {this.state.successfullConverted && `${translate('uplodedFileAndConvertedFileSuccess')}`}
                            {this.renderProgress()}
                          </div>
                      </Col>
                    </Row>
                  }
                </div>
              </div>
            </Row>
          </ModalBody>
        </Modal>}
        {!userHasCredits && <Modal size="md" className="text-center" centered={true} open={modalOpen} toggle={()=>{}}>
          <ModalHeader titleClass="text-primary">{translate('noCredit')}</ModalHeader>
            <ModalBody>
              <p>{translate('takeAdvantage')}</p>
              <Button tag={Link} to={`/app/offers`}>{translate('choosePackage')}</Button>
          </ModalBody>
        </Modal>}
      </React.Fragment>
    )
  }
}

function mapStateToProps({ userInterface : { uploadProgress, uploading, successfullUploaded, convertProgress, converting, successfullConverted, uploadError, convertError }, user : { credits } }){
  return {  uploadProgress, uploading, successfullUploaded, convertProgress, converting, successfullConverted, uploadError, convertError, credits }
}

export default hot(connect(mapStateToProps)(withTranslate(DropzoneArea)))
