import { Col, Container, Row, NavLink } from "shards-react"
import React from "react"
import { Link } from "react-router-dom"
import { withTranslate } from 'react-redux-multilingual'

class WebFooter extends React.Component {

    render(){
        const { translate } = this.props

        return (
            <footer className="bg-primary text-white text-center py-5 font-size-md">
            <Container className="">
                <Row>
                    <Col lg="6">
                        <p className="font-weight-bold mb-2">{translate('findUs')}</p>
                        <p className="font-size-sm">82 rue Victor Hugo<br/>71000 Mâcon</p>
                    </Col>
                    <Col lg="6">
                        <p className="font-weight-bold mb-2">{translate('aQuestion2')}</p>
                        <p className="font-size-sm"><a href="tel:03 85 31 13 97" className="text-white">{translate('telNumber')}</a><br/>
                        <a href="mailto:contact@siclik.fr" className="text-white">contact@siclik.fr</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-4">
                        <small>
                            <NavLink tag={Link} to="/legales-notices" className="text-white d-inline">{translate('legalesNotices')}</NavLink> -
                            <NavLink tag={Link} to="/terms-and-conditions" className="text-white d-inline">{translate('termsAndConditions')}</NavLink> -
                            <NavLink href="https://siclik.fr" target="_blank" rel="noopener noreferrer" className="text-white d-inline">Siclik.fr</NavLink>
                        </small>
                    </Col>
                </Row>
            </Container>
            {process.env.NODE_ENV === 'development' &&
                <div className="media-queries">
                    <div className="media-querie d-block d-sm-none">xs</div>
                    <div className="media-querie d-none d-sm-block d-md-none">sm</div>
                    <div className="media-querie d-none d-md-block d-lg-none">md</div>
                    <div className="media-querie d-none d-lg-block d-xl-none">lg</div>
                    <div className="media-querie d-none d-xl-block">xl</div>
                </div>}
            </footer>
        )
    }
}

export default withTranslate(WebFooter)
