// Types d’actions
// ---------------
const constants = {
    'ALERT_SHOW': 'ALERT_SHOW',
    'ALERT_HIDE': 'ALERT_HIDE',
    'ALERT_CLEAR': 'ALERT_CLEAR'
};

// Réducteur
// ---------
const defaultState = {
  messages: []
}

export function alert(state = defaultState, action) {
  switch (action.type) {
    case 'ALERT_SHOW':{
      const { messages } = state
      const newMessages = [ ...messages ]
      const { type, message } = action.payload
      
      const newMessage =  {
        type: type.toLowerCase(),
        message,
        visible: true,
        idMessage: state.messages.length
      }
      newMessages.push(newMessage)

      return {
        ...state,
        messages: newMessages
      }
    } case 'ALERT_HIDE': {
      const { messages } = state
      const newMessages = [ ...messages ]

      const messageToHide = newMessages.find(( message ) => message.visible === true)
      if(messageToHide) messageToHide.visible = false      

      return {
        ...state,
        messages: newMessages
      }
    } case 'ALERT_CLEAR': {
      const { messages } = state
      let newMessages = [ ...messages ]

      if(action.payload){
        const { idMessage } = action.payload
        newMessages = newMessages.filter(( message ) => message.idMessage !== idMessage)
      } else {
        newMessages.shift()
      }

      return {
        ...state,
        messages: newMessages
      };
    }default:
      return state
  }
}

// Action Creators
// ---------------
export function setAlert(type, message, delay=1500){
  return (dispatch) => {

    // Show alert
    dispatch({ type: constants.ALERT_SHOW, payload: { type, message } } )

    // Hide alert
    setTimeout(() => { dispatch({ type: constants.ALERT_HIDE }) }, delay)

    // Clear alert
    setTimeout(() => { dispatch({ type: constants.ALERT_CLEAR }) }, delay + 750)
  }
}

export function clearAlert(idMessage){
    return (dispatch) => {
      // Hide and Clear alert
      dispatch({ type: constants.ALERT_CLEAR, payload: { idMessage } })
    }
}