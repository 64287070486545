import { Col, Container, Row } from "shards-react"

import React from "react"
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


class TermsAndConditions extends React.Component {
  render() {
    const { translate } = this.props

    return (
      <React.Fragment>
        <section>
            <Container className="py-5">

            <Row noGutters className="justify-content-center">
                <Col md="12">
                    <h1 className="main-title mt-4 font-weight-bold">{translate('termAndCondTitle1')}</h1>
                    <h3 className="mt-5">{translate('termAndCondTitle2')}</h3>
                    <p className="mt-3">{translate('termAndCondSentence1')}</p>

                    <h3 className="mt-5">{translate('termAndCondTitle3')}</h3>
                    <p>{translate('termAndCondSentence2')}</p>
                    <p>{translate('termAndCondSentence3')}</p>
                    <h3 className="mt-5">
                    {translate('termAndCondTitle4')}
                    </h3>
                    <p>{translate('termAndCondSentence4')}</p>

                    <h3 className="mt-5">
                    {translate('termAndCondTitle5')}
                    </h3>
                    <p>{translate('termAndCondSentence5')}</p>

                    <h3 className="mt-5">
                    {translate('termAndCondTitle6')}
                    </h3>
                    <p>{translate('termAndCondSentence6')}</p>

                    <h3 className="mt-5">
                    {translate('termAndCondTitle7')}
                    </h3>
                    <p>{translate('termAndCondSentence7')}</p>

                    <h3 className="mt-5">
                    {translate('termAndCondTitle8')}
                    </h3>
                    <p>{translate('termAndCondSentence8')}</p>

                    <h3 className="mt-4">
                    {translate('termAndCondTitle9')}
                    </h3>
                    <p>{translate('termAndCondSentence9')}</p>
                </Col>
            </Row>
            </Container>
        </section>
      </React.Fragment>
    )
  }
}

export default hot(withTranslate(TermsAndConditions))
