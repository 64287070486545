import { Button, Col, FormInput, InputGroup, InputGroupAddon, Modal, ModalBody, ModalHeader, Row } from "shards-react";
import { func } from 'prop-types'

import React from "react"
import { updateProductToCart } from '../../../reducers/shop/cart.reducer'
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { toggleProductDetailModal } from '../../../reducers/shop/customerInterface.reducer'
import { withTranslate } from 'react-redux-multilingual'


class ProductDetail extends React.Component {
    static propTypes = {
        dispatch: func.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            quantity: 1
        }
    }

    toggleModal = () => {
        this.props.dispatch(toggleProductDetailModal())
    }

    subQuantity = () => {
        const { quantity } = this.state

        this.setState({
            quantity: (quantity === 1) ? quantity : quantity - 1
        })
    }

    addQuantity = () => {
        const { quantity } = this.state

        this.setState({
            quantity: quantity + 1
        })
    }

    addToCart = (quantity, idProduct) => {
        this.props.dispatch(updateProductToCart(idProduct, quantity, 'add'))
    }

    render(){
        const { productDetailModal : { open, idProduct }, products : { items }, translate, book  } = this.props
        const index = items.findIndex(product => product.idProduct === parseInt(idProduct))
        const product = items[index]
        const { quantity } = this.state
        const imgsPath = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/images/`

        return (
            <Modal size="lg" centered={true} open={open} toggle={this.toggleModal} modalClassName="overflow-scroll">
                <ModalHeader className="bg-light" toggle={this.toggleModal}>{product && product.name}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={4}>
                            {product && <img crossOrigin="anonymous" src={(product.images[0]) ? `${imgsPath}${product.images[0].name}` : `${imgsPath}default.jpg`} alt="" />}
                        </Col>
                        <Col lg={8}>
                            {product && <div className="font-weight-bold mb-2"><span>{product.ref}</span></div>}
                            {product && <div dangerouslySetInnerHTML={{ __html: `${product.desc}` }}></div>}
                            {product && <div className="font-weight-bold mb-2 text-primary"><span className="font-size-lg">{book.owner && book.owner.displayPricesWithoutTax ? product.priceExclTax : product.price}€ <sup>{book.owner && book.owner.displayPricesWithoutTax ? translate('exclTax') : translate('inclTax')}</sup></span></div>}
                            {book && book.owner && book.owner.activeStock && product && <small className="d-block mb-2"><i>{translate('quantityAvailable')}{translate('space')}: {product.quantityInStock}</i></small>}
                            <Row>
                                <Col lg={6}>
                                    <InputGroup>
                                        <InputGroupAddon type="prepend">
                                            <Button disabled={(book && book.owner && book.owner.activeStock && product && product.quantityInStock <= 0)? true : false } onClick={this.subQuantity}>-</Button>
                                        </InputGroupAddon>
                                        <FormInput disabled value={quantity} />
                                        <InputGroupAddon type="append">
                                            <Button disabled={(book && book.owner && book.owner.activeStock && product && product.quantityInStock <= 0)? true : false } onClick={this.addQuantity}>+</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    <Button disabled={(book && book.owner && book.owner.activeStock && product && product.quantityInStock <= 0)? true : false } block className="mt-2" onClick={() => { this.addToCart(quantity, idProduct) } }>{translate('addToCart')}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}


const mapStateToProps = ({ shop : { customerInterface : { productDetailModal }, products, book } }) => {
    return { productDetailModal, products, book }
}

export default hot(connect(mapStateToProps)(withTranslate(ProductDetail)))
