import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink } from "shards-react"
import { Link } from "react-router-dom"
import { withTranslate } from 'react-redux-multilingual'


class Steps extends Component {
    render() {
        const { step, bookKey, translate } = this.props
        
        return (
            <Nav fill className="bg-white mb-4 rounded d-none d-sm-flex">
                <NavItem className="bg-primary rounded-left">
                    <NavLink tag={Link} className="text-white" to={`/shop/book/${bookKey}/cart`}>01. {translate('cart')}</NavLink>
                </NavItem>
                <NavItem className={ step < 2 ? "bg-white" : "bg-primary" }>
                    <NavLink tag={Link} className={ step < 2 ? "" : "text-white" } disabled={ step < 2 ? true : false } to={`/shop/book/${bookKey}/authentication`}>02. {translate('login')}</NavLink>
                </NavItem>
                <NavItem className={ step < 3 ? "bg-white" : "bg-primary" }>
                    <NavLink tag={Link} className={ step < 3 ? "" : "text-white" } disabled={ step < 3 ? true : false } to={`/shop/book/${bookKey}/address`}>03. {translate('address')}</NavLink>
                </NavItem>
                <NavItem className={ step < 4 ? "bg-white" : "bg-primary" }>
                    <NavLink tag={Link} className={ step < 4 ? "" : "text-white" } disabled={ step < 4 ? true : false } to={`/shop/book/${bookKey}/shipping`}>04. {translate('shipping')}</NavLink>
                </NavItem>
                <NavItem className={ step < 5 ? "rounded-right bg-white" : "rounded-right bg-primary" }>
                    <NavLink tag={Link} className={ step < 5 ? "" : "text-white" } disabled={ step < 5 ? true : false } to={`/shop/book/${bookKey}/payment`}>05. {translate('summary')}</NavLink>
                </NavItem>
            </Nav>
        )
    }
}

export default connect()(withTranslate(Steps))