import { Button, Card, CardBody, CardFooter, CardHeader, CardImg, CardTitle, Col, Container, FormInput, InputGroup, InputGroupAddon, Row, } from "shards-react"
import { updateProductToCart } from '../../reducers/shop/cart.reducer'

import { Link } from "react-router-dom"
import React from "react"
import { connect } from 'react-redux'
import { getCart } from '../../reducers/shop/cart.reducer'
import { hot } from 'react-hot-loader/root'
import  Steps from '../../components/shop/order/Steps'
import { withTranslate } from 'react-redux-multilingual'



class Cart extends React.Component{

    subQuantity = (idProduct, quantity) => {
        this.props.dispatch(updateProductToCart(idProduct, quantity, 'sub'))
    }

    addQuantity = (idProduct, quantity) => {
        this.props.dispatch(updateProductToCart(idProduct, quantity, 'add'))
    }

    removeItem = (idProduct) => {
        this.props.dispatch(updateProductToCart(idProduct, 0, 'rem'))
    }

    componentDidMount() {
        const { cart, book } = this.props

        if(cart.hasOwnProperty('idCart') && book.hasOwnProperty('idBook') && cart.idCart !== 0 && book.idBook !== 0) {
            this.props.dispatch(getCart(cart.idCart, book.idBook))
        }
    }

    render(){
        const { cart, book: { activePage, owner }, match: { params : { key } }, translate } = this.props // 
        let itemsIsEmpty = !cart.hasOwnProperty('totalNumberProducts') || cart.totalNumberProducts === 0
        const thumbsPath = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/images/thumbs/`

        return (
            <Container fluid className="main-content-container bg-dark">
                <Row className="page-header justify-content-center py-5">
                    <Col xl="7" md="11">
                        <Steps step={1} bookKey={key} />
                        <Card>
                            <CardHeader className="border-bottom bg-light">01. {translate('cart')}</CardHeader>
                            <CardBody>
                                {!itemsIsEmpty && cart.products.map((product, index)=> {
                                    return <Row key={index} className="justify-content-center mb-3">
                                        <Col xs="2" className="order-1">
                                            <CardImg crossOrigin="anonymous" className="img-thumbnail" src={product.images[0] ? `${thumbsPath}${product.images[0].name}` : `${thumbsPath}default.jpg`} />
                                        </Col>
                                        <Col xs="5" className="order-2">
                                            <CardTitle className="text-primary">{product.name}</CardTitle>
                                            <p className="mb-4 d-none d-sm-block">{product.desc.replace(/(<([^>]+)>)/ig,"").substring(0,80)}...</p>
                                            <p className="mb-0 d-none d-sm-block">
                                                {owner && owner.displayPricesWithoutTax ? product.priceExclTax : product.price} € <sup>{owner && owner.displayPricesWithoutTax ? translate('exclTax') : translate('inclTax')}</sup>
                                            </p>
                                        </Col>
                                        <Col sm="2" xs="6" className="order-3 align-self-center d-none d-sm-block">
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <Button onClick={()=>{this.subQuantity(product.idProduct, 1)}}>-</Button>
                                                </InputGroupAddon>
                                                <FormInput disabled value={product.quantity} />
                                                <InputGroupAddon type="append">
                                                    <Button onClick={()=>{this.addQuantity(product.idProduct, 1)}}>+</Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                        <Col sm="2" xs="3" className="order-4 align-self-center text-center" >
                                            { owner && owner.displayPricesWithoutTax ? `${product.subTotalExclTax} €` : `${product.subTotalInclTax} €` } <sup>{owner && owner.displayPricesWithoutTax ? translate('exclTax') : translate('inclTax')}</sup>
                                        </Col>
                                        <Col sm="1" xs="2" className="order-5 align-self-center text-center">
                                            <i className="material-icons cursor-pointer font-size-md" onClick={()=>{ this.removeItem(product.idProduct) }}>delete</i>
                                        </Col>
                                    </Row>
                                })}
                                {itemsIsEmpty && <p className="text-center">{translate('noProductInCart')}</p>}
                            </CardBody>
                            <CardFooter className="border-top bg-light">
                                <Row>
                                    <Col sm="3" >
                                        {key && <Button tag={Link} theme='primary' className="d-block d-sm-inline-block mb-2 mb-sm-0" to={`/shop/book/${key}?numPage=${activePage}`}>&larr; {translate('continueShopping')}</Button>}
                                    </Col>
                                    <Col sm="6">
                                        {!itemsIsEmpty && <table className="w-100">
                                            <tbody>
                                                <tr className="border-bottom">
                                                    <td className="text-light">
                                                        {translate('totalItems')} <small><i>{translate('ie')} {cart.totalPriceProductsExclTax}€ <sup>{translate('exclTax')}</sup></i></small>
                                                    </td>
                                                    <td className="text-right text-light">
                                                        {cart.totalPriceProductsInclTax}€ <sup>{translate('inclTax')}</sup>
                                                    </td>
                                                </tr>
                                                <tr className="border-bottom">
                                                    <td className="text-light">
                                                        {translate('shipping')} <small><i>{cart.shippingCosts.name.toLowerCase()}</i></small>
                                                    </td>
                                                    <td className="text-right text-light">
                                                        {cart.shippingCosts.totalPriceInclTax}€ <sup>{translate('inclTax')}</sup>
                                                    </td>
                                                </tr>
                                                {cart.discount > 0 && <tr className="border-bottom">
                                                    <td className="text-light">
                                                        {translate('discount')}
                                                    </td>
                                                    <td className="text-right text-light">
                                                        - {cart.discount}€ <sup>{translate('inclTax')}</sup>
                                                    </td>
                                                </tr>}
                                                <tr>
                                                    <td>
                                                        <b>{translate('total').toUpperCase()}</b> <small><i>{translate('ie')} {cart.totalPriceExclTax}€ <sup>{translate('exclTax')}</sup></i></small>
                                                    </td>
                                                    <td className="text-right font-weight-bold">
                                                        <b>{cart.totalPriceInclTax}€ <sup>{translate('inclTax')}</sup></b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                    </Col>
                                    <Col sm="3" >
                                        {!itemsIsEmpty && <Button theme='primary' className='float-sm-right d-block d-sm-inline-block' tag={Link} to={`/shop/book/${key}/authentication`}>{translate('checkout')} &rarr;</Button>}
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({ shop : { cart, book, customer } }) => {
    return { cart, book, customer }
}

export default hot(connect(mapStateToProps)(withTranslate(Cart)))
