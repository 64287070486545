function convertStringToPrice(n){
    let number = n

    // Chech if n finish by .
    if(number[number.length - 1] === '.') number = `${number}.00`

    // Convert string to number
    number = Number(n)
    
    // check if it is a positive number 
    if(Math.sign(number) !== -1 && !isNegativeZero(number)){
        return number.toFixed(2)
    } else {
        throw new Error('Not a positive number')
    }
}

function isNegativeZero(n){
    return n === 0 && (1 / n) === -Infinity;
}



export { convertStringToPrice, isNegativeZero  }