import { Button, Form, FormGroup, FormInput } from "shards-react"
import { func, object } from 'prop-types'
import React from "react"
import { connect } from 'react-redux'
import emailValidator from 'email-validator'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


/* eslint jsx-a11y/anchor-is-valid: 0 */

class ForgotPasswordForm extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    user: object.isRequired,
    userInterface: object.isRequired
  }

  constructor(props){
    super(props)

    this.state = {
      email: '',
      submitted: false
    };
  }

  // Autobind with arrow func
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  // Autobind with arrow func
  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({ submitted: true });
    const { email } = this.state;
    const lostPassword = this.props.handler

    if (emailValidator.validate(email)) {
        this.props.dispatch(lostPassword(email))
    }
  }

  render() {
    const { waiting } = this.props.userInterface;
    const { email, submitted, showLabel } = this.state;
    const { translate } = this.props

    return (
        <Form onSubmit={this.handleSubmit}>
            <FormGroup>
                {showLabel && <label htmlFor="email-forgot-password">{translate('email')}</label>}
                <FormInput
                    type="text"
                    id="email-forgot-password"
                    autoComplete="email"
                    placeholder={translate('enterEmail')}
                    name="email"
                    onChange={this.handleChange}
                    value={email}
                />
                {submitted && (!email || !emailValidator.validate(email)) &&
                    <small className="text-danger">{translate('email')} {translate('required')}</small>
                }
            </FormGroup>
            {waiting &&
                <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
            }
            <Button
            theme="white"
            className="float-right"
            type="submit"
            >
            <i className="material-icons">how_to_reg</i> {translate('generatePassword')}
            </Button>
        </Form>
    )
  }
}


function mapStateToProps( { user, userInterface } ) {
  return { user, userInterface };
}

export default hot(connect(mapStateToProps)(withTranslate(ForgotPasswordForm)))
