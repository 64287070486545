import { Button, Card, CardBody, Col, Container, Row } from "shards-react"
import { changeActivePage } from '../../reducers/shop/book.reducer'
import { func, object } from 'prop-types'

import FlipPage from 'react-flip-page'
import Page from '../../components/shop/book/Page'
import ProductDetail from '../../components/shop/book/ProductDetail'
import React from 'react'
import { connect } from 'react-redux';
import { history } from '../../utils/history'
import { hot } from 'react-hot-loader/root'

class Book extends React.Component{
    static propTypes = {
        dispatch: func.isRequired,
        book: object.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            bookSize : {
                width: 0,
                height: 0,
            },
            zoom: window.innerWidth > 991 ? 60 : 100,
            mouseDown: false,
            mouseX: 0,
            mouseY: 0
        }

        this.row = React.createRef()
        this.col = React.createRef()
    }
    

    setBookSize = () => {
        
        const { ratioPage, } = this.props.book
        const { bookSize: { width, height } } = this.state
        const numberPages = (window.innerWidth > 575) ? 2 : 1
        const targetWidth = this.col.current.offsetWidth
        const targetHeight = (targetWidth / numberPages) * ratioPage

        // Define just one time book size
        if(targetWidth !== 0 && targetHeight !== 0 && targetWidth !== width && targetHeight !== height){
            this.setState({ bookSize : { width: targetWidth, height: targetHeight }})
        }
    }

    checkValidUrlSearchParams = () => {
        let { activePage, totalPages } = this.props.book

        // For XL AND LARGE screens size divide the totalPages per 2
        totalPages = (window.innerWidth > 575) ? Math.ceil(totalPages / 2) : totalPages

        const { search } = history.location
        let numPage = new URLSearchParams(search.substring(1)).get("numPage")
        numPage = parseInt(numPage) ? parseInt(numPage) : 1

        // Current book loading
        if(totalPages === 0){
            this.handleClickPage(0)
        } 
        // If the requested page larger than the total number of pages
        else if(numPage > totalPages){
            this.flipPage.gotoPage(0)
        } 
        // If a specific page is requested 
        else if(numPage !== activePage) {
            this.flipPage.gotoPage(numPage - 1)
        }
    }

    handleClickPage = (pageIndex) => {

        const { pathname } = history.location

        this.props.dispatch(changeActivePage(++pageIndex))

        history.push({
            pathname: pathname,
            search:`?numPage=${pageIndex}`
        })
    }

    componentDidMount() {
        this.checkValidUrlSearchParams()
        this.setBookSize()
    }

    componentDidUpdate(){
         this.setBookSize()
    }

    loadPages = () => {
        const bookPages = []
        let { idBook, pages, totalPages } = this.props.book

        // XL, L, M, SM screen size = 2 pages per screen
        if(window.innerWidth > 575){
            for(let i = 0; i < pages.length; i+=2){
                bookPages.push(<Page key={i} idBook={idBook} left={pages[i]} right={pages[i+1]} />)
            } 

            // And divide the totalPages per 2
            totalPages = Math.ceil(totalPages / 2)
    
        // XS screen size = 1 page per screen
        } else {
            for(let i = 0; i < pages.length; i+=1){
                bookPages.push(<Page key={i} idBook={idBook} left={pages[i]} />)
            }
        }
        
        return { bookPages, totalPages }
    }

    zoomOut = () => {
        if(this.state.zoom > 60){
            this.setState((state) => ({zoom: state.zoom - 10, x:0, y:0}), () => {
                this.setBookSize()
            })
        }
    }

    zoomIn = () => {
        this.setState((state) => ({zoom: state.zoom + 10, x:0, y:0}), () => {
            this.setBookSize()
        })
    }

    render(){
        let { activePage } = this.props.book
        let { bookSize : { height }, zoom, x, y } = this.state
        const startAt = activePage - 1

        const { bookPages, totalPages } =  this.loadPages()

        return (
            <React.Fragment>
                <Container onMouseUp={this.handlerOnMouseUp} fluid className="main-content-container overflow-hidden px-4 book bg-dark">
                    <div ref={this.row} className="row page-header py-2 overflow-scroll">
                        <div className="col p-0 m-auto" ref={this.col} style={{'flex':`0 0 ${zoom}%`,'maxWidth': `${zoom}%`, 'transform':`translate(${x}px, ${y}px)`}}>
                            <div className="text-center d-none d-sm-block"><span className="text-center px-3 py-1 mb-2 rounded bg-white d-inline-block">{activePage} / {totalPages}</span></div>
                            <Card style={{borderRadius:0}}>
                                <CardBody className="p-0">
                                    <FlipPage 
                                        onPageChange={(pageIndex,direction) => { this.handleClickPage(pageIndex,direction) }} 
                                        ref={(component) => { this.flipPage = component }} startAt={startAt} orientation="horizontal" 
                                        width="100%" 
                                        height={height} 
                                        uncutPages={true} 
                                        responsive={false} 
                                        className="cursor-grab" 
                                        noShadow={true} 
                                        flipOnTouch={zoom > 100 ? true : false} 
                                        disableSwipe={zoom > 100 ? true : false} 
                                        showHint showSwipeHint>
                                            {bookPages}
                                    </FlipPage>
                                </CardBody>
                            </Card>
                            <div className="text-center d-sm-none"><span className="text-center px-3 py-1 mb-2 rounded bg-white d-inline-block">{activePage} / {totalPages}</span></div>
                        </div>
                    </div>
                    <div className="d-none d-sm-block" style={{position:'fixed',bottom:'0',left:'50%','zIndex':'10'}}>
                        <div style={{position:'relative',left:'-50%'}}>
                            <Row noGutters className="align-items-center justify-content-between book-buttons rounded">
                                <Col xs="3">
                                    <Button disabled={(zoom <= 60) ? true : false} onClick={this.zoomOut}><i className="material-icons font-size-lg">zoom_out</i></Button>
                                </Col>
                                <Col xs="3">
                                    <Button disabled={(zoom === 100) ? true : false} onClick={this.zoomIn}><i className="material-icons font-size-lg">zoom_in</i></Button>
                                </Col>
                                <Col xs="3">
                                    <Button onClick={() => this.flipPage.gotoPreviousPage()} disabled={(activePage === 1) ? true : false}><i className="material-icons font-size-lg">keyboard_arrow_left</i></Button>
                                </Col>
                                <Col xs="3" className="text-right">
                                    <Button onClick={() => this.flipPage.gotoNextPage()} disabled={(activePage === totalPages) ? true : false}><i className="material-icons font-size-lg">keyboard_arrow_right</i></Button>
                                </Col>
                            </Row>      
                        </div>
                    </div>
                </Container>
                <ProductDetail />
            </React.Fragment>
        )
    }
}

function mapStateToProps( { shop : { book } } ) {
    return { book }
}
  
export default hot(connect(mapStateToProps)(Book))
