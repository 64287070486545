import "react-quill/dist/quill.snow.css"

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import { addProduct, getProduct, updateProduct } from '../../reducers/app/products.reducer'
import { func, object } from 'prop-types'
import { number, round } from 'mathjs'

import ImagesModal from "../../components/product/ImagesModal"
import PageTitle from "../../components/common/PageTitle"
import React from "react"
import ReactQuill from "react-quill"
import { connect } from 'react-redux'
import { history } from '../../utils/history'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'

class ProductDetail extends React.Component {

  static propTypes = {
    dispatch: func.isRequired,
    products: object.isRequired
  }

  constructor(props){
    super(props)

    this.fileInput = React.createRef();

    this.state = {
      desc: '',
      ref: '',
      price: 12.00,
      priceExclTax: 10.00,
      vat: 20,
      quantityInStock: 0,
      name: '',
      idProduct: 0,
      images: [],
      submitted: false,
      open: false
    }
  }

  async componentDidMount() {
    const { idProduct } = this.props.match.params

    if(idProduct){
      await this.props.dispatch(getProduct(idProduct))
      
      const { items } = this.props.products
      const index = items.findIndex(item => item.idProduct === Number(idProduct))
      const product = items[index]

      if(product){
        this.setState({
          ...product
        })
      } else {
        history.push('/app/products')
      }
    }
  }

  // Autobind with arrow func
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleChangeDesc = (text) => {
    this.setState({ desc: text })
  }

  handleChangePrice = (e) => {
    const price = e.target.value
    const { vat } = this.state;

    try{
      number(price)
      const priceExclTax = round(price / (1 + vat / 100), 2)
      this.setState({ price, priceExclTax })
    } catch(e){
      return e
    }
  }

  handleChangePriceExclTax = (e) => {
    const priceExclTax = e.target.value
    const { vat } = this.state;

    try{
      number(priceExclTax)
      const price = round(priceExclTax * (1 + vat / 100), 2)
      this.setState({ price, priceExclTax })
    } catch(e){
      return e
    }
  }

  handleChangeVat = (e) => {
    const vat = number(e.target.value)
    const { price } = this.state;

    const priceExclTax = round(price / ((vat + 100) / 100), 2)

    this.setState({ priceExclTax, vat })
  }

  handleChangeQuantity = (e) => {
    const quantityInStock = e.target.value

    try{
      const newQuantityInStock = number(quantityInStock)
      this.setState({ quantityInStock: newQuantityInStock })
    } catch(e){
      return e
    }
  }

  // Autobind with arrow func
  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({ submitted: true })
    let { ref, desc, price, name, idProduct, images, vat, priceExclTax, quantityInStock } = this.state
    let { activeStock } = this.props.user

    const productValues = [
      name.length > 1,
      ref.length > 1,
      price > 0,
      priceExclTax > 0,
      activeStock ? quantityInStock >= 0 : true
    ]
    let checker = arr => arr.every(v => v === true)

    if(idProduct !== 0 && checker(productValues)){
      this.props.dispatch(updateProduct({ ref, desc, price, name, idProduct, images, vat, priceExclTax, quantityInStock }))
    } else if (idProduct === 0 && checker(productValues)) {
      this.props.dispatch(addProduct({ ref, desc, price, name, images, vat, priceExclTax, quantityInStock }))
    }
  }

  setImage = (image) => {
    image.position = 1
    this.setState((state) => ({ images: [ image ], open: !state.open }))
  }

  resetImage = () => {
    this.setState({ images : [] })
  }

  toggleModal = () => {
    this.setState((prev) => ({
      open: !prev.open
    }))
  }

  render(){
    const { submitted, ref, desc, price, priceExclTax, vat, name, idProduct, open, images, quantityInStock } = this.state;
    const { translate, user : { activeStock } } = this.props

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Form onSubmit={this.handleSubmit}>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={idProduct ? translate('editProduct') : translate('addProduct')} subtitle={translate('products')} className="text-sm-left" />
        </Row>
          <Row>
          {/* Editor */}
          <Col lg="9" md="12">
            <Card small className="mb-3">
              <CardBody>
                <FormInput value={name} name="name" placeholder={translate('productName')} onChange={this.handleChange} />
                {submitted && (!name || name.length < 2) &&
                  <small className="text-danger">{translate('requiredField2minLength')}</small>
                }
                <ReactQuill className="add-new-post__editor mb-1 mt-3" value={desc} onChange={this.handleChangeDesc}  />
              </CardBody>
            </Card>
          </Col>

          {/* Sidebar Widgets */}
          <Col lg="3" md="12">
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{translate('infos')}</h6>
              </CardHeader>

              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <label htmlFor="ref">{translate('ref')}</label>
                        <FormInput id="ref" placeholder="#001" value={ref} name="ref" onChange={this.handleChange} />
                        {submitted && (!ref || ref.length < 2) &&
                          <small className="text-danger">{translate('requiredField2minLength')}</small>
                        }
                      </FormGroup>
                      {activeStock && <FormGroup>
                        <label htmlFor="stock">{translate('quantity')}</label>
                        <FormInput id="stock" value={quantityInStock} name="quantityInStock" onChange={this.handleChangeQuantity} />
                      </FormGroup>}
                      <FormGroup>
                        <label htmlFor="price">{translate('price')} <sup>{translate('inclTax')}</sup></label>
                        <InputGroup className="">
                          <FormInput id="price" placeholder="12.00" value={price} onChange={this.handleChangePrice} name="price" />
                          <InputGroupAddon type="append">
                            <InputGroupText>€</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                          {submitted && (!price || isNaN(Number(price)) || Number(price) === 0) &&
                            <small className="text-danger">{translate('requiredField')}</small>
                          }
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="vat">{translate('vat')}</label>
                        <FormSelect name="vat" value={vat} onChange={this.handleChangeVat}>
                          <option value="20">20 %</option>
                          <option value="10">10 %</option>
                          <option value="5.5">5.5 %</option>
                          <option value="0">0 %</option>
                        </FormSelect>
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="price-exclude-tax">{translate('price')} <sup>{translate('exclTax')}</sup></label>
                        <InputGroup className="">
                          <FormInput id="price-exclude-tax" placeholder="10.00" value={priceExclTax} onChange={this.handleChangePriceExclTax} name="priceExclTax" />
                          <InputGroupAddon type="append">
                            <InputGroupText>€</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                          {submitted && ((!number(priceExclTax)) || number(priceExclTax) === 0) &&
                            <small className="text-danger">{translate('requiredField')}</small>
                          }
                      </FormGroup>
                      <div className="mb-3">
                        <h6 className="mb-1">Image</h6>
                        {images.length === 0 && <a href="#" className="d-block" onClick={this.toggleModal}><small>{translate('setHighlightedImage')}</small></a>}
                        {images.length > 0 && <React.Fragment><a href="#" onClick={this.toggleModal}><img crossOrigin="anonymous" className="d-block img-thumbnail bg-light" src={`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/images/${this.state.images[0].name}`} alt="Produit" /></a>
                        <a href="#" className="d-block" onClick={this.resetImage}><small>{translate('removeHighlightedImage')}</small></a></React.Fragment>}
                      </div>
                      <Button type="submit">{translate('save')}</Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          </Row>
        </Form>
        {open &&
          <ImagesModal
            toggleModal={this.toggleModal}
            setImage={this.setImage}
            open={open}
          />
        }
      </Container>
    )
  }
}



function mapStateToProps( { products, user } ) {
  return { products, user }
}

export default hot(connect(mapStateToProps)(withTranslate(ProductDetail)))
