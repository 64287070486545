import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'

import { history } from '../../utils/history'
import { setAlert } from '../alert.reducer'
import { waiting } from '../app/userInterface.reducer'

// Types d’actions
// ---------------
const constants = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    TOKEN_SUCCESS: 'TOKEN_SUCCESS',
    TOKEN_FAILURE: 'TOKEN_FAILURE',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
}

// Réducteur
// ---------
const defaultState = { logged: false }

export function user(state = defaultState, action) {
  switch (action.type) {
    case 'TOKEN_SUCCESS':
    case 'LOGIN_SUCCESS':
      const user = action.payload

      return {
        ...state,
        logged: true,
        ...user
      }
    case 'UPDATE_USER_SUCCESS':
      const updatedUser = action.payload

      return {
        ...state,
        ...updatedUser
    }
    case 'LOGIN_FAILURE':
    case 'TOKEN_FAILURE':
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        logged: false
      }
    default:
      return state
  }
}

// Action Creators
// ---------------
export function register(lastname, firstname, email, password){
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    dispatch(waiting(true))

    try{
      setLocaleHeader(locale)

      const response = await axios.post('/users/register', { lastname, firstname, email, password })
      const data = response.data

      dispatch(setAlert('SUCCESS', data.message))
      dispatch(waiting(false))
      history.push(process.env.REACT_APP_REDIRECT_AFTER_LOGIN_FAILURE)
    } catch(error) {
      const { data } = error.response
      dispatch(setAlert('DANGER', data.message))
      dispatch(waiting(false))
    }
  }
}

export function login(email, password) {
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    dispatch(waiting(true))

    try {
      setLocaleHeader(locale)

      const response = await axios.post('/users/token', { email: email, password: password })
      const { token } = response.data

      if(token){
        setAuthHeader(token)
        const response = await axios.get('/users/me')
        const user = response.data

        localStorage.setItem('user', JSON.stringify({ token }))

        dispatch({ type: constants.LOGIN_SUCCESS, payload: user })
        dispatch(waiting(false))
        history.push(process.env.REACT_APP_REDIRECT_AFTER_LOGIN_SUCCESS)
      }
    } catch (error) {
      const { data } = error.response
      dispatch({ type: constants.LOGIN_FAILURE })
      dispatch(waiting(false))
      dispatch(setAlert('DANGER', data.message))
    }
  }
}

export function logout() {
    return (dispatch) => {
      localStorage.clear()
      dispatch({ type: constants.LOGOUT_SUCCESS })
      dispatch(waiting(false))
      history.push(process.env.REACT_APP_BASENAME)
    }
}

export function checkValidToken() {
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    const user = JSON.parse(localStorage.getItem('user'))

    if(user){
        try {
          setAuthHeader(user.token)
          setLocaleHeader(locale)

          const response = await axios.get('/users/me')
          const userResponse = response.data
          dispatch({type: constants.TOKEN_SUCCESS, payload: userResponse})
          dispatch(waiting(false))
        } catch (error) {
          const { data } = error.response
          localStorage.removeItem("user")
          dispatch({ type: constants.TOKEN_FAILURE })
          dispatch(waiting(false))
          history.push(process.env.REACT_APP_REDIRECT_AFTER_LOGIN_FAILURE)
          dispatch(setAlert('DANGER', data.message))
        }
    } else {
      dispatch({ type: constants.TOKEN_FAILURE })
      dispatch(waiting(false))
    }
  }
}

export function lostPassword(email) {
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    dispatch(waiting(true))

    try {
      setLocaleHeader(locale)

      const response = await axios.post('/users/lostpassword', { email })
      const data = response.data
      dispatch(setAlert('SUCCESS', data.message))
      dispatch(waiting(false))
    } catch (error) {
      const { data } = error.response
      dispatch(setAlert('DANGER', data.message))
      dispatch(waiting(false))
    }
  }
}

export function resetPassword(token, password){
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    dispatch(waiting(true))

    try {
      setAuthHeader(token)
      setLocaleHeader(locale)

      const response = await axios.put('/users/me', { password })
      const data = response.data
      history.push(process.env.REACT_APP_REDIRECT_AFTER_LOGIN_FAILURE)
      dispatch(setAlert('SUCCESS', data.message))
      dispatch(waiting(false))
    } catch (error) {
      const { data } = error.response
      dispatch(setAlert('DANGER', data.message))
      dispatch(waiting(false))
    }
  }
}

export function udapteUser({ email, firstname, lastname, password, credits, displayPricesWithoutTax, activeStock, shippingCosts, cartDiscounts, collectionShops }){
  return async (dispatch, getState) => {
    const { Intl : { locale } } = getState()
    dispatch(waiting(true))

    const user = JSON.parse(localStorage.getItem('user'))

    if(user){
      const updatedUser = {}

      try {
        setAuthHeader(user.token)
        setLocaleHeader(locale)

        if(email) updatedUser.email = email
        if(firstname) updatedUser.firstname = firstname
        if(lastname) updatedUser.lastname = lastname
        if(password && password.length > 7) updatedUser.password = password
        if(credits) updatedUser.credits = credits
        if(typeof displayPricesWithoutTax === 'boolean') updatedUser.displayPricesWithoutTax = displayPricesWithoutTax
        if(typeof activeStock === 'boolean') updatedUser.activeStock = activeStock
        if(shippingCosts) updatedUser.shippingCosts = shippingCosts
        if(cartDiscounts) updatedUser.cartDiscounts = cartDiscounts
        if(collectionShops) updatedUser.collectionShops = collectionShops

        const response = await axios.put('/users/me', updatedUser)
        const data = response.data

        dispatch({ type: constants.UPDATE_USER_SUCCESS })
        dispatch(setAlert('SUCCESS', data.message))
        dispatch(checkValidToken())
        dispatch(waiting(false))
      } catch (error) {
        const { data } = error.response
        dispatch({ type: constants.UPDATE_USER_FAILURE })
        dispatch(setAlert('DANGER', data.message))
        dispatch(waiting(false))
      }
    }
  }
}