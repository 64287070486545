import {
    Button,
    Card,
    CardHeader,
    CardBody
} from "shards-react"

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { stripeAuthorization, stripeDeauthorization } from '../../reducers/payment.reducer'
import { setAlert } from "../../reducers/alert.reducer"
import { withTranslate } from 'react-redux-multilingual'

  
class ConfigPayment extends React.Component {
  
    componentDidMount(){
        const { location, translate } = this.props
        const searchParams = new URLSearchParams(location.search)
        const scope = searchParams.get('scope')
        const code = searchParams.get('code')
        const error = searchParams.get('error')
        

        if(scope && code){
            this.props.dispatch(stripeAuthorization(scope, code))
        } else if(error){
            setAlert('DANGER', translate('stripeErrorConnection'))
        } 
    }

    deauthorize = () => {
        this.props.dispatch(stripeDeauthorization())
    }

    render(){
        const { externalPaymentGateway } = this.props.user
        const { translate } = this.props

        
        return (
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">{translate('payment')}</h6>
                </CardHeader>
                <CardBody>
                    <p>
                    {translate('connectAccountForPayments')}
                    {externalPaymentGateway && externalPaymentGateway.externalPaymentName === 'stripe' && externalPaymentGateway.connectedAccountId ? <Button size="sm" theme="success" className="mr-2 float-right" onClick={this.deauthorize}>{translate('stripeDisconnect')}</Button> : <Button size="sm" className="mr-2 float-right" href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write`}>{translate('stripeConnect')}</Button>}</p>
                </CardBody>
            </Card>
        )
    }
}
  
const mapStateToProps = ( { user } ) => { 
    return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(ConfigPayment)))
  