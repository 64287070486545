import "react-quill/dist/quill.snow.css";

import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  Button
} from "shards-react";

import Dropzone from "react-dropzone"
import React from "react";
import classNames from "classnames"
import { connect } from 'react-redux';
import { getImages, addImage, deleteImage } from '../../reducers/app/images.reducer'
import { hot } from 'react-hot-loader/root';
import { setAlert } from '../../reducers/alert.reducer'
import { withTranslate } from 'react-redux-multilingual'


const maxFileSize = 1 * 1000000 // 1 Mo



class ImagesModal extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            showLibrary: true,
            showUploader: false,
            imagesLoaded: 0
        }
    }

    toggleModal = () => {
        this.props.toggleModal()
    }

    handleClick = (image) => {
        this.props.setImage(image)
    }

    showUploader = () => {
        this.setState({
            showUploader: true,
            showLibrary: false,
        })
    }

    showLibrary = () => {
        this.setState({
            showUploader: false,
            showLibrary: true,
        })
    }

    loadMoreImages = () => {
        const limit = this.state.imagesLoaded + 20
        this.props.dispatch(getImages(limit))
    }

    deleteImage = (idImage) => {
        this.props.dispatch(deleteImage(idImage))
    }

    handleDrop = async(acceptedFiles, rejectedFiles, event) => {
        const { translate } = this.props 

        if(rejectedFiles.length > 0){
            this.props.dispatch(setAlert('DANGER', translate('fileTooLargeOrIncorrectFormat')))
        } else if(acceptedFiles.length > 0){
            
            acceptedFiles.forEach((file) => {
                this.props.dispatch(addImage(file))
            })
        }
    }

    renderProgress = (file) => {
        const inProcess = this.props.images.upload.inProcess

        if (inProcess) {
            const progress = this.props.images.upload.progress[file]

            return ( <Progress theme="primary" value={progress.percentage} /> )
        }
    }

    componentDidMount() {
        this.props.dispatch(getImages())
    }

    static getDerivedStateFromProps(props, state) {

        const newState = { ...state }

        if(props.images.items.length !== newState.imagesLoaded){
            newState.imagesLoaded = props.images.items.length
        }

        return {
            ...state,
            ...newState
        }
    }

    render(){
        const { images : { items, upload: { files } }, toggleModal, open, translate } = this.props
        const { showLibrary, showUploader } = this.state

        return (
            <Modal size="lg" centered={true} open={open} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{translate('setHighlightedImage')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <Nav pills justified>
                                <NavItem>
                                    <NavLink active={showUploader} className={showUploader ? 'text-white mr-1 mb-3' : 'text-secondary bg-light mb-3 mr-1'} href="#" onClick={this.showUploader}>
                                        {translate('uploadFiles')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={showLibrary} className={showLibrary ? 'text-white mb-3' : 'text-secondary bg-light mb-3'} href="#" onClick={this.showLibrary}>{translate('mediaLibrary')}</NavLink>
                                </NavItem>
                            </Nav>
                            <div className="tab-content">
                                <div className={`tab-pane fade ${(showUploader)? 'show active' : ''}`}>
                                    <div className="file-manager-cards__dropzone w-100 mt-3">
                                        <p className="text-primary mb-2">{translate('forAnOptimalQualityPleaseRespectThe')} <span className="text-underline">{translate('theSquareFormat')}</span> </p>
                                        {!files[0] && <Dropzone disabled={false} accept="image/jpeg,image/png" maxSize={maxFileSize} onDrop={this.handleDrop}>
                                            {({ getRootProps, getInputProps, isDragActive }) => {
                                            return (
                                                <div
                                                {...getRootProps()}
                                                className={classNames("dropzone", {
                                                    "dropzone--isActive": isDragActive
                                                })}
                                                >
                                                <input {...getInputProps()} />
                                                <p className="m-0 py-5">
                                                    {isDragActive
                                                    ? translate('dropFilesHere')
                                                    : translate('updoadFilesOrClick')}
                                                </p>
                                                </div>
                                            )
                                            }}
                                        </Dropzone>}
                                        {files[0] &&
                                        <Row>
                                            <Col xl={12}>
                                                <div className="text-primary filename">
                                                    {files.map((file, index)=>{
                                                        return (
                                                            <div className="mt-2" key={index}>
                                                                {file}
                                                                {this.renderProgress(file)}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                        }
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${(showLibrary)? 'show active' : ''}`}>
                                    <Row className="height-400 overflow-scroll">
                                        { items.length === 0 && <Col><p className="text-center mt-5">{translate('noFilesFound')}</p></Col>}
                                        { items.map((image, index) => {
                                            return <Col className="mb-4" lg={3} key={index}>
                                                    <div className="thumbnail img-thumbnail cursor-pointer position-relative">
                                                        <img crossOrigin="anonymous" onClick={(e)=>this.handleClick(image)} src={`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/images/thumbs/${image.name}`} alt="" />
                                                        <a href="#" onClick={() => { this.deleteImage(image.idImage)}} className="delete-img">{translate('remove')}</a>
                                                    </div>
                                                </Col>
                                            })
                                        }
                                        { items.length > 0 && <Col lg={12} className="text-center"><Button theme="light" onClick={this.loadMoreImages}>{translate('seeMoreImages')}</Button></Col>}
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}



function mapStateToProps( { images } ) {
  return { images }
}

export default hot(connect(mapStateToProps)(withTranslate(ImagesModal)))
