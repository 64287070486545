import { Col, Container, Row } from "shards-react"
import PageTitle from "../../components/common/PageTitle"
import React from "react"
import UserAccountDetails from "../../components/user-profile/UserAccountDetails"
import { withTranslate } from 'react-redux-multilingual'


class UserProfile extends React.Component {

  render(){
    const { translate } = this.props
    
    return (<Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Profil" subtitle={translate('account')} md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col md="12">
            <UserAccountDetails />
          </Col>
        </Row>
      </Container>)
  }
    
  }

export default withTranslate(UserProfile)
