import React, { Fragment } from 'react'
import {Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceRef: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 100
    }
})


  const InvoiceNo = ({invoice, translate}) => (
    <Fragment>
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>{translate('order')} {translate('number')} :</Text>
            <Text style={styles.invoiceRef}>{invoice.ref}</Text>
        </View >
        {/* <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Transaction: </Text>
            <Text >{invoice.transactionBy}</Text>
        </View > */}
    </Fragment>
  )
  
  export default InvoiceNo