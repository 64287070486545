import { Redirect, Route } from 'react-router-dom';
import { func, object } from 'prop-types'

import React from 'react';
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'

class PrivateRoute extends React.Component{
    static propTypes = {
        dispatch: func.isRequired,
        user: object.isRequired,
    }

    render() {
        const { component: Component, user, customer, book, path, ...rest } = this.props

        if(path.startsWith('/app')){
            return (<Route path={path} {...rest} render={props => (
                user.logged
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: process.env.REACT_APP_REDIRECT_AFTER_LOGIN_FAILURE, state: { from: props.location } }} />
            )} />)
        } else if(path.startsWith('/shop')){
            return (<Route path={path} {...rest} render={props => (
                customer.logged && book
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: `/shop/book/${book.key}`, state: { from: props.location } }} />
            )} />)
        }
    }
}


function mapStateToProps( { user, shop : { customer, book } }) {
    return { user, customer, book }
}

export default hot(connect(mapStateToProps)(PrivateRoute))
