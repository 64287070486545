import { Button, Form, FormGroup, FormInput } from "shards-react"
import { func, object } from 'prop-types'
import React from "react"
import { connect } from 'react-redux'
import emailValidator from 'email-validator'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'


/* eslint jsx-a11y/anchor-is-valid: 0 */

class LoginForm extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    user: object.isRequired,
    userInterface: object.isRequired
  }

  constructor(props){
    super(props)

    this.state = {
      email: '',
      password: '',
      submitted: false
    };
  }

  // Autobind with arrow func
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  // Autobind with arrow func
  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({ submitted: true });
    const { email, password } = this.state;
    const login = this.props.handler

    if (emailValidator.validate(email) && password) {
        this.props.dispatch(login(email, password));
    }
  }

  render() {
    const { userInterface : { waiting }, translate } = this.props;
    const { email, password, submitted } = this.state;

    return (
        <Form onSubmit={this.handleSubmit}>
            <FormGroup>
            <label htmlFor="email-login">{translate('email')}</label>
            <FormInput
                type="text"
                id="email-login"
                autoComplete="email"
                placeholder={translate('enterYourEmail')}
                name="email"
                onChange={this.handleChange}
                value={email}
            />
            {submitted && (!email || !emailValidator.validate(email)) &&
                <small className="text-danger">{translate('email')} {translate('required')}</small>
            }
            </FormGroup>
            <FormGroup>
            <label htmlFor="password-login">{translate('password')}</label>
            <FormInput
                type="password"
                id="password-login"
                autoComplete="current-password"
                placeholder={translate('enterPassword')}
                name="password"
                onChange={this.handleChange}
                value={password}
            />
            {submitted && (!password) &&
                <small className="text-danger">{translate('password')} {translate('required')}</small>
            }
            </FormGroup>
            {waiting &&
                <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
            }
            <Button
            className="float-right btn-primary"
            type="submit"
            >
            <i className="material-icons">how_to_reg</i> {translate('login')}
            </Button>
        </Form>
    )
  }
}


function mapStateToProps( { user, userInterface } ) {
  return { user, userInterface };
}

export default hot(connect(mapStateToProps)(withTranslate(LoginForm)))
