import axios, { setAuthHeader, setLocaleHeader } from '../../utils/axios'
import { convertError, convertProgress, convertReset, convertSuccess, uploadError, uploadProgress, uploadReset, uploadSucess } from '../app/userInterface.reducer'

import { history } from '../../utils/history'
import { setAlert } from '../alert.reducer'

// Types d’actions
// ---------------
const constants = {
    GET_BOOK_DETAIL_SUCCESS: 'GET_BOOK_DETAIL_SUCCESS',
    GET_BOOK_DETAIL_ERROR: 'GET_BOOK_DETAIL_ERROR',
    GET_BOOKS_SUCCESS: 'GET_BOOKS_SUCCESS',
    GET_BOOKS_ERROR: 'GET_BOOKS_ERROR',
    DELETE_BOOK_SUCCESS: 'DELETE_BOOK_SUCCESS',
    DELETE_BOOK_ERROR: 'DELETE_BOOK_ERROR',
    CREATE_BOOK_SUCCESS: 'CREATE_BOOK_SUCCESS',
    CREATE_BOOK_ERROR: 'CREATE_BOOK_ERROR',
    UPDATE_BOOK_SUCCESS: 'UPDATE_BOOK_SUCCESS',
    UPDATE_BOOK_ERROR: 'UPDATE_BOOK_ERROR'
}

// Réducteur
// ---------
const defaultBook = { idBook: 0, title:'', ref:'', key:'', totalPages:0, activePage:1, ratioPage:0.00, owner:[], createdAt:'', type:0, displayAs:0 }

export function books(state = {}, action) {
    switch (action.type) {
        case 'GET_BOOK_DETAIL_SUCCESS': {
            const books = state
            const book = action.payload

            if(books[book.idBook]){
                books[book.idBook] = book

                return {
                    ...state,
                    ...books
                }
            } else {
                return state
            }

        } case 'GET_BOOKS_SUCCESS': {
            const books = action.payload

            return {
                ...books
            }
        } case 'DELETE_BOOK_SUCCESS': {
            const books = state
            const { idBook } = action.payload

            delete books[idBook]

            return {
                ...state,
                ...books
            }
        } case 'UPDATE_BOOK_SUCCESS':{
            const { idBook, ref, title, pages } = action.payload
            const books = state

            books[idBook].ref = ref
            books[idBook].title = title
            books[idBook].pages = pages
        
            return {
                ...state,
                ...books
            }
        } case 'CREATE_BOOK_SUCCESS': {
            const { idBook, key, createdAt, ref, title, type, displayAs } = action.payload
            const books = state
            const newBook = defaultBook
            newBook.idBook = idBook
            newBook.key = key
            newBook.createdAt = createdAt
            newBook.ref = ref
            newBook.title = title
            newBook.type = type
            newBook.displayAs = displayAs

            books[idBook] = newBook

            return {
                ...state,
                ...books
            }
        } default: {
            return state
        }
    }
}

// Action Creators
// ---------------
export function getBook(idBook){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        try {
            setAuthHeader(user.token)
            setLocaleHeader(locale)
            const response = await axios.get(`/books/${idBook}`)
            const book = response.data
            dispatch({ type: constants.GET_BOOK_DETAIL_SUCCESS, payload: book })
        } catch (error) {
            dispatch({ type: constants.GET_BOOK_DETAIL_ERROR })
            history.push('/not-found')
        }
    }
}

export function getBooks() {
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
            setAuthHeader(user.token)
            setLocaleHeader(locale)
            const response = await axios.get('/books')
            const books = response.data
            dispatch({type: constants.GET_BOOKS_SUCCESS, payload: books })
            } catch (error) {
            dispatch({ type: constants.GET_BOOKS_ERROR })
            }
        }
    }
}

export function createBookFromPdf(file){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)
                // Create Book
                const response = await axios.post(`/books/`, { type: 1 })
                const { idBook, key, createdAt, ref, title } = response.data

                try {
                    // Upload Pdf
                    const formData = new FormData()
                    formData.append("file", file, file.name)

                    const responseUpload = await axios.put(`/books/${idBook}/pdf/upload`, formData, {
                        headers:{
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: (progressEvent) => {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            // Progress upload started
                            dispatch(uploadProgress(percentCompleted))
                        }
                    })

                    await setTimeout(()=>{
                        dispatch(uploadSucess())
                        dispatch(uploadReset())
                    }, 2000)

                    try {
                        const data = responseUpload.data
                        dispatch(setAlert('SUCCESS', data.message))

                        await axios.put(`/books/${idBook}/pdf/convert`, {}, {
                            onDownloadProgress: (downloadProgress) => {
                                try {
                                    const totalDownloaded = parseInt(downloadProgress.srcElement.getResponseHeader('content-length'))
                                    let downloadCompleted = Math.round((downloadProgress.loaded * 100) / totalDownloaded)
                                    // Progress convert started
                                    dispatch(convertProgress(downloadCompleted))
                                } catch (error) {
                                    const { data } = error.response
                                    dispatch(setAlert('DANGER', data.message))
                                }
                            }
                        })

                        await setTimeout(()=>{
                            dispatch(convertSuccess())
                            dispatch(convertReset())
                            dispatch({ type: constants.CREATE_BOOK_SUCCESS, payload: { idBook, key, createdAt, ref, title } })
                            history.push(`/app/book/${idBook}`)
                        }, 2000)
                    } catch (error) {
                        const { data } = error.response
                        dispatch(setAlert('DANGER', data.message))
                        dispatch(convertError())
                        dispatch(uploadReset())
                        dispatch(convertReset())
                    }
                } catch (error) {
                    const { data } = error.response
                    dispatch(setAlert('DANGER', data.message))
                    dispatch(uploadError())
                    dispatch(uploadReset())
                    dispatch(convertReset())
                }

            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
            }
        }
    }
}

export function createBookFromProductList(){
    return async (dispatch, getState) => {
        const { Intl : { locale } } = getState()
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
                setAuthHeader(user.token)
                setLocaleHeader(locale)

                // Create Book
                const response = await axios.post(`/books/`, { type: 2 })
                const { idBook, key, createdAt, ref, title, type, displayAs } = response.data

                dispatch({ type: constants.CREATE_BOOK_SUCCESS, payload: { idBook, key, createdAt, ref, title, type, displayAs } })
                history.push(`/app/book/${idBook}`)
                //dispatch(this.getBook(idBook))
            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
            }
        }
    }
}

export function updateBook(idBook, ref, title, updatedPages, activeEshop, terms, idShippingCosts, displayAs){
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
                setAuthHeader(user.token)

                // Update book
                await axios.put(`/books/${idBook}`, { ref, title, activeEshop, terms, idShippingCosts, displayAs })

                // Update areas
                const res = await axios.put(`/books/${idBook}/pages`, { pages: updatedPages })
                const { message, pages } = res.data
                dispatch(setAlert('SUCCESS', message))
                dispatch({ type: constants.UPDATE_BOOK_SUCCESS, payload: { idBook, ref, title, pages } })
            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
                dispatch({ type: constants.UPDATE_BOOK_ERROR })
            }
        }
    }
}

export function deleteBook(idBook){
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
            try {
              setAuthHeader(user.token)
              const response = await axios.delete(`/books/${idBook}`)
              const data = response.data
              dispatch(setAlert('SUCCESS', data.message))
              dispatch({ type: constants.DELETE_BOOK_SUCCESS, payload: { idBook } })
            } catch (error) {
                const { data } = error.response
                dispatch(setAlert('DANGER', data.message))
                dispatch({ type: constants.DELETE_BOOK_ERROR })
            }
        }
    }
}
