/* eslint jsx-a11y/anchor-is-valid: 0 */

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  FormInput,
  Row
} from "shards-react"
import { func, object } from 'prop-types'

import { Link } from "react-router-dom"
import React from "react"
import { connect } from 'react-redux'
import emailValidator from 'email-validator'
import { hot } from 'react-hot-loader/root'
import { register } from '../../reducers/app/user.reducer'
import { history } from '../../utils/history'
import { withTranslate } from 'react-redux-multilingual'


class Register extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    userInterface: object.isRequired
  }

  constructor(props){
    super(props)

    this.state = {
      lastname: '',
      firstname: '',
      email: '',
      password: '',
      submitted: false
    }
  }

  // Autobind with arrow func
  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  // Autobind with arrow func
  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({ submitted: true })
    const { lastname, firstname, email, password } = this.state

    if (lastname && firstname && email && emailValidator.validate(email) && password && password.length > 7) {
        this.props.dispatch(register(lastname, firstname, email, password))
    }
  }

  componentDidMount = () => {
    const { logged }  = this.props.user
    if (logged) {
      history.push(process.env.REACT_APP_REDIRECT_AFTER_LOGIN_SUCCESS)
    }
  }


  render(){
    const { translate } = this.props
    const { waiting } = this.props.userInterface
    const { lastname, firstname, email, password, submitted } = this.state

    return (<Container fluid className="main-content-container p-0 vh-100 intro">
      <Row noGutters className="vh-100 p-4">
        <Col xl="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Title */}
              <div className="font-size-lg font-weight-bold font-family-montserrat text-center text-primary mb-3">Module-market</div>
              <p className="text-center mb-3 text-secondary">{translate('createYourAccount')}<br/>{translate('withNoObligation')}</p>
              <p class="text-center mb-3 text-underline"><a class="" href="https://youtu.be/G55fhl1EmzU" target="_blank">Voir la démonstration</a></p>
              {/* Form Fields */}
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <label htmlFor="lastname" class="text-secondary">{translate('name')}</label>
                  <FormInput
                    type="text"
                    id="lastname"
                    placeholder={translate('enterYourName')}
                    name="lastname"
                    onChange={this.handleChange}
                  />
                  {submitted && !lastname &&
                    <small className="text-danger">{translate('name')} {translate('required')}</small>
                  }
                </FormGroup>
                <FormGroup>
                  <label htmlFor="firstname" class="text-secondary">{translate('surname')}</label>
                  <FormInput
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder={translate('enterYourSurname')}
                    onChange={this.handleChange}
                  />
                  {submitted && !firstname &&
                    <small className="text-danger">{translate('surname')} {translate('required')}</small>
                  }
                </FormGroup>
                <FormGroup>
                  <label htmlFor="email" class="text-secondary">{translate('email')}</label>
                  <FormInput
                    type="email"
                    id="email"
                    name="email"
                    placeholder={translate('enterYourEmail')}
                    onChange={this.handleChange}
                  />
                  {submitted && (!email || !emailValidator.validate(email)) &&
                    <small className="text-danger">{translate('email')} {translate('requis')}</small>
                  }
                </FormGroup>
                <FormGroup>
                  <label htmlFor="password" class="text-secondary">{translate('password')}</label>
                  <FormInput
                    type="password"
                    id="password"
                    name="password"
                    placeholder={translate('enterPassword')}
                    onChange={this.handleChange}
                  />
                  {submitted && (!password || password.length < 8) &&
                    <small className="text-danger">{translate('passwordGreaterThan')}</small>
                  }
                </FormGroup>
                {waiting &&
                  <div className="text-center"><div className="spinner-border text-primary mb-3" role="status"><span className="sr-only">Loading...</span></div></div>
                }
                <Button
                  theme="primary"
                  className="d-table mx-auto"
                  type="submit"
                >
                  {translate('createAccount')}
                </Button>
              </Form>
            </CardBody>
          </Card>

          {/* Meta Details */}
          <div className="auth-form__meta d-flex mt-4">
              <Link to="/app/forgot-password" className="text-white">{translate('lostPassword')}</Link>
              <Link to="/app/login" className="ml-auto text-white">{translate('login')}{translate('space')}?</Link>
          </div>
        </Col>
      </Row>
    </Container>)
  }
}

function mapStateToProps( { user, userInterface } ) {
  return { user, userInterface }
}

export default hot(connect(mapStateToProps)(withTranslate(Register)))
