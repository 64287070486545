import { Button, Card, CardBody, CardFooter, CardTitle, Col, Container, Row } from "shards-react"
import { connect } from 'react-redux'
import React from "react"
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'
import ProductDetail from '../../components/shop/book/ProductDetail'
import { getProductsByBook } from '../../reducers/shop/products.reducer'
import { updateProductToCart } from '../../reducers/shop/cart.reducer'
import { toggleProductDetailModal } from '../../reducers/shop/customerInterface.reducer'

class ProductsList extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            offset: 0,
            limit: 12
        }
    }

    componentDidMount() {
        const { book: { idBook, key: currentKey }, match: { params : { key } } } = this.props

        // If new book loaded
        if(currentKey === key){
            this.props.dispatch(getProductsByBook(idBook, 0, 12))
        }
    }

    addToCart = (quantity, idProduct) => {
        this.props.dispatch(updateProductToCart(idProduct, quantity, 'add'))
    }

    toggleProductModal = (idProduct) => {
        this.props.dispatch(toggleProductDetailModal(idProduct))
    }

    loadMoreProducts = () => {
        const { limit } = this.state
        const { book } = this.props

        this.setState({ limit: limit + 12 },() => {
            const { offset, limit } = this.state
            this.props.dispatch(getProductsByBook(book.idBook, offset, limit))
        })
    }

    render(){
        const { book, products, translate } = this.props
        const { limit } = this.state
        const imgsPath = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}/public/images/`

        return (
            <React.Fragment>
                <Container fluid className="main-content-container py-5 bg-dark">
                    <Row className="justify-content-center">
                        <Col md={12} lg={12} xl={9}>
                            <Row>
                                {products && products.items.map((product, index)=>{
                                    return <Col key={index} xs={12} sm={6} md={3} className="mb-4">
                                        <Card small className="card-post">
                                            <img className="cursor-pointer card-post__image"  onClick={()=> this.toggleProductModal(product.idProduct)}  crossOrigin="anonymous" src={(product.images[0]) ? `${imgsPath}${product.images[0].name}` : `${imgsPath}default.jpg`} alt="" />
                                            <CardBody onClick={()=> this.toggleProductModal(product.idProduct)} className="cursor-pointer bg-white py-2 px-3">
                                                <CardTitle className="font-size-sm fixed-height-small mb-0">{product.name.length > 30 ? `${product.name.slice(0,30)}...` : product.name}</CardTitle>
                                                <div className="font-weight-bold text-primary text-right">{<span className="font-size-md">{book.owner && book.owner.displayPricesWithoutTax ? product.priceExclTax : product.price}€ <sup>{book.owner && book.owner.displayPricesWithoutTax ? translate('exclTax') : translate('inclTax')}</sup></span>}</div>  
                                            </CardBody>
                                            <CardFooter className="px-3 pb-3 pt-1">
                                                <div className="ml-auto">
                                                    <Button theme="primary" disabled={(book && book.owner && book.owner.activeStock && product && product.quantityInStock <= 0)? true : false } onClick={() => { this.addToCart(1, product.idProduct) } }><i className="material-icons">shopping_cart</i> {translate('addToCart')}</Button>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                    </Row>
                    {products && products.totalItems > limit && <Row>
                        <Col className="text-center">
                            <Button size="md" className="m-3 py-3 px-5" onClick={this.loadMoreProducts}>Voir plus de produits</Button> 
                        </Col>
                    </Row>}
                </Container>
                <ProductDetail />
            </React.Fragment>
        )
    }
}

function mapStateToProps( { shop : { book, products } } ) {
    return { book, products }
}
  
export default hot(connect(mapStateToProps)(withTranslate(ProductsList)))
