import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    FormInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "shards-react"

import React from "react"
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { withTranslate } from 'react-redux-multilingual'
import { number, sign } from 'mathjs'
import { udapteUser } from '../../reducers/app/user.reducer'
import { isNegativeZero } from '../../utils/math'

class DiscountPrices extends React.Component {
  
    constructor(props){
        super(props)

        this.state = {
            submitted: false,
            discount: {
                ranges:[]
            },
            errors:[]
        }
    }

    componentDidMount(){
        const { cartDiscounts } = this.props.user

        if(cartDiscounts && cartDiscounts.hasOwnProperty('ranges')){
            this.setState({ discount: cartDiscounts })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        
        const { discount } = this.state

        this.props.dispatch(udapteUser({ cartDiscounts: discount }))
    }

    addRange = () => {
        const { discount } = this.state
        const copyDiscount = { ...discount }
        const rangesLength = copyDiscount.ranges.length
        let newRange
        
        if(rangesLength === 0){
            newRange = { 
                discount: 5,
                priceSupOrEqualTo: 10,
                priceLowerThan: 100,
            }
        } else {
            newRange = { 
                discount: number(copyDiscount.ranges[rangesLength - 1].discount) + 5,
                priceSupOrEqualTo: number(copyDiscount.ranges[rangesLength - 1].priceLowerThan) + 1,
                priceLowerThan: number(copyDiscount.ranges[rangesLength - 1].priceLowerThan) + 100,
            }
        }

        copyDiscount.ranges.push(newRange)
        this.setState({ discount: copyDiscount })
    }

    editRange = (e,index) => {
        const { name, value } = e.target
        const { ranges } = this.state.discount
        let price = value

        try{
            // check if it is a positive number 
            if(sign(Number(price)) !== -1 && !isNegativeZero(Number(price))){
                number(price)

                const newRanges = [...ranges]
                const discount = { ...newRanges[index] }

                if(price.includes('.') && price.indexOf('.') + 1 !== price.length){
                    const dotIndex = price.indexOf('.')
                    price = price.substring(0, dotIndex + 3)
                }

                discount[name] = price
                newRanges[index] = discount

                this.setState({
                    ...this.state,
                    discount:{
                        ...this.state.discount,
                        ranges: newRanges
                    }
                }, () => {
                    this.checkRanges()
                })        
            } else {
                throw new Error('Not a positive number')
            }
        } catch(e){
            return e
        }
    }

    deleteRange =  (index) => {
        const { discount } = this.state
        const copyDiscount = { ...discount }
        
        // Remove specific range
        copyDiscount.ranges.splice(index, 1)
        
        this.setState({ discount: copyDiscount })

        this.checkRanges()
    }

    checkRanges = () => {
        const { discount : { ranges } } = this.state
        const errors = []
        const rangesLength = ranges.length
        
        ranges.map((range, index) => {
            const obj = {}
            
            // Checks fields in a row
            if(number(range.discount) <= 0)
                obj.discount = 'mustBeGreeter'
            
            if(number(range.discount) > number(range.priceSupOrEqualTo)){
                obj.discount = 'mustBeLower'
                obj.priceSupOrEqualTo = 'orMustBeGreeter'
            }
              
            if(number(range.priceSupOrEqualTo) >= number(range.priceLowerThan)){
                obj.priceSupOrEqualTo = 'mustBeLower'
                obj.priceLowerThan = 'orMustBeGreeter'
            }
            
            if(number(range.priceLowerThan) <= 0)
                obj.priceLowerThan = 'mustBeGreeter'

            // Check fields with next row
            if(rangesLength > index + 1){
                if(number(range.priceLowerThan) >= number(ranges[index + 1].priceSupOrEqualTo))
                obj.priceLowerThan = 'mustBeLowerThanNextRange'
            }
            
            if(obj.hasOwnProperty('discount') || obj.hasOwnProperty('priceSupOrEqualTo') || obj.hasOwnProperty('priceLowerThan'))
                errors[index] = obj

            return null
        })

        this.setState({ errors })
    }

    render(){
        const { translate } = this.props
        const { discount, errors } = this.state
        const discountIsEmpty = discount.ranges.length === 0
        const errorsIsEmpty = errors.length === 0

        return (
        <React.Fragment>
            <Form onSubmit={this.handleSubmit}>
                <Card small className='mb-4'>
                    <CardHeader className="border-bottom">
                            <Row>
                                <Col>
                                    <h6 className="m-0">{translate('discountPrices')}</h6>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Button onClick={this.addRange} theme="white" size="sm">{translate('addRange')}</Button>
                                </Col>
                            </Row>
                    </CardHeader>
                    <CardBody className={discountIsEmpty ? '' : 'p-0'}>
                        <Container fluid className="px-0">
                            {!discountIsEmpty && <div className="table-responsive">
                                <table className="table no-border mb-0">
                                    <thead className="py-2 bg-light text-semibold">
                                    <tr>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('discountPricesNote1')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('discountPricesNote2')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0">{translate('discountPricesNote3')}</th>
                                        <th className="font-weight-normal text-light font-size-xs border-0"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {discount.ranges.map((range,index) => {
                                            return <tr key={index}>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="discount" className="text-right" value={range.discount}
                                                        onChange={(e) => this.editRange(e,index)} />
                                                        <InputGroupAddon type="prepend">
                                                            <InputGroupText>€</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('discount') &&
                                                        <small className="text-danger">{translate(errors[index].discount)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="priceSupOrEqualTo" className="text-right" value={range.priceSupOrEqualTo} onChange={(e) => this.editRange(e,index)} />
                                                        <InputGroupAddon type="prepend">
                                                            <InputGroupText>€</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('priceSupOrEqualTo') &&
                                                        <small className="text-danger">{translate(errors[index].priceSupOrEqualTo)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    <InputGroup>
                                                        <FormInput name="priceLowerThan" className="text-right" value={range.priceLowerThan} onChange={(e) => this.editRange(e,index)} />
                                                        <InputGroupAddon type="prepend">
                                                            <InputGroupText>€</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {!errorsIsEmpty && errors[index] && errors[index].hasOwnProperty('priceLowerThan') &&
                                                        <small className="text-danger">{translate(errors[index].priceLowerThan)}</small>
                                                    }
                                                </td>
                                                <td>
                                                    {index + 1  === discount.ranges.length && <Button onClick={()=> this.deleteRange(index)} theme="white"><i className="material-icons">delete</i></Button>}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                            {discountIsEmpty && <p className="mb-0">{translate('noRangeDefined')}</p>}
                        </Container>
                    </CardBody>
                    <CardFooter className="border-top">
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button type="submit" size="sm" theme="primary" disabled={!errorsIsEmpty}>{translate('save')}</Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Form>
        </React.Fragment>
        )
    }
}
  
const mapStateToProps = ( { user } ) => { 
    return { user }
}

export default hot(connect(mapStateToProps)(withTranslate(DiscountPrices)))
  