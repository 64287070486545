import { Col, Container, Row } from "shards-react"
import React from "react"
import WebFooter from "../components/layout/WebFooter"
import WebNavbar from "../components/layout/navigation/WebNavbar"
import { hot } from 'react-hot-loader/root'

const WebLayout = ({ children }) => (
  <Container fluid>
    <Row>
        <Col
            className="main-content p-0"
            lg={{ size: 12 }}
            md={{ size: 12 }}
            sm="12"
            tag="main"
        >
        <WebNavbar />
        {children}
        <WebFooter />
        </Col>
    </Row>
  </Container>
)

export default hot(WebLayout)
